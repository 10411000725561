import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';

@Injectable()
export class PaidPlansService {
    constructor(private httpClient: HttpClient) {}

    private baseUrl: string = 'plans';

    /**
     * Get paid plans list
     * @param params Object with list params
     * @returns Backend API response
     */
    getPaidPlans(params: any = {}): Observable<Object> {
        return this.httpClient.get<any>(`${this.baseUrl}/`, { params });
    }

    /**
     * Get specific paid plan
     * @param id Desired paid plan's id
     * @returns Backend API response
     */
    getPaidPlan(id: number): Observable<Object> {
        return this.httpClient.get<any>(`${this.baseUrl}/${id}/`);
    }

    /**
     * Create paid plan
     * @param paidPlan Object with paid plan data needed to create paid-plan
     * @returns Backend API response
     */
    createPaidPlan(paidPlan: any): Observable<Object> {
        return this.httpClient.post<any>(`${this.baseUrl}/`, { ...paidPlan.data });
    }

    /**
     * Update existing paid plan
     * @param paidPlan Object with paid plan id and paid-plan data to be saved
     * @returns Backend API response
     */
    savePaidPlan(paidPlan: any): Observable<Object> {
        return this.httpClient.put<any>(`${this.baseUrl}/${paidPlan.id}/`, { ...paidPlan.data });
    }

    /**
     * Delete paid plan
     * @param id PaidPlan ID
     * @returns Backend API response
     */
    deletePaidPlan(id: number): Observable<Object> {
        return this.httpClient.delete<any>(`${this.baseUrl}/${id}/`);
    }

    /**
     * Get paid plans options
     * @returns Backend API response
     */
    getOptions(): Observable<Object> {
        return this.httpClient.options<any>(`${this.baseUrl}/`);
    }
}
