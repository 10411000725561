import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UploadService } from '@core/services/upload.service';
import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class BrandService {
    constructor(private httpClient: HttpClient, private uploadService: UploadService) {}

    /**
     * Get brands list
     * @param params Object with list params
     * @returns Backend API response
     */
    getBrands(params: any): Observable<Object> {
        return this.httpClient.get<any>(PROTECTED_URLS.BRANDS, { params });
    }

    /**
     * Create brand
     * @param params Object with brand
     * @returns Backend API response
     */
    createBrand(params: any = {}): Observable<Object> {
        return this.httpClient.post<any>(PROTECTED_URLS.BRANDS, params);
    }

    /**
     * Get single brand
     * @param brandId Object with brand id
     * @returns Backend API response
     */
    getBrand(brandId: any = {}): Observable<Object> {
        return this.httpClient.get<any>(`${PROTECTED_URLS.BRANDS}${brandId}/`);
    }

    /**
     * Save single brand
     * @returns Backend API response
     * @param brand
     */
    saveBrand(brand: any = {}): Observable<Object> {
        const { id, ...data } = brand;
        return this.httpClient.patch<any>(`${PROTECTED_URLS.BRANDS}${id}/`, data);
    }

    /**
     * Delete single brand
     * @param brandId Object with brand id
     * @returns Backend API response
     */
    deleteBrand(brandId: any = {}): Observable<Object> {
        return this.httpClient.delete<any>(`${PROTECTED_URLS.BRANDS}${brandId}/`);
    }

    /**
     * Prepare import request
     * @param data Object with import data needed to import brands
     * @returns Backend API response
     */
    prepareImport(data: any): Observable<any> {
        return this.uploadService.postUpload(PROTECTED_URLS.BRANDS_UPLOAD, data);
    }

    /**
     * Get import status request
     * @param id Celecry task ID
     * @returns Backend API response
     */
    getImportStatus(id: number): Observable<Object> {
        return this.httpClient.get<any>(`${PROTECTED_URLS.BRANDS_STATUS}${id}/`);
    }
}
