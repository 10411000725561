import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';

import { Params } from '@angular/router';
import { GetPartnersPayload } from '@core/models';

@Injectable()
export class PartnersService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get my partners data
     * @returns Backend API response
     */
    getPartners(params: GetPartnersPayload = {}): Observable<Object> {
        return this.httpClient.get<any>('partners/', { params } as Params);
    }
}
