import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { from, Observable, of } from 'rxjs';

import * as companyDataRequirementsActions from '@core/actions/company-data-requirements.actions';
import * as companySharingPresetsActions from '@core/actions/company-sharing-presets.actions';
import * as partners from '@core/actions/partners.actions';

import { CompaniesService } from '@core/services/companies.service';
import { ModalService } from '@core/services/modal.service';
import { NotificationsService } from '@core/services/notifications.service';
import { PartnersService } from '@core/services/partners.service';

import { HTTPClientVer } from '@core/utils/request.utils';
import { catchErrorJson } from './catch-error';

import {
    CommonPartnerPayload,
    PartnerDataRequirementChangesPayload,
    PartnerDataRequirementsPayload,
} from '@core/models';

@Injectable()
export class PartnersEffects {
    constructor(
        private actions$: Actions,
        private companiesService: CompaniesService,
        private modalService: ModalService,
        private notificationService: NotificationsService,
        private partnersService: PartnersService,
    ) {}

    
    getPartnersActionsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(partners.ActionTypes.GET_PARTNERS_REQUEST),
        map((action: partners.GetPartnersRequest) => action.payload),
        switchMap((payload) => {
            return this.partnersService.getPartners(payload).pipe(
                map((response) => new partners.GetPartnersSuccess(response)),
                catchError((error) => of(new partners.GetPartnersError(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    ));

    
    getSinglePartnerActionsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(partners.ActionTypes.GET_PARTNER_DATA_REQUEST),
        map((action: partners.GetPartnerDataRequest) => action.payload),
        switchMap((payload: any) => {
            return this.companiesService.getCompany(payload).pipe(
                map((response) => new partners.GetPartnerDataSuccess(response)),
                catchError((error) => of(new partners.GetPartnerDataError(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    ));

    
    getDataRequirementsActionsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(partners.ActionTypes.GET_DATA_REQUIREMENTS_REQUEST),
        map((action: partners.GetDataRequirementsRequest) => action.payload),
        switchMap((payload: any) => {
            return this.companiesService.getPartnerDataRequirements(payload.company, payload.params).pipe(
                map((response) => new partners.GetDataRequirementsSuccess(response)),
                catchError((error) => of(new partners.GetDataRequirementsError(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    ));

    
    getDataRequirementActionsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(partners.ActionTypes.GET_DATA_REQIUREMENT_REQUEST),
        map((action: partners.GetDataRequirementRequest) => action.payload),
        switchMap((payload: any) => {
            return this.companiesService.getPartnerDataRequirement(payload.company, payload.requirement).pipe(
                tap(() => this.modalService.open('partner-requirement-preview-modal')),
                mergeMap((response: any) => {
                    const { company, category } = response;
                    return from([
                        new companyDataRequirementsActions.GetAvailableAttributesRequestAction({
                            company,
                            category,
                        }),
                        new partners.GetDataRequirementSuccess(response),
                    ]);
                }),
                catchError((error) => of(new partners.GetDataRequirementError(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    ));

    
    bulkCreatePartnerSharingPresetsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(partners.ActionTypes.BULK_CREATE_SHARING_PRESETS_REQUEST),
        map((action: partners.BulkCreateSharingPresetsRequest) => action.payload),
        switchMap((payload: CommonPartnerPayload) => {
            const { company, partner, data, params } = payload.sharingPreset;

            return this.companiesService.bulkCreatePartnerSharingPresets(company, data).pipe(
                tap(() => this.notificationService.success('Presets has been created')),
                mergeMap((response: any) => {
                    const statsParams = {
                        partner,
                        company,
                    };

                    let actions = [
                        new companySharingPresetsActions.GetSharingPresetsSummaryRequest(statsParams),
                        new companySharingPresetsActions.GetCompanySharingPresetsRequestAction(params),
                        new companySharingPresetsActions.GetHistoryLogsRequestAction({
                            ...payload.sharingPreset.params.company,
                            offset: 0,
                        }),
                        new partners.BulkCreateSharingPresetsSuccess(response),
                        new partners.GetOverviewRequestAction({
                            company,
                            partner,
                        }),
                    ];

                    if (payload.dataRequirements.hasDataReqAccess) {
                        actions = [
                            ...actions,
                            new partners.GetDataRequirementsRequest(payload.dataRequirements),
                            new partners.GetDataRequirementsSummaryRequest(statsParams),
                        ];
                    }

                    return from(actions);
                }),
                catchError((error) =>
                    of(new partners.BulkCreateSharingPresetsError(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getDataRequirementsSummaryRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(partners.ActionTypes.GET_DATA_REQUIREMENTS_SUMMARY_REQUEST),
        map((action: partners.GetDataRequirementsSummaryRequest) => action.payload),
        switchMap((payload: PartnerDataRequirementsPayload) => {
            return this.companiesService.getPartnerDataRequirementsSummary(payload).pipe(
                map((response) => new partners.GetDataRequirementsSummarySuccess(response)),
                catchError((error) =>
                    of(new partners.GetDataRequirementsSummaryError(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getDataRequirementsChangesNotificationsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(partners.ActionTypes.GET_CHANGES_NOTIFICATIONS_REQUEST),
        map((action: partners.GetChangesNotificationsRequestAction) => action.payload),
        switchMap((payload: PartnerDataRequirementChangesPayload) => {
            return this.companiesService.getPartnerDataRequirementsChangesNotifications(payload).pipe(
                map((response) => new partners.GetChangesNotificationsSuccessAction(response as any)),
                catchError((error) =>
                    of(new partners.GetChangesNotoficationsErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    applyDataRequirementsChangesNotificationsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(partners.ActionTypes.APPLY_CHANGES_NOTIFICATIONS_REQUEST),
        map((action: partners.ApplyChangesNotificationsRequestAction) => action.payload),
        switchMap((payload: PartnerDataRequirementChangesPayload) => {
            return this.companiesService.applyPartnerDataRequirementsChangesNotifications(payload).pipe(
                map((_) => new partners.ApplyChangesNotificationsSuccessAction()),
                catchError((error) =>
                    of(new partners.ApplyChangesNotoficationsErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getOverviewRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(partners.ActionTypes.GET_OVERVIEW_REQUEST),
        map((action: partners.GetOverviewRequestAction) => action.payload),
        switchMap((payload: PartnerDataRequirementsPayload) => {
            return this.companiesService.getPartnerOverview(payload).pipe(
                map((response) => new partners.GetOverviewSuccessAction(response)),
                catchError((error) => of(new partners.GetOverviewErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    ));
}
