import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class CompanyDomainsService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get company domains list
     * @param params Object with list params
     * @returns Backend API response
     */
    getCompanyDomains(params?: any): Observable<Object> {
        return this.httpClient.get<any>(PROTECTED_URLS.COMPANY_DOMAINS, { params });
    }

    /**
     * Get company domain details
     * @param id CompanyDomain ID
     * @returns Backend API response
     */
    getCompanyDomain(id: number): Observable<Object> {
        return this.httpClient.get<any>(`${PROTECTED_URLS.COMPANY_DOMAINS}${id}/`);
    }

    /**
     * Save company domain
     * @param companyDomain Object with company domain id & data needed to save company domain
     * @returns Backend API response
     */
    saveCompanyDomain(companyDomain: any): Observable<Object> {
        return this.httpClient.patch<any>(`${PROTECTED_URLS.COMPANY_DOMAINS}${companyDomain.id}/`, {
            ...companyDomain.data,
        });
    }

    /**
     * Create company domain
     * @param companyDomain Object with company domain data needed to create company domain
     * @returns Backend API response
     */
    createCompanyDomain(companyDomain: any): Observable<Object> {
        return this.httpClient.post<any>(PROTECTED_URLS.COMPANY_DOMAINS, { ...companyDomain.data });
    }

    /**
     * Delete company domain
     * @param id Company domain ID
     * @returns Backend API response
     */
    deleteCompanyDomain(id: number): Observable<Object> {
        return this.httpClient.delete<any>(`${PROTECTED_URLS.COMPANY_DOMAINS}${id}/`);
    }

    /**
     * Get options company domains request
     * @returns Backend API response
     */
    getOptions(): Observable<Object> {
        return this.httpClient.options<any>(PROTECTED_URLS.COMPANY_DOMAINS);
    }
}
