import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class AttributeGroupsService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get attribute groups list
     * @param params Object with list params
     * @returns Backend API response
     */
    getAttributeGroups(params: any = {}): Observable<object> {
        return this.httpClient.get<any>(PROTECTED_URLS.ATTRIBUTE_GROUPS_SIMPLE, { params });
    }

    /**
     * Get attribute groups list with simple data of attributes
     * @param params Object with list params
     * @returns Backend API response
     */
    getSimpleAttributeGroups(params: any = {}): Observable<object> {
        return this.httpClient.get<any>(PROTECTED_URLS.ATTRIBUTE_GROUPS_SIMPLE_ATTRIBUTES, { params });
    }

    /**
     * Get attribute group
     * @param id Attribute group ID
     * @returns Backend API response
     */
    getAttributeGroup(id: number): Observable<object> {
        return this.httpClient.get<any>(`${PROTECTED_URLS.ATTRIBUTE_GROUPS}${id}/`);
    }

    /**
     * Save attribute group
     * @param attributeGroup Object with attribute group ID and attribute data needed to save attribiute
     * @returns Backend API response
     */
    saveAttributeGroup(attributeGroup: any): Observable<object> {
        return this.httpClient.put<any>(`${PROTECTED_URLS.ATTRIBUTE_GROUPS}${attributeGroup.id}/`, attributeGroup.data);
    }

    /**
     * Create attribute
     * @param attributeGroup Object with attribute data needed to create attribiute
     * @returns Backend API response
     */
    createAttributeGroup(attributeGroup: any): Observable<object> {
        return this.httpClient.post<any>(PROTECTED_URLS.ATTRIBUTE_GROUPS, attributeGroup.data);
    }

    /**
     * Delete attribute
     * @param id Attribute group ID
     * @returns Backend API response
     */
    deleteAttributeGroup(id: number): Observable<object> {
        return this.httpClient.delete<any>(`${PROTECTED_URLS.ATTRIBUTE_GROUPS}${id}/`);
    }
}
