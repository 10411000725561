import { addInstrumentationHandler, logger } from '@sentry/utils';
import { getActiveTransaction } from './utils.js';

/**
 * Configures global error listeners
 */
function registerErrorInstrumentation() {
  addInstrumentationHandler('error', errorCallback);
  addInstrumentationHandler('unhandledrejection', errorCallback);
}

/**
 * If an error or unhandled promise occurs, we mark the active transaction as failed
 */
function errorCallback() {
  var activeTransaction = getActiveTransaction();
  if (activeTransaction) {
    var status = 'internal_error';
    (typeof __SENTRY_DEBUG__ === 'undefined' || __SENTRY_DEBUG__) && logger.log(`[Tracing] Transaction: ${status} -> Global error occured`);
    activeTransaction.setStatus(status);
  }
}
export { registerErrorInstrumentation };
