import { isMatchingPattern, addInstrumentationHandler, dynamicSamplingContextToSentryBaggageHeader, isInstanceOf, BAGGAGE_HEADER_NAME } from '@sentry/utils';
import { hasTracingEnabled, getActiveTransaction } from '../utils.js';
var DEFAULT_TRACING_ORIGINS = ['localhost', /^\//];

/** Options for Request Instrumentation */

var defaultRequestInstrumentationOptions = {
  traceFetch: true,
  traceXHR: true,
  tracingOrigins: DEFAULT_TRACING_ORIGINS
};

/** Registers span creators for xhr and fetch requests  */
function instrumentOutgoingRequests(_options) {
  const {
    traceFetch,
    traceXHR,
    tracingOrigins,
    shouldCreateSpanForRequest
  } = {
    ...defaultRequestInstrumentationOptions,
    ..._options
  };

  // We should cache url -> decision so that we don't have to compute
  // regexp everytime we create a request.
  var urlMap = {};
  var defaultShouldCreateSpan = url => {
    if (urlMap[url]) {
      return urlMap[url];
    }
    var origins = tracingOrigins;
    urlMap[url] = origins.some(origin => isMatchingPattern(url, origin)) && !isMatchingPattern(url, 'sentry_key');
    return urlMap[url];
  };

  // We want that our users don't have to re-implement shouldCreateSpanForRequest themselves
  // That's why we filter out already unwanted Spans from tracingOrigins
  let shouldCreateSpan = defaultShouldCreateSpan;
  if (typeof shouldCreateSpanForRequest === 'function') {
    shouldCreateSpan = url => {
      return defaultShouldCreateSpan(url) && shouldCreateSpanForRequest(url);
    };
  }
  var spans = {};
  if (traceFetch) {
    addInstrumentationHandler('fetch', handlerData => {
      fetchCallback(handlerData, shouldCreateSpan, spans);
    });
  }
  if (traceXHR) {
    addInstrumentationHandler('xhr', handlerData => {
      xhrCallback(handlerData, shouldCreateSpan, spans);
    });
  }
}

/**
 * Create and track fetch request spans
 */
function fetchCallback(handlerData, shouldCreateSpan, spans) {
  if (!hasTracingEnabled() || !(handlerData.fetchData && shouldCreateSpan(handlerData.fetchData.url))) {
    return;
  }
  if (handlerData.endTimestamp) {
    var spanId = handlerData.fetchData.__span;
    if (!spanId) return;
    var span = spans[spanId];
    if (span) {
      if (handlerData.response) {
        // TODO (kmclb) remove this once types PR goes through
        span.setHttpStatus(handlerData.response.status);
      } else if (handlerData.error) {
        span.setStatus('internal_error');
      }
      span.finish();
      delete spans[spanId];
    }
    return;
  }
  var activeTransaction = getActiveTransaction();
  if (activeTransaction) {
    var span = activeTransaction.startChild({
      data: {
        ...handlerData.fetchData,
        type: 'fetch'
      },
      description: `${handlerData.fetchData.method} ${handlerData.fetchData.url}`,
      op: 'http.client'
    });
    handlerData.fetchData.__span = span.spanId;
    spans[span.spanId] = span;
    var request = handlerData.args[0];

    // In case the user hasn't set the second argument of a fetch call we default it to `{}`.
    handlerData.args[1] = handlerData.args[1] || {};
    var options = handlerData.args[1];
    options.headers = addTracingHeadersToFetchRequest(request, activeTransaction.getDynamicSamplingContext(), span, options);
    activeTransaction.metadata.propagations += 1;
  }
}
function addTracingHeadersToFetchRequest(request, dynamicSamplingContext, span, options) {
  var sentryBaggageHeader = dynamicSamplingContextToSentryBaggageHeader(dynamicSamplingContext);
  var sentryTraceHeader = span.toTraceparent();
  var headers = typeof Request !== 'undefined' && isInstanceOf(request, Request) ? request.headers : options.headers;
  if (!headers) {
    return {
      'sentry-trace': sentryTraceHeader,
      baggage: sentryBaggageHeader
    };
  } else if (typeof Headers !== 'undefined' && isInstanceOf(headers, Headers)) {
    var newHeaders = new Headers(headers);
    newHeaders.append('sentry-trace', sentryTraceHeader);
    if (sentryBaggageHeader) {
      // If the same header is appended miultiple times the browser will merge the values into a single request header.
      // Its therefore safe to simply push a "baggage" entry, even though there might already be another baggage header.
      newHeaders.append(BAGGAGE_HEADER_NAME, sentryBaggageHeader);
    }
    return newHeaders;
  } else if (Array.isArray(headers)) {
    var newHeaders = [...headers, ['sentry-trace', sentryTraceHeader]];
    if (sentryBaggageHeader) {
      // If there are multiple entries with the same key, the browser will merge the values into a single request header.
      // Its therefore safe to simply push a "baggage" entry, even though there might already be another baggage header.
      newHeaders.push([BAGGAGE_HEADER_NAME, sentryBaggageHeader]);
    }
    return newHeaders;
  } else {
    var existingBaggageHeader = 'baggage' in headers ? headers.baggage : undefined;
    var newBaggageHeaders = [];
    if (Array.isArray(existingBaggageHeader)) {
      newBaggageHeaders.push(...existingBaggageHeader);
    } else if (existingBaggageHeader) {
      newBaggageHeaders.push(existingBaggageHeader);
    }
    if (sentryBaggageHeader) {
      newBaggageHeaders.push(sentryBaggageHeader);
    }
    return {
      ...headers,
      'sentry-trace': sentryTraceHeader,
      baggage: newBaggageHeaders.length > 0 ? newBaggageHeaders.join(',') : undefined
    };
  }
}

/**
 * Create and track xhr request spans
 */
function xhrCallback(handlerData, shouldCreateSpan, spans) {
  if (!hasTracingEnabled() || handlerData.xhr && handlerData.xhr.__sentry_own_request__ || !(handlerData.xhr && handlerData.xhr.__sentry_xhr__ && shouldCreateSpan(handlerData.xhr.__sentry_xhr__.url))) {
    return;
  }
  var xhr = handlerData.xhr.__sentry_xhr__;

  // check first if the request has finished and is tracked by an existing span which should now end
  if (handlerData.endTimestamp) {
    var spanId = handlerData.xhr.__sentry_xhr_span_id__;
    if (!spanId) return;
    var span = spans[spanId];
    if (span) {
      span.setHttpStatus(xhr.status_code);
      span.finish();
      delete spans[spanId];
    }
    return;
  }

  // if not, create a new span to track it
  var activeTransaction = getActiveTransaction();
  if (activeTransaction) {
    var span = activeTransaction.startChild({
      data: {
        ...xhr.data,
        type: 'xhr',
        method: xhr.method,
        url: xhr.url
      },
      description: `${xhr.method} ${xhr.url}`,
      op: 'http.client'
    });
    handlerData.xhr.__sentry_xhr_span_id__ = span.spanId;
    spans[handlerData.xhr.__sentry_xhr_span_id__] = span;
    if (handlerData.xhr.setRequestHeader) {
      try {
        handlerData.xhr.setRequestHeader('sentry-trace', span.toTraceparent());
        var dynamicSamplingContext = activeTransaction.getDynamicSamplingContext();
        var sentryBaggageHeader = dynamicSamplingContextToSentryBaggageHeader(dynamicSamplingContext);
        if (sentryBaggageHeader) {
          // From MDN: "If this method is called several times with the same header, the values are merged into one single request header."
          // We can therefore simply set a baggage header without checking what was there before
          // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/setRequestHeader
          handlerData.xhr.setRequestHeader(BAGGAGE_HEADER_NAME, sentryBaggageHeader);
        }
        activeTransaction.metadata.propagations += 1;
      } catch (_) {
        // Error: InvalidStateError: Failed to execute 'setRequestHeader' on 'XMLHttpRequest': The object's state must be OPENED.
      }
    }
  }
}
export { DEFAULT_TRACING_ORIGINS, defaultRequestInstrumentationOptions, fetchCallback, instrumentOutgoingRequests, xhrCallback };
