import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class WorkflowsService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get configuration
     * @param id configuration ID
     * @returns Backend API response
     */
    getWorkflowConfiguration(id: number): Observable<object> {
        return this.httpClient.get<any>(`${PROTECTED_URLS.WORKFLOW_CONFIGURATIONS}${id}/`);
    }

    /**
     * Save configuration
     * @param configuration Object with configuration ID and configuration data needed to save configuration
     * @returns Backend API response
     */
    saveWorkflowConfiguration(configuration: any): Observable<object> {
        return this.httpClient.put<any>(`${PROTECTED_URLS.WORKFLOW_CONFIGURATIONS}${configuration.id}/`, {
            ...configuration.data,
        });
    }

    /**
     * Create configuration
     * @param configuration Object with configuration data needed to create configuration
     * @returns Backend API response
     */
    createWorkflowConfiguration(configuration: any): Observable<object> {
        return this.httpClient.post<any>(PROTECTED_URLS.WORKFLOW_CONFIGURATIONS, { ...configuration.data });
    }
}
