import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export class UploadService {
    constructor(protected httpClient: HttpClient) {}

    upload<T>(url: string, body: object): Observable<T> {
        return this.httpClient.patch<T>(url, body);
    }

    postUpload<T>(url: string, body: object): Observable<T> {
        return this.httpClient.post<T>(url, body);
    }
}
