<div
  class="notification"
  [@enterLeave]="item.state"
  (click)="onClick($event)"
  [ngClass]="{
    'notification--alert': item.type === 'alert',
    'notification--error': item.type === 'error',
    'notification--success': item.type === 'success',
    'notification--info': item.type === 'info',
    'notification--bare': item.type === 'bare',
    'notification--rtl-mode': rtl
  }"
  (mouseenter)="onEnter()"
  (mouseleave)="onLeave()">
    <div class="notification__body" *ngIf="!item.html">
      <div class="notification__title">{{ item.title }}</div>
      <div class="notification__content">{{ item.content }}</div>
    </div>

    <div class="notification__body" *ngIf="item.html" [innerHTML]="item.html"></div>

    <div class="notification__loader" *ngIf="showProgressBar">
      <span [ngStyle]="{ 'width': progressWidth + '%' }"></span>
    </div>
  </div>
