import { getCurrentHub } from '@sentry/hub';
export { TRACEPARENT_REGEXP, extractTraceparentData, stripUrlQueryAndFragment } from '@sentry/utils';

/**
 * Determines if tracing is currently enabled.
 *
 * Tracing is enabled when at least one of `tracesSampleRate` and `tracesSampler` is defined in the SDK config.
 */
function hasTracingEnabled(maybeOptions) {
  var client = getCurrentHub().getClient();
  var options = maybeOptions || client && client.getOptions();
  return !!options && ('tracesSampleRate' in options || 'tracesSampler' in options);
}

/** Grabs active transaction off scope, if any */
function getActiveTransaction(maybeHub) {
  var hub = maybeHub || getCurrentHub();
  var scope = hub.getScope();
  return scope && scope.getTransaction();
}

/**
 * Converts from milliseconds to seconds
 * @param time time in ms
 */
function msToSec(time) {
  return time / 1000;
}

/**
 * Converts from seconds to milliseconds
 * @param time time in seconds
 */
function secToMs(time) {
  return time * 1000;
}
export { getActiveTransaction, hasTracingEnabled, msToSec, secToMs };
