import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class NutrientsService {
    constructor(private fetchService: HttpClient) {}

    /**
     * Get nutrients list
     * @param params Object with list params
     * @returns Backend API response
     */
    getNutrients(params: any = {}): Observable<Response> {
        return this.fetchService.get<any>(PROTECTED_URLS.NUTRIENTS, {
            params,
        });
    }

    /**
     * Get specific nutrient
     * @param id Desired nutrient's id
     * @returns Backend API response
     */
    getNutrient(id: number): Observable<Response> {
        return this.fetchService.get<any>(`${ PROTECTED_URLS.NUTRIENTS }${id}/`);
    }

    /**
     * Create nutrient
     * @param nutrient Object with nutrient data needed to create nutrient
     * @returns Backend API response
     */
    createNutrient(nutrient: any): Observable<any> {
        return this.fetchService.post<any>(PROTECTED_URLS.NUTRIENTS, { ...nutrient.data });
    }

    /**
     * Update existing nutrient
     * @param nutrient Object with nutrient id and nutrient data to be saved
     * @returns Backend API response
     */
    saveNutrient(nutrient: any): Observable<any> {
        return this.fetchService.put<any>(`${ PROTECTED_URLS.NUTRIENTS }${ nutrient.id }/`, { ...nutrient.data });
    }

    /**
     * Delete nutrient
     * @param id Nutrient ID
     * @returns Backend API response
     */
    deleteNutrient(id: number): Observable<Response> {
        return this.fetchService.delete<any>(`${ PROTECTED_URLS.NUTRIENTS }${id}/`);
    }

    /**
     * Get nutrients options
     * @returns Backend API response
     */
    getOptions(): Observable<any> {
        return this.fetchService.options<any>(PROTECTED_URLS.NUTRIENTS);
    }
}
