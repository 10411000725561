import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { FilteringParams, MediaProfileRequest } from '@core/models';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { HTTPClientVer } from '@core/utils/request.utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import * as mediaProfiles from '../actions/media-profiles.actions';

import { MediaProfilesService } from '../services/media-profiles.service';
import { NotificationsService } from '../services/notifications.service';
import { SectionGroupService } from '../services/section-group.service';

import { catchErrorJson } from './catch-error';

@Injectable()
export class MediaProfilesEffects {
    
    public postMediaProfileRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(mediaProfiles.ActionTypes.CREATE_MEDIA_PROFILE_REQUEST),
        map((action: mediaProfiles.CreateMediaProfileRequestAction) => action.payload),
        switchMap((payload: MediaProfileRequest) => {
            return this.mediaProfilesService.postMediaProfile(payload.data).pipe(
                tap((response) => {
                    this.notificationsService.success(`Saved media profile.`);
                    if (payload.redirectTo) {
                        const navigateExtras: NavigationExtras = {
                            queryParams: payload.params,
                        };

                        this.router.navigate([payload.redirectTo], navigateExtras);
                    }
                }),
                map((response) => new mediaProfiles.CreateMediaProfileSuccessAction(response)),
                catchError((error) =>
                    of(new mediaProfiles.CreateMediaProfileErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    public getMediaProfileOptionsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(mediaProfiles.ActionTypes.GET_MEDIA_PROFILE_OPTIONS_REQUEST),
        map((action: mediaProfiles.GetMediaProfileOptionsRequestAction) => action.payload),
        switchMap(() => {
            return this.mediaProfilesService.getMediaProfileOptions().pipe(
                map((response) => new mediaProfiles.GetMediaProfileOptionsSuccessAction(response.actions.POST)),
                catchError((error) =>
                    of(new mediaProfiles.GetMediaProfileOptionsErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    public getMediaProfilesRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(mediaProfiles.ActionTypes.GET_MEDIA_PROFILES_REQUEST),
        map((action: mediaProfiles.GetMediaProfilesRequestAction) => action.payload),
        switchMap((payload: FilteringParams) => {
            return this.mediaProfilesService.getMediaProfiles(payload).pipe(
                map((response) => new mediaProfiles.GetMediaProfilesSuccessAction(response)),
                catchError((error) =>
                    of(new mediaProfiles.GetMediaProfilesErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    public updateMediaProfileRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(mediaProfiles.ActionTypes.SAVE_MEDIA_PROFILE_REQUEST),
        map((action: mediaProfiles.SaveMediaProfileRequestAction) => action.payload),
        switchMap((payload: MediaProfileRequest) => {
            return this.mediaProfilesService.updateMediaProfile(payload.data).pipe(
                tap((response) => {
                    this.notificationsService.success(`Updated media profile.`);
                    if (payload.redirectTo) {
                        const navigateExtras: NavigationExtras = {
                            queryParams: payload.params,
                        };

                        this.router.navigate([payload.redirectTo], navigateExtras);
                    }
                }),
                map((response) => new mediaProfiles.SaveMediaProfileSuccessAction(response)),
                catchError((error) =>
                    of(new mediaProfiles.SaveMediaProfileErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    public getMediaProfileRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(mediaProfiles.ActionTypes.GET_MEDIA_PROFILE_REQUEST),
        map((action: mediaProfiles.GetMediaProfileRequestAction) => action.payload),
        switchMap((payload: number) => {
            return this.mediaProfilesService.getMediaProfile(payload).pipe(
                map((response) => new mediaProfiles.GetMediaProfileSuccessAction(response)),
                catchError((error) =>
                    of(new mediaProfiles.GetMediaProfileErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    public deleteMediaProfileRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(mediaProfiles.ActionTypes.DELETE_MEDIA_PROFILE_REQUEST),
        map((action: mediaProfiles.DeleteMediaProfileRequestAction) => action.payload),
        switchMap((payload: MediaProfileRequest) => {
            return this.mediaProfilesService.deleteMediaProfile(payload.data).pipe(
                tap((response) => {
                    this.notificationsService.success(`Delete media profile.`);
                    if (payload.redirectTo) {
                        const navigateExtras: NavigationExtras = {
                            queryParams: payload.params,
                        };

                        this.router.navigate([payload.redirectTo], navigateExtras);
                    }
                }),
                map((response) => new mediaProfiles.DeleteMediaProfileSuccessAction(response)),
                catchError((error) =>
                    of(new mediaProfiles.DeleteMediaProfileErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    public getMediaProfileAvailableImagesRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(mediaProfiles.ActionTypes.GET_MEDIA_PROFILE_AVAILABLE_IMAGES_REQUEST),
        map((action: mediaProfiles.GetMediaProfileAvailableImagesRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.mediaProfilesService.getAvailableImages(payload).pipe(
                map((response) => new mediaProfiles.GetMediaProfileAvailableImagesSuccessAction(response)),
                catchError((error) =>
                    of(
                        new mediaProfiles.GetMediaProfileAvailableImagesErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    ));

    
    public postSampleImagePreview$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(mediaProfiles.ActionTypes.POST_SAMPLE_IMAGE_REQUEST),
        map((action: mediaProfiles.PostSampleImageRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.mediaProfilesService.previewSampleImage(payload).pipe(
                map((response) => new mediaProfiles.PostSampleImageSuccessAction(response)),
                catchError((error) =>
                    of(new mediaProfiles.PostSampleImageErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    constructor(
        private actions$: Actions,
        private router: Router,
        private mediaProfilesService: MediaProfilesService,
        private notificationsService: NotificationsService,
        private sectionGroupService: SectionGroupService,
    ) {}
}
