import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import * as attributeGroups from '../actions/attribute-groups.actions';

import { AttributeGroupsService } from '../services/attribute-groups.service';
import { ModalService } from '../services/modal.service';
import { NotificationsService } from '../services/notifications.service';

import { HTTPClientVer } from '@core/utils/request.utils';
import { catchErrorJson } from './catch-error';

@Injectable()
export class AttributeGroupsEffects {
    constructor(
        private router: Router,
        private actions$: Actions,
        private attributeGroupsService: AttributeGroupsService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
    ) {}

    
    getAttributeGroupsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<any>(attributeGroups.ActionTypes.GET_ATTRIBUTE_GROUPS_REQUEST),
        switchMap((action) => {
            return this.attributeGroupsService.getAttributeGroups(action.payload).pipe(
                map((response) => new attributeGroups.GetAttributeGroupsSuccessAction(response)),
                catchError((error) =>
                    of(new attributeGroups.GetAttributeGroupsErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getSimpleAttributeGroupsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<any>(attributeGroups.ActionTypes.GET_SIMPLE_ATTRIBUTE_GROUPS_REQUEST),
        switchMap((action) => {
            return this.attributeGroupsService.getSimpleAttributeGroups(action.payload).pipe(
                map((response) => new attributeGroups.GetAttributeGroupsSuccessAction(response)),
                catchError((error) =>
                    of(new attributeGroups.GetAttributeGroupsErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getAttributeGroupRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<any>(attributeGroups.ActionTypes.GET_ATTRIBUTE_GROUP_REQUEST),
        map((action: attributeGroups.GetAttributeGroupRequestAction) => action.payload),
        switchMap((payload) => {
            return this.attributeGroupsService.getAttributeGroup(payload).pipe(
                map((response) => new attributeGroups.GetAttributeGroupSuccessAction(response)),
                catchError((error) =>
                    of(new attributeGroups.GetAttributeGroupErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    saveAttributeGroupRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(attributeGroups.ActionTypes.SAVE_ATTRIBUTE_GROUP_REQUEST),
        map((action: attributeGroups.SaveAttributeGroupRequestAction) => action.payload),
        switchMap((payload) => {
            return this.attributeGroupsService.saveAttributeGroup(payload).pipe(
                tap((response) =>
                    this.notificationsService.success(
                        `Saved attribute group ${(response as any).translations.en.name}`,
                    ),
                ),
                tap(() => this.router.navigate(['/attribute-groups'])),
                map((response) => new attributeGroups.SaveAttributeGroupSuccessAction(response)),
                catchError((error) =>
                    of(new attributeGroups.SaveAttributeGroupErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    createAttributeGroupRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(attributeGroups.ActionTypes.CREATE_ATTRIBUTE_GROUP_REQUEST),
        map((action: attributeGroups.CreateAttributeGroupRequestAction) => action.payload),
        switchMap((payload) => {
            return this.attributeGroupsService.createAttributeGroup(payload).pipe(
                tap((response) =>
                    this.notificationsService.success(
                        `Created attribute group ${(response as any).translations.en.name}`,
                    ),
                ),
                tap(() => this.router.navigate(['/attribute-groups'])),
                map((response) => new attributeGroups.CreateAttributeGroupSuccessAction(response)),
                catchError((error) =>
                    of(new attributeGroups.CreateAttributeGroupErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    deleteAttributeGroupRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(attributeGroups.ActionTypes.DELETE_ATTRIBUTE_GROUP_REQUEST),
        map((action: attributeGroups.DeleteAttributeGroupRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.attributeGroupsService.deleteAttributeGroup(payload.id).pipe(
                tap(
                    () => this.router.navigate([payload.redirectTo]),
                    () => this.modalService.close('delete-attribute-group-modal'),
                ),
                tap(() => this.notificationsService.success('Deleted attribute group')),
                map(() => new attributeGroups.DeleteAttributeGroupSuccessAction()),
                catchError((error) =>
                    of(new attributeGroups.DeleteAttributeGroupErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));
}
