import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
    CompanyWhitelistedIpsListPayload,
    WhitelistedIpCreatePayload,
    WhitelistedIpId,
    WhitelistedIpPostPayload,
} from '@core/models';

const BASE_URL: string = 'company_public_api_whitelisted_ips';

@Injectable()
export class CompanyWhitelistedIpsService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get company whitelist IPs list
     * @param params Object with list params
     * @returns Backend API response
     */
    getCompanyWhitelistedIps(params: CompanyWhitelistedIpsListPayload): Observable<Object> {
        return this.httpClient.get<any>(`${BASE_URL}/`, { params });
    }

    /**
     * Get company whitelisted IP details
     * @param id Company Whitelisted IP ID
     * @returns Backend API response
     */
    getCompanyWhitelistedIp(id: WhitelistedIpId): Observable<Object> {
        return this.httpClient.get<any>(`${BASE_URL}/${id}/`);
    }

    /**
     * Save company whitelisted IP
     * @param payload Object with company whitelisted IP details
     * @returns Backend API response
     */
    saveCompanyWhitelistedIp(payload: WhitelistedIpPostPayload): Observable<Object> {
        return this.httpClient.patch<any>(`${BASE_URL}/${payload.id}/`, { ...payload.data });
    }

    /**
     * Create company whitelisted IP
     * @param payload Object with company whitelisted IP details
     * @returns Backend API response
     */
    createCompanyWhitelistedIp(payload: WhitelistedIpCreatePayload): Observable<Object> {
        return this.httpClient.post<any>(`${BASE_URL}/`, { ...payload.data });
    }

    /**
     * Delete company whitelisted IP
     * @param id Company whitelisted IP ID
     * @returns Backend API response
     */
    deleteCompanyWhitelistedIp(id: WhitelistedIpId): Observable<Object> {
        return this.httpClient.delete<any>(`${BASE_URL}/${id}/`);
    }
}
