import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import * as partnerships from '../actions/partnerships.actions';

import { HTTPClientVer } from '@core/utils/request.utils';
import { PartnershipsService } from '../services/partnerships.service';
import { catchErrorJson } from './catch-error';

@Injectable()
export class PartnershipsEffects {
    constructor(private actions$: Actions, private partnershipsService: PartnershipsService) {}

    
    getPartnershipsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(partnerships.ActionTypes.GET_PARTNERSHIPS_REQUEST),
        map((action: partnerships.GetPartnershipsRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.partnershipsService.getPartnerships(payload).pipe(
                map((response) => new partnerships.GetPartnershipsSuccessAction(response)),
                catchError((error) =>
                    of(new partnerships.GetPartnershipsErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getPartnershipRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(partnerships.ActionTypes.GET_PARTNERSHIP_REQUEST),
        map((action: partnerships.GetPartnershipRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.partnershipsService.getPartnership(payload).pipe(
                map((response) => new partnerships.GetPartnershipSuccessAction(response)),
                catchError((error) =>
                    of(new partnerships.GetPartnershipErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));
}
