import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { IngredientGroup } from '@core/models';

import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class IngredientGroupsService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get ingredient groups list
     * @param params Object with list params
     * @returns Backend API response
     */
    getIngredientGroups(params: any = {}): Observable<any> {
        return this.httpClient.get(PROTECTED_URLS.INGREDIENT_GROUPS, params);
    }

    /**
     * Get ingredient group
     * @param id Atribiute group ID
     * @returns Backend API response
     */
    getIngredientGroup(id: number): Observable<any> {
        return this.httpClient.get<IngredientGroup>(`${PROTECTED_URLS.INGREDIENT_GROUPS}${id}/`);
    }

    /**
     * Save ingredient group
     * @param ingredientGroup Object with ingredient group ID and ingredient data needed to save ingredient
     * @returns Backend API response
     */
    saveIngredientGroup(ingredientGroup: any): Observable<any> {
        return this.httpClient.put(`${PROTECTED_URLS.INGREDIENT_GROUPS}${ingredientGroup.id}/`, ingredientGroup.data);
    }

    /**
     * Create ingredient
     * @param ingredientGroup Object with ingredient data needed to create ingredient
     * @returns Backend API response
     */
    createIngredientGroup(ingredientGroup: any): Observable<any> {
        return this.httpClient.post<IngredientGroup>(PROTECTED_URLS.INGREDIENT_GROUPS, ingredientGroup.data);
    }

    /**
     * Delete ingredient
     * @param id Ingredient group ID
     * @returns Backend API response
     */
    deleteIngredientGroup(id: number): Observable<any> {
        return this.httpClient.delete(`${PROTECTED_URLS.INGREDIENT_GROUPS}${id}/`);
    }
}
