/**
 * Auxiliary tool for print all errors from all places
 * where recent developers mark by empty observable.
 *
 * This class should be removed or use in more proper way
 * after finish refactoring process.
 */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { environment } from '@env/environment';

import { ActionTypes, AnyErrors } from '@core/actions/common.actions';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class CommonEffects {
    constructor(private actions$: Actions) {}

    
    displayErrorInConsole$: any = createEffect(() => this.actions$.pipe(
        ofType(ActionTypes.ANY_ERRORS),
        map((action: AnyErrors) => action.payload),
        tap((payload) => {
            if (!environment.production) {
                console.log(payload);
            } else {
                console.log('TODO: uuid or info to BE or sentry for prod env');
            }
        }),
    ), { dispatch: false });
}
