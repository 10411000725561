import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UploadService } from '@core/services/upload.service';
import { Observable } from 'rxjs';
import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class NewsService {
    constructor(private httpClient: HttpClient, private uploadService: UploadService) {}

    /**
     * Get mews list
     * @param params Object with list params
     * @returns Backend API response
     */
    getNews(params: any = ''): Observable<object> {
        return this.httpClient.get<any>(PROTECTED_URLS.NEWS, { params });
    }

    /**
     * Create news
     * @param array containing news with all necessary information
     * @returns Backend API response
     */
    saveNews(news: any): Observable<object> {
        return this.httpClient.patch<any>(PROTECTED_URLS.NEWS, { news });
    }

    /**
     * Upload news image
     * @param news Object with news data needed to upload image
     * @returns Backend API response
     */
    uploadNewsImage(news: any): Observable<object> {
        const payload = new FormData();
        payload.append('image', news.data);

        return this.uploadService.upload(`${PROTECTED_URLS.NEWS}${news.id}/`, { payload });
    }
}
