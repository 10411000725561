import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { Observable } from 'rxjs';

import { UploadService } from '@core/services/upload.service';

import {
    DefaultOptionResponse,
    GetProductAttributesDiffRequestPayload,
    GetProductRequestPayload,
    GetProductsListRequestPayload,
    ImportOutputFormat,
    Product,
    ProductId,
    ProductsExportPayload,

    ReadinessReportRequestPayload,
    SaveProductRequestPayload,
} from '@core/models';

import { parseToQueryString } from '@app/utils';

const PRODUCTS_URL = 'products/';
interface ImportTemplatePayload {
    companyId: string;
    outputFormat: ImportOutputFormat;
}

@Injectable()
export class ProductsService {
    constructor(private httpClient: HttpClient, private uploadService: UploadService) {}

    /**
     * Get products list
     * @param params Object with body for filtering data and query params for sorting and pagination
     * @returns Backend API response
     */
    getProducts(params?: GetProductsListRequestPayload): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}post_list/${parseToQueryString(params.query)}`, params.body);
    }

    /**
     * Get product details
     * @param product: product information
     * @returns Backend API response
     */
    getProduct(product: GetProductRequestPayload): Observable<any> {
        return this.httpClient.get<any>(`${PRODUCTS_URL}${product.id}/`, { params: product.params || {} } as any);
    }

    /**
     * Create product
     * @param product Object with product data needed to create product
     * @returns Backend API response
     */
    createProduct(product: any): Observable<any> {
        return this.httpClient.post(PRODUCTS_URL, product.data);
    }

    /**
     * Create product
     * @param product Object with product data needed to create product
     * @returns Backend API response
     */
    saveProduct(product: SaveProductRequestPayload): Observable<any> {
        return this.httpClient.patch(`${PRODUCTS_URL}${product.id}/`, { ...product.data });
    }

    /**
     * Upload product files
     * @param product Object with product data needed to upload files
     * @returns Backend API response
     */
    uploadProductFiles(product: any): Observable<any> {
        return this.uploadService.upload<any>(`${PRODUCTS_URL}${product.id}/`, product.upload.formData);
    }

    uploadReceiptFiles(productId: number, formData: any): Observable<any> {
        return this.uploadService.upload<any>(`receipts/${productId}/`, formData);
    }

    /**
     * Delete product files
     * @param product Object with names of files to delete
     * @returns Backend API response
     */
    deleteProductFiles(product: any): Observable<any> {
        return this.httpClient.patch(`${PRODUCTS_URL}${product.id}/`, product.delete.data);
    }

    /**
     * Soft delete product (can be restored)
     * @param id Product ID
     * @returns Backend API response
     */
    softDeleteProduct(id: ProductId): Observable<any> {
        return this.httpClient.patch(`${PRODUCTS_URL}${id}/`, { isSoftDeleted: true });
    }

    /**
     * Hard delete product (cannot be restored)
     * @param id Product ID
     * @returns Backend API response
     */
    hardDeleteProduct(id: ProductId): Observable<any> {
        return this.httpClient.delete<any>(`${PRODUCTS_URL}${id}/`);
    }

    /**
     * Soft delete products (can be restored)
     * @param ids Array with products' IDs
     * @returns Backend API response
     */
    softDeleteProducts(ids: ProductId[]): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}bulk_soft_delete/`, { ids });
    }

    /**
     * Hard delete products (cannot be restored)
     * @param ids Array with products' IDs
     * @returns Backend API response
     */
    hardDeleteProducts(ids: ProductId[]): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}bulk_delete/`, { ids });
    }

    /**
     * Recover product (if it was soft deleted)
     * @param id Product ID
     * @returns Backend API response
     */
    recoverProduct(id: ProductId): Observable<any> {
        return this.httpClient.patch<any>(`${PRODUCTS_URL}${id}/`, { isSoftDeleted: false });
    }

    /**
     * Recover products (if there were soft deleted)
     * @param ids Array with products' IDs
     * @returns Backend API response
     */
    recoverProducts(ids: ProductId[]): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}bulk_recover/`, { ids });
    }

    /**
     * Set products to live
     * @param ids Array with products' IDs
     * @returns Backend API response
     */
    setProductsToLive(ids: ProductId[]): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}bulk_set_to_live/`, { ids });
    }

    /**
     * Discontinue product
     * @param id Product ID
     * @returns Backend API response
     */
    discontinueProduct(id: ProductId): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}${id}/discontinue/`, {});
    }

    /**
     * Discontinue products
     * @param ids Array with products' IDs
     * @returns Backend API response
     */
    discontinueProducts(ids: ProductId[]): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}bulk_discontinue/`, { ids });
    }

    /**
     * Revert discontinue product
     * @param id Product ID
     * @returns Backend API response
     */
    revertDiscontinueProduct(id: ProductId): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}${id}/revert_discontinue/`, {});
    }

    /**
     * Revert discontinue products
     * @param ids Array with products' IDs
     * @returns Backend API response
     */
    revertDiscontinueProducts(ids: ProductId[]): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}bulk_revert_discontinue/`, { ids });
    }

    /**
     * Get product changes history request
     * @param id Product ID
     * @param params
     * @returns Backend API response
     */
    getChangesHistory(id: ProductId, params: any = {}): Observable<any> {
        return this.httpClient.get<any>(`${PRODUCTS_URL}${id}/history/`, { params });
    }

    /**
     * Get product shares history request
     * @param id Product ID
     * @returns Backend API response
     */
    getSharesHistory(id: ProductId): Observable<any> {
        return this.httpClient.get<any>(`${PRODUCTS_URL}${id}/sharing_history/`);
    }

    /**
     * TODO: options return in this case VERY IMPORTANT data
     * this should be rewritten on BE side (add /products/options endpoint)
     * and there should be GET call to the new endpoint
     *
     * Get options products request
     * @returns Backend API response
     */
    getOptions(): Observable<Product> {
        return this.httpClient
            .options<DefaultOptionResponse>(PRODUCTS_URL)
            .pipe(map((response) => response.actions.POST));
    }

    /**
     * TODO: options return in this case VERY IMPORTANT data
     * this should be rewritten on BE side (add /products/options endpoint)
     * and there should be GET call to the new endpoint
     *
     * Get options for particular product
     * @param id Product ID
     * @returns Backend API response
     */
    getProductOptions(id: ProductId): Observable<Product> {
        return this.httpClient
            .options<DefaultOptionResponse>(`${PRODUCTS_URL}${id}/`)
            .pipe(map((response) => response.actions?.PUT));
    }

    /**
     * Post share products request
     * @returns Backend API response
     */
    shareProducts(payload): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}share/`, payload);
    }

    /**
     * Prepare local products import request
     * @param data Object with import data needed to import products
     * @returns Backend API response
     */
    prepareLocalProductsImport(data: any): Observable<any> {
        return this.uploadService.upload<any>(`${PRODUCTS_URL}import/`, data);
    }

    /**
     * Prepare products export request
     * @param config Object that contains mode and array with products' IDs
     * @returns Backend API response
     */
    prepareProductsExport(config: any): Observable<any> {
        return this.httpClient.post<any>(
            config.workflow ? 'listed_products/export_xlsx/' :
                `products/export_${ config.mode }${ config.filtered ? '_limited' : '' }/`,
            {
                ...config.params,
                id__in: config.ids,
                sendMail: config.sendMail,
                newestCopy: config.newestCopy ? config.newestCopy : false,
            });
    }

    /**
     * Prepare products export sharing history request
     * @param config Objects that contains mode and array with products IDs
     * @returns Backend API response
     */
    prepareProductsSharingHistoryExport(config: any): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}export_sharing_history/`, {
            id__in: config.ids,
            sendMail: config.sendMail,
        });
    }

    /**
     * Prepare product media export request (single product)
     * @param config Object that contains array with products' IDs
     * @returns Backend API response
     */
    prepareProductMediasExport(config: any): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}${config.id}/export_medias/`, { sendMail: config.sendMail });
    }

    getBarcodeService(config: any): Observable<any> {
        return this.httpClient.get<any>(`${PRODUCTS_URL}${config.id}/get_barcode/?code=${config.barcode_type}`);
    }

    /**
     * Prepare products medias export request (multiple products)
     * @param config Object that contains array with products' IDs
     * @returns Backend API response
     */
    prepareProductsMediasExport(config: ProductsExportPayload): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}export_medias/`, {
            id__in: config.ids,
            sendMail: config.sendMail,
        });
    }

    /**
     * Prepare products rejection reasons export request (multiple products)
     * @param config Object that contains array with products' IDs
     * @returns Backend API response
     */
    prepareProductsRejectionReasonsExport(config: ProductsExportPayload): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}rejection_reasons_export_xls/`, {
            id__in: config.ids,
            sendMail: config.sendMail,
        });
    }

    /**
     * Get export status request
     * @param id Celery task ID
     * @returns Backend API response
     */
    getExportStatus(id: number): Observable<any> {
        return this.httpClient.get<any>(`${PRODUCTS_URL}export_status/${id}/`);
    }

    /**
     * Clone product with a given ID
     * @param id Product ID
     * @returns Backend API response
     */
    cloneProduct(id: ProductId): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}${id}/clone/`, {});
    }

    /**
     * Clone products with a given IDs
     * @param products Object with products IDs
     * @returns Backend API response
     */
    bulkCloneProducts(products: ProductId[]): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}bulk_clone/`, products);
    }

    /**
     * Bulk clone products status request with a given IDs
     * @param statusId String with status ID
     * @returns Backend API response
     */
    getBulkCloneProductsStatus(statusId: string): Observable<any> {
        return this.httpClient.get<any>(`${PRODUCTS_URL}bulk_clone_status/${statusId}/`);
    }

    /**
     * Acknowledge product updates
     * @param id Product ID
     * @returns Backend API response
     */
    acknowledgeProductUpdate(id: ProductId): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}${id}/acknowledge_update/`, {});
    }

    /**
     * Acknowledge products updates
     * @param data Object with data needed to acknowledge products updates
     * @returns Backend API response
     */
    acknowledgeProductsUpdate(data: any): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}bulk_acknowledge_update/`, data);
    }

    /**
     * Get product scores pre channel
     * @param id The ID of Product
     * @returns Backend API response
     */
    getProductScores(id: ProductId): Observable<any> {
        return this.httpClient
            .get<any>(`scores-per-partner/${id}/`)
            .pipe(map((response) => response.partnerScores || []));
    }

    /**
     * Get products readiness report
     * @param payload
     * @returns Backend API response
     */
    getReadinessReport(payload: ReadinessReportRequestPayload): Observable<any> {
        return this.httpClient.post<any>('scorecards/report/', payload);
    }

    downloadBarcodeImages(payload: any): Observable<any> {
        return this.httpClient.post<any>(`${PRODUCTS_URL}download_barcode_images/`, {
            id__in: payload.ids,
            sendMail: payload.sendMail,
            barcode_type: payload.barcode_type,
        });
    }

    /**
     * Get Attributes Diff against current product category and given one
     * @param product: product information
     * @returns Backend API response
     */
    getProductAttributesDiff(payload: GetProductAttributesDiffRequestPayload): Observable<any> {
        return this.httpClient.get<any>(`${PRODUCTS_URL}${payload.id}/attributes_diff/?category_id=${payload.categoryId}`);
    }
}
