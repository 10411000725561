import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { buildFormData } from '@app/utils';
import { ProductReviewRequest } from '@core/models';
import { UploadService } from '@core/services/upload.service';

@Injectable()
export class ProductReviewsService {
    constructor(private httpClient: HttpClient, private uploadService: UploadService) {}

    /**
     * Post product review
     * @param review Object with product review data needed to create review
     * @returns Backend API response
     */
    postProductReview(review: any): Observable<any> {
        return this.uploadService.postUpload<any>(`product_reviews/`, review);
    }

    /**
     * Post bulk products review
     * @param review Object with product review data needed to create review
     * @returns Backend API response
     */
    postBulkProductsReview(review: ProductReviewRequest): Observable<any> {
        return this.uploadService.postUpload<any>(`product_reviews/bulk/`, buildFormData(review));
    }

    /**
     * Get product reviews
     * @param params Object with product review id
     * @returns Backend API response
     */
    getProductReviews(params: any): Observable<any> {
        return this.httpClient.get<any>(`product_reviews/`, { params });
    }

    /**
     * Get rejection reasons data
     * @returns Backend API response
     */
    getRejectionReasons(params): Observable<any> {
        return this.httpClient.get<any>(`rejection_reasons/`, { params });
    }

    /**
     * Get bulk products review status request
     * @param id Celecry task ID
     * @returns Backend API response
     */
    getBulkReviewStatus(id: string): Observable<any> {
        return this.httpClient.get<any>(`bulk_product_review_action_status/${id}/`);
    }
}
