<div
  class="notifications"
  [ngClass]="position">
  <notification
    *ngFor="let notification of notifications; let i = index"
    [item]="notification"
    [timeOut]="timeOut"
    [clickToClose]="clickToClose"
    [showProgressBar]="showProgressBar"
    [pauseOnHover]="pauseOnHover"
    [rtl]="rtl"
    [animate]="animate"
    [position]="i">
  </notification>
</div>
