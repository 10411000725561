import { fromEvent as observableFromEvent, Observable, Subscription } from 'rxjs';

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { debounceTime } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { LocalUser } from '../../../core/models';
import * as fromRoot from '../../../core/reducers';
import { AuthSelectors } from '../../../core/selectors/auth.selectors';

import { AppTitleService } from '../../../core/services/app-title.service';

import World from '../../animations/bubbles/world';
import { routerTransition } from '../../animations/wrapper.animations';

/**
 * Not found component
 *
 * Component for handling 404 - not found error
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    animations: [routerTransition],
    encapsulation: ViewEncapsulation.None,
})
export class NotFoundComponent implements OnInit, OnDestroy {
    localUser$: Observable<LocalUser>;
    windowResizeSubscription: Subscription;
    localUserSubscription: Subscription;

    animateReady: string = '0';

    bubbleCanvas: any;
    bubblesWorld: any;

    private themesClasses: any = {
        healthcare: 'medxsync-theme',
    };

    /**
     * Component constructor function
     */
    constructor(
        private store: Store<fromRoot.State>,
        private titleService: AppTitleService,
        private authSelectors: AuthSelectors,
    ) {
        this.localUser$ = store.select(authSelectors.getLocalUser());
    }

    /**
     * Animate container and set the title.
     */
    ngOnInit(): void {
        this.titleService.set({
            pageName: 'Page not found',
        });
        this.localUserSubscription = this.localUser$.subscribe((localUser) => {
            const type = localUser.data.company.subtype;
            if (type && this.themesClasses[type]) {
                document.body.classList.add(this.themesClasses[type]);
            }
        });

        this.animateReady = '1';

        this.initBubbles();
    }

    initBubbles() {
        this.bubbleCanvas = document.createElement('canvas');
        this.bubbleCanvas.setAttribute('id', 'bubbles');
        this.bubbleCanvas.setAttribute('class', 'bubbles');
        document.body.appendChild(this.bubbleCanvas);

        const $ = this.bubbleCanvas.getContext('2d');
        let w = (this.bubbleCanvas.width = window.innerWidth);
        let h = (this.bubbleCanvas.height = window.innerHeight);

        this.bubblesWorld = new World(
            {
                width: this.bubbleCanvas.width,
                height: this.bubbleCanvas.height,
                friction: 0.997,
            },
            $,
            'transparent',
        );

        this.bubblesWorld.animate();

        this.windowResizeSubscription = observableFromEvent(window, 'resize')
            .pipe(debounceTime(250))
            .subscribe((event) => {
                w = this.bubbleCanvas.width = window.innerWidth;
                h = this.bubbleCanvas.height = window.innerHeight;
            });
    }

    /**
     * Remove defined classes from document body
     */
    removeBodyClasses(): void {
        Object.entries(this.themesClasses).forEach(([, className]) => {
            document.body.classList.remove(className as string);
        });
    }

    ngOnDestroy() {
        this.windowResizeSubscription.unsubscribe();
        this.localUserSubscription.unsubscribe();
        this.removeBodyClasses();

        document.body.removeChild(this.bubbleCanvas);
        this.bubblesWorld.destroy();
    }
}
