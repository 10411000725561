import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WorkflowHistoryLogsFilteringParams } from '@core/models';
import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class WorkflowHistoryLogsService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get workflow history logs list
     * @param params Types object with params to filter list
     * @returns Observable with backend API response
     */
    getWorkflowHistoryLogs(params: WorkflowHistoryLogsFilteringParams = {}): Observable<object> {
        return this.httpClient.get<any>(PROTECTED_URLS.WORKFLOW_HISTORY_LOGS, { params });
    }

    /**
     * Get product workflow ids
     * @param params Types object with params to filter list
     * @returns Observable with backend API response
     */
    getProductWorkflowIds(params: WorkflowHistoryLogsFilteringParams = {}): Observable<object> {
        return this.httpClient.get<any>(PROTECTED_URLS.WORKFLOW_HISTORY_LOGS_PRODUCT_WORKFLOW_IDS, { params });
    }
}
