import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class UserGroupsService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get user groups list
     * @param params Object with list params
     * @returns Backend API response
     */
    getUserGroups(params?: any): Observable<any> {
        return this.httpClient.get<any>(PROTECTED_URLS.USER_GROUPS, { params });
    }

    /**
     * Get user group details
     * @param id User group ID
     * @returns Backend API response
     */
    getUserGroup(id: number): Observable<any> {
        return this.httpClient.get<any>(`${PROTECTED_URLS.USER_GROUPS}${id}/`);
    }

    /**
     * Save user group
     * @param userGroup Object with user group ID and user group data needed to save user group
     * @returns Backend API response
     */
    saveUserGroup(userGroup: any): Observable<any> {
        return this.httpClient.patch<any>(`${PROTECTED_URLS.USER_GROUPS}${userGroup.id}/`, userGroup.data);
    }

    /**
     * Create user group
     * @param userGroup Object with user group data needed to create user group
     * @returns Backend API response
     */
    createUserGroup(userGroup: any): Observable<any> {
        return this.httpClient.post<any>(PROTECTED_URLS.USER_GROUPS, userGroup.data);
    }

    /**
     * Delete user group
     * @param id Group ID
     * @returns Backend API response
     */
    deleteUserGroup(id: number): Observable<any> {
        return this.httpClient.delete<any>(`${PROTECTED_URLS.USER_GROUPS}${id}/`);
    }

    /**
     * Get options groups request
     * @returns Backend API response
     */
    getOptions(): Observable<any> {
        return this.httpClient.options<any>(PROTECTED_URLS.USER_GROUPS);
    }
}
