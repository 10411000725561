import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class MetricsService {
    constructor(private fetchService: HttpClient) {}

    /**
     * Get metrics list
     * @param params Object with query params.
     * @returns Backend API response
     */
    getMetrics(params: any = {}): Observable<Response> {
        return this.fetchService.get<any>(PROTECTED_URLS.METRIC_FAMILIES, { params });
    }

    /**
     * Get metric
     * @param id metric metric ID
     * @returns Backend API response
     */
    getMetric(id: number): Observable<any> {
        return this.fetchService.get<any>(`${PROTECTED_URLS.METRIC_FAMILIES}${id}/`);
    }

    /**
     * Save metric
     * @param metric Object with metric ID and metric data needed to save metric
     * @returns Backend API response
     */
    saveMetric(metric: any): Observable<any> {
        return this.fetchService.put<any>(`${PROTECTED_URLS.METRIC_FAMILIES}${metric.id}/`, { ...metric.data });
    }

    /**
     * Create metric
     * @param metric Object with metric ID and metric data needed to save metric
     * @returns Backend API response
     */
    createMetric(metric: any): Observable<any> {
        return this.fetchService.post<any>(PROTECTED_URLS.METRIC_FAMILIES, { ...metric.data });
    }

    /**
     * Delete metric
     * @param id Metric ID
     * @returns Backend API response
     */
    deleteMetric(id: number): Observable<Response> {
        return this.fetchService.delete<any>(`${PROTECTED_URLS.METRIC_FAMILIES}${id}/`);
    }
}
