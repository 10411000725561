import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    CompanyId,
    DefaultOptionResponse,
    GtinId,
    ProductId,
} from '@core/models';

@Injectable()
export class ListedProductsService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get listed products
     * @param params Object with list params
     * @returns Backend API response
     */
    getListedProducts(params: any = {}): Observable<any> {
        return this.httpClient.post<any>('listed_products/post_list/', params);
    }

    /**
     * Get listed product
     * @param product
     * @returns Backend API response
     */
    getListedProduct(product: any): Observable<any> {
        return this.httpClient.get<any>(`listed_products/${product.id}/`, { params: product.params });
    }

    /**
     * Get options listed product request
     * @returns Backend API response
     */
    getProductOptions(id: ProductId): Observable<any> {
        return this.httpClient
            .options<DefaultOptionResponse>(`listed_products/${id}/`)
            .pipe(map((response) => response.actions.PUT));
    }

    /**
     * Get listed product changes request
     * @returns Backend API response
     */
    getListedProductChanges(id: ProductId, params: any = {}): Observable<any> {
        return this.httpClient.get<any>(`listed_products/${id}/history/`, { params });
    }

    /**
     * Get listed product changes request
     * @returns Backend API response
     */
    getListedProductShares(id: string): Observable<any> {
        return this.httpClient.get<any>(`listed_products/${id}/sharing_history/`);
    }

    /**
     * Get listed product summary
     * @returns Backend API response
     */
    getListedProductSummary(id: string): Observable<any> {
        return this.httpClient.get<any>(`listed_products/${id}/summary/`);
    }

    /**
     * Get internal data product preview
     * @returns Backend API response
     */
    getInternalDataPreview(params: { company: CompanyId; gtin: GtinId }): Observable<any> {
        return this.httpClient.get<any>(`internal_data_preview/`, { params });
    }
}
