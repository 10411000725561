import { getGlobalObject, getGlobalSingleton } from './global.js';

// TODO: Implement different loggers for different environments
var global = getGlobalObject();

/** Prefix for logging strings */
var PREFIX = 'Sentry Logger ';
var CONSOLE_LEVELS = ['debug', 'info', 'warn', 'error', 'log', 'assert', 'trace'];

/**
 * Temporarily disable sentry console instrumentations.
 *
 * @param callback The function to run against the original `console` messages
 * @returns The results of the callback
 */
function consoleSandbox(callback) {
  var global = getGlobalObject();
  if (!('console' in global)) {
    return callback();
  }
  var originalConsole = global.console;
  var wrappedLevels = {};

  // Restore all wrapped console methods
  CONSOLE_LEVELS.forEach(level => {
    // TODO(v7): Remove this check as it's only needed for Node 6
    var originalWrappedFunc = originalConsole[level] && originalConsole[level].__sentry_original__;
    if (level in global.console && originalWrappedFunc) {
      wrappedLevels[level] = originalConsole[level];
      originalConsole[level] = originalWrappedFunc;
    }
  });
  try {
    return callback();
  } finally {
    // Revert restoration to wrapped state
    Object.keys(wrappedLevels).forEach(level => {
      originalConsole[level] = wrappedLevels[level];
    });
  }
}
function makeLogger() {
  let enabled = false;
  var logger = {
    enable: () => {
      enabled = true;
    },
    disable: () => {
      enabled = false;
    }
  };
  if (typeof __SENTRY_DEBUG__ === 'undefined' || __SENTRY_DEBUG__) {
    CONSOLE_LEVELS.forEach(name => {
      logger[name] = (...args) => {
        if (enabled) {
          consoleSandbox(() => {
            global.console[name](`${PREFIX}[${name}]:`, ...args);
          });
        }
      };
    });
  } else {
    CONSOLE_LEVELS.forEach(name => {
      logger[name] = () => undefined;
    });
  }
  return logger;
}

// Ensure we only have a single logger instance, even if multiple versions of @sentry/utils are being used
let logger;
if (typeof __SENTRY_DEBUG__ === 'undefined' || __SENTRY_DEBUG__) {
  logger = getGlobalSingleton('logger', makeLogger);
} else {
  logger = makeLogger();
}
export { CONSOLE_LEVELS, consoleSandbox, logger };
