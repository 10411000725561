import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { Notification } from '../../../core/models';
import { NotificationsService } from '../../../core/services/notifications.service';

@Component({
    selector: 'notification',
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('enterLeave', [
            // Enter from right
            state('fromRight', style({ opacity: 1, transform: 'translateX(0)' })),
            transition('* => fromRight', [
                style({ opacity: 0, transform: 'translateX(5%)' }),
                animate('400ms ease-in-out'),
            ]),
            state('fromRightOut', style({ opacity: 0, transform: 'translateX(-5%)' })),
            transition('fromRight => fromRightOut', [
                style({ opacity: 1, transform: 'translateX(0)' }),
                animate('300ms ease-in-out'),
            ]),

            // Enter from left
            state('fromLeft', style({ opacity: 1, transform: 'translateX(0)' })),
            transition('* => fromLeft', [
                style({ opacity: 0, transform: 'translateX(-5%)' }),
                animate('400ms ease-in-out'),
            ]),
            state('fromLeftOut', style({ opacity: 0, transform: 'translateX(5%)' })),
            transition('fromLeft => fromLeftOut', [
                style({ opacity: 1, transform: 'translateX(0)' }),
                animate('300ms ease-in-out'),
            ]),

            // Rotate
            state('scale', style({ opacity: 1, transform: 'scale(1)' })),
            transition('* => scale', [style({ opacity: 0, transform: 'scale(0)' }), animate('400ms ease-in-out')]),
            state('scaleOut', style({ opacity: 0, transform: 'scale(0)' })),
            transition('scale => scaleOut', [
                style({ opacity: 1, transform: 'scale(1)' }),
                animate('400ms ease-in-out'),
            ]),

            // Scale
            state('rotate', style({ opacity: 1, transform: 'rotate(0deg)' })),
            transition('* => rotate', [style({ opacity: 0, transform: 'rotate(5deg)' }), animate('400ms ease-in-out')]),
            state('rotateOut', style({ opacity: 0, transform: 'rotate(-5deg)' })),
            transition('rotate => rotateOut', [
                style({ opacity: 1, transform: 'rotate(0deg)' }),
                animate('400ms ease-in-out'),
            ]),
        ]),
    ],
    templateUrl: 'notification.component.html',
    styleUrls: ['notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
    @Input() public timeOut: number;
    @Input() public showProgressBar: boolean;
    @Input() public pauseOnHover: boolean;
    @Input() public clickToClose: boolean;
    @Input() public rtl: boolean;
    @Input() public animate: string;
    @Input() public position: number;
    @Input() public item: Notification;

    // Progress bar variables
    public progressWidth: number = 0;
    private stopTime: boolean = false;
    private timer: any;
    private steps: number;
    private speed: number;
    private count: number = 0;
    private start: any;
    private diff: any;

    /**
     * Component constructor function
     */
    constructor(private notificationService: NotificationsService, private zone: NgZone) {}

    /**
     * Initialize component
     */
    ngOnInit(): void {
        if (this.animate) {
            this.item.state = this.animate;
        }

        if (this.item.override) {
            this.attachOverrides();
        }

        if (this.timeOut !== 0) {
            this.startTimeOut();
        }
    }

    /**
     * Calculate necessary parameters and start timer
     */
    startTimeOut(): void {
        this.steps = this.timeOut / 10;
        this.speed = this.timeOut / this.steps;
        this.start = new Date().getTime();
        this.zone.runOutsideAngular(() => (this.timer = setTimeout(this.instance, this.speed)));
    }

    /**
     * Stop timer if pause on hover is set to true
     */
    onEnter(): void {
        if (this.pauseOnHover) {
            this.stopTime = true;
        }
    }

    /**
     * Restart timer if pauseOnHover is set to true
     */
    onLeave(): void {
        if (this.pauseOnHover) {
            this.stopTime = false;
            setTimeout(this.instance, this.speed - this.diff);
        }
    }

    /**
     * Remove notification on click if clickToClose is set to true
     */
    onClick($e: MouseEvent): void {
        this.item.click!.emit($e);

        if (this.clickToClose) {
            this.remove();
        }
    }

    /**
     * Attach all the overrides
     */
    attachOverrides(): void {
        Object.keys(this.item.override).forEach((a) => {
            if (this.hasOwnProperty(a)) {
                (this as any)[a] = this.item.override[a];
            }
        });
    }

    /**
     * Clear timer on notification destroy
     */
    ngOnDestroy(): void {
        clearTimeout(this.timer);
    }

    /**
     * Keep notification instance for runOutsideAngular purposes
     */
    private instance = () => {
        this.zone.runOutsideAngular(() => {
            this.zone.run(() => (this.diff = new Date().getTime() - this.start - this.count * this.speed));

            if (this.count++ === this.steps) {
                // If time is up, remove notification
                this.zone.run(() => this.remove());
            } else if (!this.stopTime) {
                // Else, update progressbar and timer
                if (this.showProgressBar) {
                    this.zone.run(() => (this.progressWidth += 100 / this.steps));
                }
                this.timer = setTimeout(this.instance, this.speed - this.diff);
            }
        });
    };

    /**
     * Remove notification
     */
    private remove() {
        if (this.animate) {
            this.item.state = this.animate + 'Out';
            this.zone.runOutsideAngular(() => {
                setTimeout(() => {
                    this.zone.run(() => this.notificationService.set(this.item, false));
                }, 310);
            });
        } else {
            this.notificationService.set(this.item, false);
        }
    }
}
