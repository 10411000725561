import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY as empty, from, Observable, of } from 'rxjs';

import * as companies from '../actions/companies.actions';

import { CompaniesService } from '../services/companies.service';
import { ModalService } from '../services/modal.service';
import { NotificationsService } from '../services/notifications.service';
import { SectionGroupService } from '../services/section-group.service';

import { HTTPClientVer } from '@core/utils/request.utils';
import { catchErrorJson } from './catch-error';

@Injectable()
export class CompaniesEffects {
    constructor(
        private router: Router,
        private actions$: Actions,
        private companiesService: CompaniesService,
        private sectionGroupService: SectionGroupService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
    ) {}

    
    getCompaniesRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companies.ActionTypes.GET_COMPANIES_REQUEST),
        map((action: companies.GetCompaniesRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companiesService.getCompanies(payload).pipe(
                map((response) => new companies.GetCompaniesSuccessAction(response)),
                catchError((error) => of(new companies.GetCompaniesErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    ));

    
    getCompanyRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companies.ActionTypes.GET_COMPANY_REQUEST),
        map((action: companies.GetCompanyRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companiesService.getCompany(payload).pipe(
                map((response) => new companies.GetCompanySuccessAction(response)),
                catchError((error) => of(new companies.GetCompanyErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    ));

    
    createCompanyRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companies.ActionTypes.CREATE_COMPANY_REQUEST),
        map((action: companies.CreateCompanyRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companiesService.createCompany(payload).pipe(
                tap((response) => this.notificationsService.success(`Created company ${(response as any).name}.`)),
                tap(() => {
                    if (payload.changeSectionGroup && !payload.data.logo) {
                        this.sectionGroupService.selectSection(
                            payload.changeSectionGroup.id,
                            payload.changeSectionGroup.index,
                        );
                    }
                }),
                mergeMap(response => {
                    const actions = [new companies.CreateCompanySuccessAction(response)] as Action[];

                    if (payload.triggerCompanyLogoUpload && payload.data.logo) {
                        actions.push(
                            new companies.UploadCompanyLogoRequestAction({ ...payload, id: (response as any).id }),
                        );
                    }
                    return from(actions);
                }),
                catchError((error) => of(new companies.CreateCompanyErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    ));

    
    saveCompanyRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companies.ActionTypes.SAVE_COMPANY_REQUEST),
        map((action: companies.SaveCompanyRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companiesService.saveCompany(payload).pipe(
                tap((response) => this.notificationsService.success(`Saved company ${(response as any).name}.`)),
                tap(() => (payload.redirectTo ? this.router.navigate([payload.redirectTo]) : null)),
                tap(() => {
                    if (payload.changeSectionGroup && !payload.data.logo) {
                        this.sectionGroupService.selectSection(
                            payload.changeSectionGroup.id,
                            payload.changeSectionGroup.index,
                        );
                    }
                }),
                mergeMap(response => {
                    const actions = [new companies.SaveCompanySuccessAction(response)] as Action[];
                    if (payload.triggerCompanyLogoUpload && payload.data.logo) {
                        actions.push(new companies.UploadCompanyLogoRequestAction(payload));
                    }
                    return from(actions);
                }),
                catchError((error) => of(new companies.SaveCompanyErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    ));

    
    uploadCompanyLogoRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companies.ActionTypes.UPLOAD_COMPANY_LOGO_REQUEST),
        map((action: companies.UploadCompanyLogoRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companiesService.uploadCompanyLogo(payload).pipe(
                tap(() => {
                    if (payload.changeSectionGroup) {
                        this.sectionGroupService.selectSection(
                            payload.changeSectionGroup.id,
                            payload.changeSectionGroup.index,
                        );
                    }
                }),
                map((response) => new companies.UploadCompanyLogoSuccessAction(response)),
                catchError((error) =>
                    of(new companies.UploadCompanyLogoErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    deleteCompanyRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companies.ActionTypes.DELETE_COMPANY_REQUEST),
        map((action: companies.DeleteCompanyRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companiesService.deleteCompany(payload.id).pipe(
                tap(
                    () => this.router.navigate([payload.redirectTo]),
                    () => this.modalService.close('delete-company-modal'),
                ),
                tap(() => this.notificationsService.success(`Deleted company`)),
                map(() => new companies.DeleteCompanySuccessAction()),
                catchError((error) => of(new companies.DeleteCompanyErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    ));

    
    getOptionsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companies.ActionTypes.GET_OPTIONS_REQUEST),
        switchMap(() => {
            return this.companiesService.getOptions().pipe(
                map((response) => new companies.GetOptionsSuccessAction((response as any).actions.POST)),
                catchError(() => empty),
            );
        }),
    ));
}
