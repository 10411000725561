import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IngredientsListData } from '@core/models';
import { Observable } from 'rxjs';

import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class IngredientsService {
    constructor(private fetchService: HttpClient) {}

    /**
     * Get ingredients list
     * @param params Object with list params
     * @returns Backend API response
     */
    getIngredients(params: any): Observable<IngredientsListData> {
        return this.fetchService.get<any>(PROTECTED_URLS.INGREDIENTS, { params });
    }
}
