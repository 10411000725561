import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export class CompanyPaidPlansService {
    constructor(private httpClient: HttpClient) {}

    private baseUrl: string = 'company_plans';

    /**
     * Get company paid plans list
     * @param params Object with list params
     * @returns Backend API response
     */
    getCompanyPaidPlans(params: any = {}): Observable<any> {
        return this.httpClient.get<any>(`${this.baseUrl}/`, { params });
    }

    /**
     * Get specific company paid plan
     * @param id Desired company paid plan's id
     * @returns Backend API response
     */
    getCompanyPaidPlan(id: number): Observable<object> {
        return this.httpClient.get<any>(`${this.baseUrl}/${id}/`);
    }

    /**
     * Create company paid plan
     * @param paidPlan Object with company paid plan data needed to create company paid plan
     * @returns Backend API response
     */
    createCompanyPaidPlan(paidPlan: any): Observable<object> {
        return this.httpClient.post<any>(`${this.baseUrl}/`, { ...paidPlan.data });
    }

    /**
     * Update existing company paid plan
     * @param paidPlan Object with company paid plan id and company paid plan data to be saved
     * @returns Backend API response
     */
    saveCompanyPaidPlan(paidPlan: any): Observable<object> {
        return this.httpClient.put<any>(`${this.baseUrl}/${paidPlan.id}/`, { ...paidPlan.data });
    }

    /**
     * Delete company paid plan
     * @param id CompanyPaidPlan ID
     * @returns Backend API response
     */
    deleteCompanyPaidPlan(id: number): Observable<object> {
        return this.httpClient.delete<any>(`${this.baseUrl}/${id}/`);
    }

    /**
     * Get company paid plans options
     * @returns Backend API response
     */
    getOptions(): Observable<object> {
        return this.httpClient.options<any>(`${this.baseUrl}/`);
    }
}
