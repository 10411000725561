import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class PublicApiService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get products list
     * @param params Object with params
     * @returns Backend API response
     */

    request(params?: any): Observable<Object> {
        const { method, url, options, key } = params;

        return this.httpClient.request<any>(method, url, {
            params: { ...options.payload, Authorization: key },
            observe: 'response',
        });
    }
}
