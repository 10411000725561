import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, delay, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { EMPTY as empty, from, Observable, of } from 'rxjs';

import * as attributes from '../actions/attributes.actions';

import { AttributesService } from '../services/attributes.service';
import { ModalService } from '../services/modal.service';
import { NotificationsService } from '../services/notifications.service';

import { HTTPClientVer } from '@core/utils/request.utils';
import { catchErrorJson } from './catch-error';

@Injectable()
export class AttributesEffects {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private actions$: Actions,
        private attributesService: AttributesService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
    ) {}

    importCeleryId: number;

    
    getAttributesRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(attributes.ActionTypes.GET_ATTRIBUTES_REQUEST),
        map((action: attributes.GetAttributesRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.attributesService.getAttributes(payload).pipe(
                map((response) => new attributes.GetAttributesSuccessAction(response)),
                catchError((error) =>
                    of(new attributes.GetAttributesErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getAttributeRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(attributes.ActionTypes.GET_ATTRIBUTE_REQUEST),
        map((action: attributes.GetAttributeRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.attributesService.getAttribute(payload).pipe(
                map((response) => new attributes.GetAttributeSuccessAction(response)),
                catchError((error) => of(new attributes.GetAttributeErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    ));

    
    saveAttributeRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(attributes.ActionTypes.SAVE_ATTRIBUTE_REQUEST),
        map((action: attributes.SaveAttributeRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.attributesService.saveAttribute(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Saved attribute ${response.translations.en.name}`);
                    this.router.navigate(['/attributes']);
                }),
                mergeMap((response) =>
                    from([
                        new attributes.SaveAttributeSuccessAction(response),
                        ...payload.images.map(
                            (image) =>
                                new attributes.SaveAttributeCertificateImageRequestAction({
                                    attribute: response,
                                    image,
                                }),
                        ),
                    ]),
                ),
                catchError((error) =>
                    of(new attributes.SaveAttributeErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    saveAttributeCertificateImage$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(attributes.ActionTypes.SAVE_ATTRIBUTE_CERTIFICATE_IMAGE_REQUEST),
        map((action: attributes.SaveAttributeCertificateImageRequestAction) => action.payload),
        mergeMap((payload: any) => {
            const certificate = payload.attribute.certificates.find((cert) => cert.position === payload.image.position);
            return this.attributesService
                .uploadAttributeCertificatesImage({ ...certificate, data: payload.image.data })
                .pipe(
                    tap((response) => {
                        this.notificationsService.success(`Saved certificate/feature attribute image`);
                    }),
                    map(
                        (response) =>
                            new attributes.SaveAttributeCertificateImageSuccessAction({
                                image: response.image,
                                position: payload.image.position,
                            }),
                    ),
                    catchError((error) =>
                        of(
                            new attributes.SaveAttributeCertificateImageErrorAction(
                                catchErrorJson(error, HTTPClientVer),
                            ),
                        ),
                    ),
                );
        }),
    ));

    
    createAttributeRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(attributes.ActionTypes.CREATE_ATTRIBUTE_REQUEST),
        map((action: attributes.CreateAttributeRequestAction) => action.payload),
        switchMap((payload) => {
            return this.attributesService.createAttribute(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Created attribute ${response.translations.en.name}`);
                    this.router.navigate(['/attributes']);
                }),
                mergeMap((response) =>
                    from([
                        new attributes.CreateAttributeSuccessAction(response),
                        ...payload.images.map(
                            (image) =>
                                new attributes.SaveAttributeCertificateImageRequestAction({
                                    attribute: response,
                                    image,
                                }),
                        ),
                    ]),
                ),
                catchError((error) =>
                    of(new attributes.CreateAttributeErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    deleteAttributeRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(attributes.ActionTypes.DELETE_ATTRIBUTE_REQUEST),
        map((action: attributes.DeleteAttributeRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.attributesService.deleteAttribute(payload.id).pipe(
                tap(
                    (response) => {
                        this.notificationsService.success(`Deleted attribute`);
                        this.router.navigate([payload.redirectTo]);
                    },
                    (error) => {
                        this.modalService.close('delete-attribute-modal');
                    },
                ),
                map(() => new attributes.DeleteAttributeSuccessAction()),
                catchError((error) =>
                    of(new attributes.DeleteAttributeErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    deleteAttributesRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(attributes.ActionTypes.DELETE_ATTRIBUTES_REQUEST),
        map((action: attributes.DeleteAttributesRequestAction) => action.payload),
        switchMap((payload: any) => {
            const params = this.activatedRoute.snapshot.queryParams;
            return this.attributesService.deleteAttributes(payload.ids).pipe(
                tap(
                    () => {
                        this.notificationsService.success(`Deleted attributes`);
                        this.modalService.close('hard-delete-attributes-modal');
                    },
                    () => {
                        this.modalService.close('hard-delete-attributes-modal');
                    },
                ),
                mergeMap(() =>
                    from([
                        new attributes.DeleteAttributesSuccessAction(),
                        new attributes.GetAttributesRequestAction(params),
                    ]),
                ),
                catchError((error) =>
                    of(new attributes.DeleteAttributesErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getImportTemplate$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(attributes.ActionTypes.GET_IMPORT_TEMPLATE_REQUEST),
        map((action: attributes.GetImportTemplateRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.attributesService.getImportTemplate(payload).pipe(
                tap((response) => (window.location.href = response.template)),
                map((response) => new attributes.GetImportTemplateSuccessAction(response)),
                catchError((error) =>
                    of(new attributes.GetImportTemplateErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    prepareImportRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(attributes.ActionTypes.PREPARE_IMPORT_REQUEST),
        map((action: attributes.PrepareImportRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.attributesService.prepareImport(payload).pipe(
                tap((response) => {
                    this.importCeleryId = response.id;
                    this.modalService.open('import-attributes-modal');
                }),
                mergeMap((response) =>
                    from([
                        new attributes.PrepareImportSuccessAction(response),
                        new attributes.GetImportStatusRequestAction(response.id),
                    ]),
                ),
                catchError((error) =>
                    of(new attributes.PrepareImportErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    importStatusRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(attributes.ActionTypes.GET_IMPORT_STATUS_REQUEST),
        map((action: attributes.GetImportStatusRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.attributesService.getImportStatus(payload).pipe(
                tap(
                    (response) => {
                        if (response.status === 'SUCCESS') {
                            this.modalService.close('import-attributes-modal');
                            this.notificationsService.success(`Import success.`);
                        } else if (response.status === 'FAILURE') {
                            this.modalService.close('import-attributes-modal');
                            this.notificationsService.error('Attributes import fail. Please try again later.');
                        }
                    },
                    (error) => {
                        this.modalService.close('import-attributes-modal');
                    },
                ),
                map((response) => new attributes.GetImportStatusSuccessAction(response)),
                catchError((error) =>
                    of(new attributes.GetImportStatusErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    importStatusSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(attributes.ActionTypes.GET_IMPORT_STATUS_SUCCESS),
        map((action: attributes.GetImportStatusSuccessAction) => action.payload),
        delay(1000),
        filter(() => this.modalService.isOpened('import-attributes-modal')),
        filter((payload) => payload.status === 'PENDING' || payload.status === 'IN_PROGRESS'),
        map((payload) => {
            return new attributes.GetImportStatusRequestAction(this.importCeleryId);
        }),
    ));

    
    getOptionsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(attributes.ActionTypes.GET_OPTIONS_REQUEST),
        switchMap((payload) => {
            return this.attributesService.getOptions().pipe(
                map((response) => new attributes.GetOptionsSuccessAction(response.actions.POST)),
                catchError(() => empty),
            );
        }),
    ));
}
