import { Action } from '@ngrx/store';

export interface ActionWithPayload extends Action {
    payload?: any;
}

export const ActionTypes = {
    ANY_ERRORS: 'ANY_ERRORS',
};

/**
 * For wrong implements Empty actions found in source code
 */
export class AnyErrors implements ActionWithPayload {
    type = ActionTypes.ANY_ERRORS;

    constructor(public payload?: any) {}
}
