import { getCurrentHub } from '@sentry/core';
import { addInstrumentationHandler, htmlTreeAsString, severityLevelFromString, safeJoin, getGlobalObject, parseUrl } from '@sentry/utils';

/** JSDoc */

var BREADCRUMB_INTEGRATION_ID = 'Breadcrumbs';

/**
 * Default Breadcrumbs instrumentations
 * TODO: Deprecated - with v6, this will be renamed to `Instrument`
 */
class Breadcrumbs {
  /**
   * @inheritDoc
   */
  static __initStatic() {
    this.id = BREADCRUMB_INTEGRATION_ID;
  }

  /**
   * @inheritDoc
   */
  __init() {
    this.name = Breadcrumbs.id;
  }

  /**
   * Options of the breadcrumbs integration.
   */
  // This field is public, because we use it in the browser client to check if the `sentry` option is enabled.

  /**
   * @inheritDoc
   */
  constructor(options) {
    ;
    Breadcrumbs.prototype.__init.call(this);
    this.options = {
      console: true,
      dom: true,
      fetch: true,
      history: true,
      sentry: true,
      xhr: true,
      ...options
    };
  }

  /**
   * Instrument browser built-ins w/ breadcrumb capturing
   *  - Console API
   *  - DOM API (click/typing)
   *  - XMLHttpRequest API
   *  - Fetch API
   *  - History API
   */
  setupOnce() {
    if (this.options.console) {
      addInstrumentationHandler('console', _consoleBreadcrumb);
    }
    if (this.options.dom) {
      addInstrumentationHandler('dom', _domBreadcrumb(this.options.dom));
    }
    if (this.options.xhr) {
      addInstrumentationHandler('xhr', _xhrBreadcrumb);
    }
    if (this.options.fetch) {
      addInstrumentationHandler('fetch', _fetchBreadcrumb);
    }
    if (this.options.history) {
      addInstrumentationHandler('history', _historyBreadcrumb);
    }
  }
}
Breadcrumbs.__initStatic();

/**
 * A HOC that creaes a function that creates breadcrumbs from DOM API calls.
 * This is a HOC so that we get access to dom options in the closure.
 */
function _domBreadcrumb(dom) {
  function _innerDomBreadcrumb(handlerData) {
    let target;
    let keyAttrs = typeof dom === 'object' ? dom.serializeAttribute : undefined;
    if (typeof keyAttrs === 'string') {
      keyAttrs = [keyAttrs];
    }

    // Accessing event.target can throw (see getsentry/raven-js#838, #768)
    try {
      target = handlerData.event.target ? htmlTreeAsString(handlerData.event.target, keyAttrs) : htmlTreeAsString(handlerData.event, keyAttrs);
    } catch (e) {
      target = '<unknown>';
    }
    if (target.length === 0) {
      return;
    }
    getCurrentHub().addBreadcrumb({
      category: `ui.${handlerData.name}`,
      message: target
    }, {
      event: handlerData.event,
      name: handlerData.name,
      global: handlerData.global
    });
  }
  return _innerDomBreadcrumb;
}

/**
 * Creates breadcrumbs from console API calls
 */
function _consoleBreadcrumb(handlerData) {
  var breadcrumb = {
    category: 'console',
    data: {
      arguments: handlerData.args,
      logger: 'console'
    },
    level: severityLevelFromString(handlerData.level),
    message: safeJoin(handlerData.args, ' ')
  };
  if (handlerData.level === 'assert') {
    if (handlerData.args[0] === false) {
      breadcrumb.message = `Assertion failed: ${safeJoin(handlerData.args.slice(1), ' ') || 'console.assert'}`;
      breadcrumb.data.arguments = handlerData.args.slice(1);
    } else {
      // Don't capture a breadcrumb for passed assertions
      return;
    }
  }
  getCurrentHub().addBreadcrumb(breadcrumb, {
    input: handlerData.args,
    level: handlerData.level
  });
}

/**
 * Creates breadcrumbs from XHR API calls
 */
function _xhrBreadcrumb(handlerData) {
  if (handlerData.endTimestamp) {
    // We only capture complete, non-sentry requests
    if (handlerData.xhr.__sentry_own_request__) {
      return;
    }
    const {
      method,
      url,
      status_code,
      body
    } = handlerData.xhr.__sentry_xhr__ || {};
    getCurrentHub().addBreadcrumb({
      category: 'xhr',
      data: {
        method,
        url,
        status_code
      },
      type: 'http'
    }, {
      xhr: handlerData.xhr,
      input: body
    });
    return;
  }
}

/**
 * Creates breadcrumbs from fetch API calls
 */
function _fetchBreadcrumb(handlerData) {
  // We only capture complete fetch requests
  if (!handlerData.endTimestamp) {
    return;
  }
  if (handlerData.fetchData.url.match(/sentry_key/) && handlerData.fetchData.method === 'POST') {
    // We will not create breadcrumbs for fetch requests that contain `sentry_key` (internal sentry requests)
    return;
  }
  if (handlerData.error) {
    getCurrentHub().addBreadcrumb({
      category: 'fetch',
      data: handlerData.fetchData,
      level: 'error',
      type: 'http'
    }, {
      data: handlerData.error,
      input: handlerData.args
    });
  } else {
    getCurrentHub().addBreadcrumb({
      category: 'fetch',
      data: {
        ...handlerData.fetchData,
        status_code: handlerData.response.status
      },
      type: 'http'
    }, {
      input: handlerData.args,
      response: handlerData.response
    });
  }
}

/**
 * Creates breadcrumbs from history API calls
 */
function _historyBreadcrumb(handlerData) {
  var global = getGlobalObject();
  let from = handlerData.from;
  let to = handlerData.to;
  var parsedLoc = parseUrl(global.location.href);
  let parsedFrom = parseUrl(from);
  var parsedTo = parseUrl(to);

  // Initial pushState doesn't provide `from` information
  if (!parsedFrom.path) {
    parsedFrom = parsedLoc;
  }

  // Use only the path component of the URL if the URL matches the current
  // document (almost all the time when using pushState)
  if (parsedLoc.protocol === parsedTo.protocol && parsedLoc.host === parsedTo.host) {
    to = parsedTo.relative;
  }
  if (parsedLoc.protocol === parsedFrom.protocol && parsedLoc.host === parsedFrom.host) {
    from = parsedFrom.relative;
  }
  getCurrentHub().addBreadcrumb({
    category: 'navigation',
    data: {
      from,
      to
    }
  });
}
export { BREADCRUMB_INTEGRATION_ID, Breadcrumbs };
