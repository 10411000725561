import { Observable, throwError as observableThrowError, EMPTY as EmptyRequest } from 'rxjs';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

import { getErrorMessage, unpackErrorMessage } from '@core/utils/request.utils';
import { environment } from '@env/environment';

import { isInvalidCredentialError } from '@core/effects/catch-error';
import { NotificationsService } from '@core/services/notifications.service';
import { AuthHttpClientService } from './auth.service';
import * as Sentry from '@sentry/browser';

import { Store } from '@ngrx/store';
import { PUBLIC_URLS } from '../../../urls.conf';
import * as authActions from '../../core/actions/auth.actions';

const PUBLIC_MODE = 'public_mode';
const STANDARD_MODE = 'standard_mode';
const endpointsWithoutToken = [...Object.values(PUBLIC_URLS)];

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private static baseApiUrl = '/api/v1/';
    private static basePublicApiUrl = '/api/public/v1/';
    private mode: 'public_mode' | 'standard_mode';

    constructor(
        private authService: AuthHttpClientService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private notificationsService: NotificationsService,
        private store: Store,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.params.get('Authorization') === null) {
            if (endpointsWithoutToken.indexOf(request.url) < 0 && !this.authService.token) {
                console.warn(`Request to ${request.url} omitted`);
                return EmptyRequest;
            }
            this.mode = STANDARD_MODE;
        } else {
            this.mode = PUBLIC_MODE;
        }

        const token = this.mode === PUBLIC_MODE ? request.params.get('Authorization') : this.authService.token;

        if (this.mode === PUBLIC_MODE) {
            request.params.delete('Authorization');
        }

        const updatedKeys = {
            url: TokenInterceptor.parseUrl(request.url, this.mode),
            setHeaders: {
                'json-key-case': 'camelCase',
            },
        };

        if (endpointsWithoutToken.indexOf(request.url) < 0 && request.params.get('Authorization') !== '') {
            Object.assign(updatedKeys.setHeaders, {
                Authorization: `token ${token}`,
            });
        }

        return next.handle(request.clone(updatedKeys)).pipe(catchError((error) => this.handleError(error, request)));
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    private static parseUrl(url, mode) {
        if (url[0] === '/' && !environment.production) {
            console.error(`Check called endpoint ${url} bc it starts with /`);
        }

        const apiUrl = mode === PUBLIC_MODE ? this.basePublicApiUrl : this.baseApiUrl;

        return `${apiUrl}${url}`;
    }

    /**
     * Handle situation when backend API returns error
     * @param httpError
     */
    private handleError(httpError: HttpErrorResponse, request: HttpRequest<any>) {
        let message: string;
        let preventLastDuplicates: string | boolean = 'visible';

        if (!isInvalidCredentialError(httpError) && this.mode === STANDARD_MODE) {
            switch (httpError.status) {
                case 401: {
                    this.authService.deleteToken();

                    if (window.location.search.includes('SAMLRequest')) {
                        this.router.navigateByUrl(PUBLIC_URLS.LOGIN + window.location.search);
                    } else {
                        this.router.navigate([PUBLIC_URLS.LOGIN]);
                        message = 'Your session has expired or your session token is invalid.';
                        this.store.dispatch(new authActions.SignOutAction());
                    }

                    break;
                }

                case 403: {
                    if (typeof httpError.error.detail === 'string') {
                        message = httpError.error.detail;
                    } else {
                        message = 'You do not have sufficient permissions to access this page.';
                    }
                    break;
                }

                case 404: {
                    this.router.navigate([PUBLIC_URLS.NOT_FOUND], { replaceUrl: true });
                    message = 'The requested page was not found.';
                    break;
                }

                default:
                    if (httpError.error.nonFieldErrors) {
                        message = getErrorMessage(httpError);
                    } else if (httpError.error.constructor.name === 'ArrayBuffer') {
                        const decodedString = String.fromCharCode.apply(null, new Uint8Array(httpError.error));
                        const obj = JSON.parse(decodedString);
                        message = unpackErrorMessage(obj);
                    } else if (typeof httpError.error === 'object') {
                        message = unpackErrorMessage(httpError.error);
                    } else {
                        message = 'Something went wrong.';
                        Sentry.configureScope((scope) => {
                            scope.setExtra('request', request);
                        });
                        Sentry.captureMessage(httpError.message);
                    }
                    preventLastDuplicates = false;
            }

            if (!environment.production) {
                console.error('Logged error in TokenInterceptor');
                console.error(httpError);
            }

            this.notificationsService.error(message, '', preventLastDuplicates);
        }

        return observableThrowError(httpError);
    }
}
