import { Injectable } from '@angular/core';

/**
 * This is dirty functionality that is moved from other components/controllers.
 * At the moment we have to adapt to the existing solution,
 * to avoid rebuilding the entire application's styles.
 */
@Injectable()
export class ThemeService {
    private themesClasses: any = {
        healthcare: 'medxsync-theme',
        sharjah: 'municipality-theme',
        fta: 'fta-theme',
    };

    setTheme(companySubtype: string): void {
        const themeName = this.themesClasses[companySubtype];
        this.removeThemeClasses();

        if (themeName) {
            document.body.classList.add(themeName);
        }
    }

    removeThemeClasses() {
        Object.entries(this.themesClasses).forEach(([key, className]: [string, any]) => {
            document.body.classList.remove(className);
        });
    }
}
