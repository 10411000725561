import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class ImportHistoryLogsService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get import history logs list
     * @param params Types object with params to filter list
     * @returns Observable with backend< API response
     */
    getImportHistoryLogs(params: any = {}): Observable<any> {
        return this.httpClient.get<any>(PROTECTED_URLS.IMPORT_HISTORY_LOGS_PRODUCT, { params });
    }

    /**
     * Get import history log data
     * @param logId Types number with log ID
     * @returns Observable with backend API response
     */
    getImportHistoryLog(logId: number): Observable<any> {
        return this.httpClient.get<any>(`${PROTECTED_URLS.IMPORT_HISTORY_LOGS_PRODUCT}${logId}/`);
    }
}
