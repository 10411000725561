import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyId } from '@app/core/models';
import { Observable } from 'rxjs';

import { UploadService } from '@core/services/upload.service';
import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class CompanyInternalDataService {
    constructor(private httpClient: HttpClient, private uploadService: UploadService) {}

    /**
     * Get company internal data import template
     * @param companyId Requested company ID
     * @returns Backend API response
     */
    getCompanyInternalTemplate(companyId: CompanyId): Observable<Object> {
        return this.httpClient.get<any>(
            `${PROTECTED_URLS.COMPANIES}${companyId}/${PROTECTED_URLS.COMPANY_INTERNAL_DATA_AUTOPOPULATION_XLS}`,
        );
    }

    /**
     * Upload internal data import files
     * @param payload FormData with company, internalDataFile, internalMediasFile fields
     * @returns Backend API response
     */
    uploadInternalDataTemplate(payload: FormData): Observable<any> {
        return this.uploadService.postUpload(`internal_data_template_import/`, payload);
    }
}
