import * as fromRouter from '@ngrx/router-store';

import * as fromAttributeGroups from './attribute-groups.reducer';
import * as fromAttributes from './attributes.reducer';
import * as fromAuth from './auth.reducer';
import * as fromBrands from './brands.reducer';
import * as fromCompanies from './companies.reducer';
import * as fromCompanyDataRequirements from './company-data-requirements.reducer';
import * as fromCompanyDomains from './company-domains.reducer';
import * as fromCompanyInternalData from './company-internal-data.reducer';
import * as fromCompanyPaidPlans from './company-paid-plans.reducer';
import * as fromCompanySharingPresets from './company-sharing-presets.reducer';
import * as fromCompanyWhitelistedIps from './company-whitelisted-ips.reducer';
import * as fromExportHistoryLogs from './export-history-logs.reducer';
import * as fromImageConfigurations from './image-configurations.reducer';
import * as fromImagePlatform from './image-platform.reducer';
import * as fromImportHistoryLogs from './import-history-logs.reducer';
import * as fromIngredientGroups from './ingredient-groups.reducer';
import * as fromIngredients from './ingredients.reducer';
import * as listedProducts from './listed-products.reducer';
import * as fromMediaProfiles from './media-profiles.reducer';
import * as fromMetrics from './metrics.reducer';
import * as fromNews from './news.reducer';
import * as fromNutrients from './nutrients.reducer';
import * as fromOnboarding from './onboarding.reducer';
import * as fromPaidPlans from './paid-plans.reducer';
import * as fromPartners from './partners.reducer';
import * as fromPartnerships from './partnerships.reducer';
import * as fromProductCategories from './product-categories.reducer';
import * as fromProductChangeRequests from './product-change-requests.reducer';
import * as fromProductReviews from './product-reviews.reducer';
import * as fromProductTemplates from './product-templates.reducer';
import * as fromProducts from './products.reducer';
import * as fromPublicApi from './public-api.reducer';
import * as fromRefresh from './refresh.reducer';
import * as fromUserGroups from './user-groups.reducer';
import * as fromUserLogs from './user-logs.reducer';
import * as fromUsers from './users.reducer';
import * as fromUtils from './utils.reducer';
import * as fromWorkflowHistoryLogs from './workflow-history-logs.reducer';
import * as fromWorkflows from './workflows.reducer';

export interface State {
    attributeGroups: fromAttributeGroups.State;
    attributes: fromAttributes.State;
    auth: fromAuth.State;
    brands: fromBrands.State;
    companies: fromCompanies.State;
    companyDataRequirements: fromCompanyDataRequirements.State;
    companyDomains: fromCompanyDomains.State;
    companyInteralData: fromCompanyInternalData.State;
    companyPaidPlans: fromCompanyPaidPlans.State;
    companySharingPresets: fromCompanySharingPresets.State;
    companyWhitelistedIps: fromCompanyWhitelistedIps.State;
    exportHistoryLogs: fromExportHistoryLogs.State;
    imageConfigurations: fromImageConfigurations.State;
    imagePlatform: fromImagePlatform.State;
    importHistoryLogs: fromImportHistoryLogs.State;
    ingredientGroups: fromIngredientGroups.State;
    ingredients: fromIngredients.State;
    listedProducts: listedProducts.State;
    mediaProfiles: fromMediaProfiles.State;
    metrics: fromMetrics.State;
    news: fromNews.State;
    nutrients: fromNutrients.State;
    onboarding: fromOnboarding.State;
    paidPlans: fromNutrients.State;
    partners: fromPartners.State;
    partnerships: fromPartnerships.State;
    productCategories: fromProductCategories.State;
    productChangeRequests: fromProductChangeRequests.State;
    productReviews: fromProductReviews.State;
    products: fromProducts.State;
    productTemplates: fromProductTemplates.State;
    publicApi: any;
    refresh: fromRefresh.State;
    routerState: fromRouter.RouterReducerState;
    userGroups: fromUserGroups.State;
    userLogs: fromUserLogs.State;
    users: fromUsers.State;
    utils: fromUtils.State;
    workflowHistoryLogs: fromWorkflowHistoryLogs.State;
    workflows: fromWorkflows.State;
}

export const reducers = {
    attributeGroups: fromAttributeGroups.reducer,
    attributes: fromAttributes.reducer,
    auth: fromAuth.reducer,
    brands: fromBrands.reducer,
    companies: fromCompanies.reducer,
    companyDataRequirements: fromCompanyDataRequirements.reducer,
    companyDomains: fromCompanyDomains.reducer,
    companyInteralData: fromCompanyInternalData.reducer,
    companyPaidPlans: fromCompanyPaidPlans.reducer,
    companySharingPresets: fromCompanySharingPresets.reducer,
    companyWhitelistedIps: fromCompanyWhitelistedIps.reducer,
    exportHistoryLogs: fromExportHistoryLogs.reducer,
    imageConfigurations: fromImageConfigurations.reducer,
    imagePlatform: fromImagePlatform.reducer,
    importHistoryLogs: fromImportHistoryLogs.reducer,
    ingredientGroups: fromIngredientGroups.reducer,
    ingredients: fromIngredients.reducer,
    listedProducts: listedProducts.reducer,
    mediaProfiles: fromMediaProfiles.reducer,
    metrics: fromMetrics.reducer,
    news: fromNews.reducer,
    nutrients: fromNutrients.reducer,
    onboarding: fromOnboarding.reducer,
    paidPlans: fromPaidPlans.reducer,
    partners: fromPartners.reducer,
    partnerships: fromPartnerships.reducer,
    productCategories: fromProductCategories.reducer,
    productChangeRequests: fromProductChangeRequests.reducer,
    productReviews: fromProductReviews.reducer,
    products: fromProducts.reducer,
    productTemplates: fromProductTemplates.reducer,
    publicApi: fromPublicApi.reducer,
    refresh: fromRefresh.reducer,
    routerState: fromRouter.routerReducer,
    userGroups: fromUserGroups.reducer,
    userLogs: fromUserLogs.reducer,
    users: fromUsers.reducer,
    utils: fromUtils.reducer,
    workflowHistoryLogs: fromWorkflowHistoryLogs.reducer,
    workflows: fromWorkflows.reducer,
};
