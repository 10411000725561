import { Injectable } from '@angular/core';
import { HTTPClientVer } from '@core/utils/request.utils';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import * as userLogs from '../actions/user-logs.actions';

import { UserLogsService } from '../services/user-logs.service';

import { catchErrorJson } from './catch-error';

@Injectable()
export class UserLogsEffects {
    constructor(private actions$: Actions, private userLogsService: UserLogsService) {}

    
    getLogsActionsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(userLogs.ActionTypes.GET_USER_LOGS_REQUEST),
        map((action: userLogs.GetUserLogsRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.userLogsService.getLogsActions(payload).pipe(
                map((response) => new userLogs.GetUserLogsSuccessAction(response)),
                catchError((error) => of(new userLogs.GetUserLogsErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    ));
}
