import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class ExportHistoryLogsService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get export history logs list
     * @param params Types object with params to filter list
     * @returns Observable with backend API response
     */
    getExportHistoryLogs(params: any = {}): Observable<object> {
        return this.httpClient.get(PROTECTED_URLS.EXPORT_HISTORY_LOGS_PRODUCT, {
            params,
        });
    }

    /**
     * Get image platform export history log data
     * @param logId Types number with log ID
     * @returns Observable with backend API response
     */
    getImagePlatformExportHistoryLog(logId: number): Observable<object> {
        return this.httpClient.get<any>(`${PROTECTED_URLS.EXPORT_HISTORY_LOGS_IMAGE_PLATFORM}${logId}/`);
    }

    /**
     * Get product data export history log data
     * @param logId Types number with log ID
     * @returns Observable with backend API response
     */
    getProductDataExportHistoryLog(logId: number): Observable<object> {
        return this.httpClient.get<any>(`${PROTECTED_URLS.EXPORT_HISTORY_LOGS_PRODUCT_DATA}${logId}/`);
    }

    /**
     * Get product sharing history export history log data
     * @param logId Types number with log ID
     * @returns Observable with backend API response
     */
    getProductSharingHistoryExportHistoryLog(logId: number): Observable<object> {
        return this.httpClient.get<any>(`${PROTECTED_URLS.EXPORT_HISTORY_LOGS_PRODUCT_SHARING_HISTORY}${logId}/`);
    }

    /**
     * Get product media export history log data
     * @param logId Types number with log ID
     * @returns Observable with backend API response
     */
    getProductMediaExportHistoryLog(logId: number): Observable<object> {
        return this.httpClient.get<any>(`${PROTECTED_URLS.EXPORT_HISTORY_LOGS_PRODUCT_MEDIA}${logId}/`);
    }
}
