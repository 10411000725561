import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppTitleService } from '../../../core/services/app-title.service';
import { SectionGroupService } from '../../../core/services/section-group.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'common',
    styleUrls: ['./common.component.scss'],
    templateUrl: './common.component.html',
})
export class CommonComponent implements OnInit {
    form: UntypedFormGroup;

    multiple0: Boolean = false;
    multiple1: Boolean = true;
    options: any[] = [];
    selection: string[];

    activeStep: number = 0;

    constructor(private sectionGroupService: SectionGroupService, private titleService: AppTitleService) {
        this.options = [
            {
                value: 'poznan',
                displayName: 'Poznań',
            },
            {
                value: 'wroclaw',
                displayName: 'Wrocław',
            },
            {
                value: 'lomza',
                displayName: 'Łomża',
            },
            {
                value: 'cracow',
                displayName: 'Kraków',
            },
            {
                value: 'warsaw',
                displayName: 'Warsaw',
            },
        ];
    }

    ngOnInit() {
        this.titleService.set({
            pageName: 'Common page',
        });
        this.form = new UntypedFormGroup({});
        this.form.addControl('selectSingle', new UntypedFormControl(''));
        this.form.addControl('selectMultiple', new UntypedFormControl(''));
    }

    onSingleOpened() {
        this.logSingle('- opened');
    }

    onSingleClosed() {
        this.logSingle('- closed');
    }

    onSingleSelected(item) {
        this.logSingle('- selected (value: ' + item.value + ', label:' + item.label + ')');
    }

    onSingleDeselected(item) {
        this.logSingle('- deselected (value: ' + item.value + ', label:' + item.label + ')');
    }

    private logSingle(value) {
        // console.log(value);
    }

    goToSection(sectionId, sectionIndex) {
        this.sectionGroupService.selectSection(sectionId, sectionIndex);
    }

    editorContent = 'WYSIWYG Editor';
}
