import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProductTemplatesService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get product templates list
     * @param params Object with list params
     * @returns Backend API response
     */
    getProductTemplates(params: any = {}): Observable<any> {
        return this.httpClient.get<any>('product_templates/', { params });
    }

    /**
     * Get product template
     * @param productTemplateId product template ID
     * @returns Backend API response
     */
    getProductTemplate(productTemplateId: number): Observable<any> {
        return this.httpClient.get<any>(`product_templates/${productTemplateId}/`);
    }

    /**
     * Save product template
     * @param productTemplate Object with product template ID and product template data needed to save product template
     * @returns Backend API response
     */
    saveProductTemplate(productTemplate: any): Observable<any> {
        return this.httpClient.put<any>(`product_templates/${productTemplate.id}/`, productTemplate.data);
    }

    /**
     * Create product template
     * @param productTemplate Object with product template data needed to create product template
     * @returns Backend API response
     */
    createProductTemplate(productTemplate: any): Observable<any> {
        return this.httpClient.post<any>('product_templates/', productTemplate.data);
    }

    /**
     * Delete product template
     * @param id Product template ID
     * @returns Backend API response
     */
    deleteProductTemplate(id: number): Observable<any> {
        return this.httpClient.delete<any>(`product_templates/${id}/`);
    }
}
