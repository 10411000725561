import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class ImageConfigurationsService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get image configurations list
     * @param params Object with list params
     * @returns Backend API response
     */
    public getImageConfigurations(params: any = {}): Observable<object> {
        return this.httpClient.get<any>(PROTECTED_URLS.IMAGE_CONFIGURATIONS, { params });
    }

    /**
     * Create image configuration
     * @param imageConfiguration Object with image configuration data needed to create image configuration
     * @returns Backend API response
     */
    public createImageConfiguration(imageConfiguration: any): Observable<any> {
        return this.httpClient.post<any>(PROTECTED_URLS.IMAGE_CONFIGURATIONS, imageConfiguration.data);
    }

    /**
     * Delete image configuration
     * @param id Image configuration ID
     * @returns Backend API response
     */
    public deleteImageConfiguration(id: number): Observable<object> {
        return this.httpClient.delete<any>(`${PROTECTED_URLS.IMAGE_CONFIGURATIONS}${id}/`);
    }

    /**
     * Get image configurations options
     * @returns Backend API response
     */
    public getOptions(): Observable<any> {
        return this.httpClient.options<any>(PROTECTED_URLS.IMAGE_CONFIGURATIONS);
    }
}
