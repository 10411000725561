import { _nullishCoalesce } from '@sentry/utils/esm/buildPolyfills';
import { generateUniqueID } from './generateUniqueID.js';
var initMetric = (name, value) => {
  return {
    name,
    value: _nullishCoalesce(value, () => -1),
    delta: 0,
    entries: [],
    id: generateUniqueID()
  };
};
export { initMetric };
