import { CommonModule, NgClass } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';

import { reducers } from './reducers';

import { TokenInterceptor } from '@core/auth_httpclient/auth.interceptors';

import { AppTitleService } from './services/app-title.service';
import { AttributeGroupsService } from './services/attribute-groups.service';
import { AttributesService } from './services/attributes.service';
import { AuthService } from './services/auth.service';
import { BrandService } from './services/brands.service';
import { CompaniesService } from './services/companies.service';
import { CompanyDataRequirementsService } from './services/company-data-requirements.service';
import { CompanyDomainsService } from './services/company-domains.service';
import { CompanyInternalDataService } from './services/company-internal-data.service';
import { CompanyPaidPlansService } from './services/company-paid-plans.service';
import { CompanySharingPresetsService } from './services/company-sharing-presets.service';
import { CompanyWhitelistedIpsService } from './services/company-whitelisted-ips.service';
import { CustomValidatorsService } from './services/custom-validators.service';
import { ExportHistoryLogsService } from './services/export-history-logs.service';
import { ImageConfigurationsService } from './services/image-configurations.service';
import { ImagePlatformService } from './services/image-platform.service';
import { ImportHistoryLogsService } from './services/import-history-logs.service';
import { IngredientGroupsService } from './services/ingredient-groups.service';
import { IngredientsService } from './services/ingredients.service';
import { InjectionService } from './services/injection.service';
import { ListedProductsService } from './services/listed-products.service';
import { MediaProfilesService } from './services/media-profiles.service';
import { MetricsService } from './services/metrics.service';
import { ModalService } from './services/modal.service';
import { NewsService } from './services/news.service';
import { NotificationsService } from './services/notifications.service';
import { NutrientsService } from './services/nutrients.service';
import { OnboardingService } from './services/onboarding.service';
import { PaidPlansService } from './services/paid-plans.service';
import { PartnersService } from './services/partners.service';
import { PartnershipsService } from './services/partnerships.service';
import { ProductCategoriesService } from './services/product-categories.service';
import { ProductReviewsService } from './services/product-reviews.service';
import { ProductTemplatesService } from './services/product-templates.service';
import { ProductsService } from './services/products.service';
import { PublicApiService } from './services/public-api.service';
import { RoutingHistoryService } from './services/routing-history.service';
import { SectionGroupService } from './services/section-group.service';
import { ThemeService } from './services/theme.service';
import { UserGroupsService } from './services/user-groups.service';
import { UserLogsService } from './services/user-logs.service';
import { UsersService } from './services/users.service';
import { UtilsService } from './services/utils.service';
import { WorkflowHistoryLogsService } from './services/workflow-history-logs.service';
import { WorkflowsService } from './services/workflows.service';
import { MaintenanceService } from './services/maintenance.service';

import { CommonEffects } from '@core/effects/common.effects';
import { AttributeGroupsEffects } from './effects/attribute-groups.effects';
import { AttributesEffects } from './effects/attributes.effects';
import { AuthEffects } from './effects/auth.effects';
import { BrandsEffects } from './effects/brands.effects';
import { CompaniesEffects } from './effects/companies.effects';
import { CompanyDataRequirementsEffects } from './effects/company-data-requirements.effects';
import { CompanyDomainsEffects } from './effects/company-domains.effects';
import { CompanyInternalDataEffects } from './effects/company-internal-data.effects';
import { CompanyPaidPlansEffects } from './effects/company-paid-plans.effects';
import { CompanySharingPresetsEffects } from './effects/company-sharing-presets.effects';
import { CompanyWhitelistedIpsEffects } from './effects/company-whitelisted-ips.effects';
import { ExportHistoryLogsEffects } from './effects/export-history-logs.effects';
import { ImageConfigurationsEffects } from './effects/image-configurations.effects';
import { ImagePlatformEffects } from './effects/image-platform.effects';
import { ImportHistoryLogsEffects } from './effects/import-history-logs.effects';
import { IngredientGroupsEffects } from './effects/ingredient-groups.effects';
import { IngredientsEffects } from './effects/ingredients.effects';
import { ListedProductsEffects } from './effects/listed-products.effects';
import { MediaProfilesEffects } from './effects/media-profiles.effects';
import { MetricsEffects } from './effects/metrics.effects';
import { NewsEffects } from './effects/news.effects';
import { NutrientsEffects } from './effects/nutrients.effects';
import { OnboardingEffects } from './effects/onboarding.effects';
import { PaidPlansEffects } from './effects/paid-plans.effects';
import { PartnersEffects } from './effects/partners.effects';
import { PartnershipsEffects } from './effects/partnerships.effects';
import { ProductCategoriesEffects } from './effects/product-categories.effects';
import { ProductReviewsEffects } from './effects/product-reviews.effects';
import { ProductTemplateEffects } from './effects/product-templates.effects';
import { ProductsEffects } from './effects/products.effects';
import { PublicApiEffects } from './effects/public-api.effects';
import { RefreshEffects } from './effects/refresh.effects';
import { UserGroupsEffects } from './effects/user-groups.effects';
import { UserLogsEffects } from './effects/user-logs.effects';
import { UsersEffects } from './effects/users.effects';
import { UtilsEffects } from './effects/utils.effects';
import { WorkflowHistoryLogsEffects } from './effects/workflow-history-logs.effects';
import { WorkflowsEffects } from './effects/workflows.effects';

import { AttributeGroupsManageGuard, AttributeGroupsReadGuard } from './guards/attribute-groups.guards';
import { AttributesManageGuard, AttributesReadGuard } from './guards/attributes.guards';
import { IsAuthenticatedGuard } from './guards/auth.guards';
import { CompaniesManageGuard, CompaniesManageOwnGuard, CompaniesReadOwnGuard } from './guards/companies.guards';
import { CompanyDomainsManageGuard, CompanyDomainsReadGuard } from './guards/company-domains.guards';
import { ImagePlatformAccessGuard } from './guards/image-platform.guards';
import { IngredientGroupsManageGuard, IngredientGroupsReadGuard } from './guards/ingredient-groups.guards';
import { IsStaffGuard } from './guards/is-staff.guards';
import { MetricsManageGuard, MetricsReadGuard } from './guards/metrics.guards';
import { NewsManageGuard } from './guards/news.guards';
import { NutrientsManageGuard, NutrientsReadGuard } from './guards/nutrients.guards';
import { PaidPlansManageGuard, PaidPlansReadGuard } from './guards/paid-plans.guards';
import { ProductCategoriesManageGuard, ProductCategoriesReadGuard } from './guards/product-categories.guards';
import { ProductTemplatesManageGuard, ProductTemplatesReadGuard } from './guards/product-templates.guards';
import {
    NotProductsAutomateAnnotateGuard,
    ProductsAnnotateGuard,
    ProductsExportGuard,
    ProductsImportGuard,
    ProductsManageGuard,
    ProductsManageOwnGuard,
    ProductsReadOwnGuard,
} from './guards/products.guards';
import { PublicApiAccessGuard } from './guards/public-api.guards';
import { UnsavedChangesGuard } from './guards/unsaved-changes.guards';
import { UserGroupsManageOwnGuard, UserGroupsReadOwnGuard } from './guards/user-groups.guards';
import { UserLogsReadGuard } from './guards/user-logs.guards';
import { UsersManageGuard, UsersManageOwnGuard, UsersReadOwnGuard } from './guards/users.guards';
import { WorkflowHistoryLogsGuard } from './guards/workflow-history-logs.guards';
import {
    CanDisplayMyListingsGuard,
    WorkflowsManageGuard,
} from './guards/workflows.guards';
import { MaintenanceGuard } from './guards/maintenance.guards';

import { UploadService } from '@core/services/upload.service';
import { AttributeGroupsSelectors } from './selectors/attribute-groups.selectors';
import { AttributesSelectors } from './selectors/attributes.selectors';
import { AuthSelectors } from './selectors/auth.selectors';
import { BrandsSelectors } from './selectors/brands.selectors';
import { CompaniesSelectors } from './selectors/companies.selectors';
import { CompanyDataRequirementsSelectors } from './selectors/company-data-requirements.selectors';
import { CompanyDomainsSelectors } from './selectors/company-domains.selectors';
import { CompanyInternalDataSelectors } from './selectors/company-internal-data.selectors';
import { CompanyPaidPlansSelectors } from './selectors/company-paid-plans.selectors';
import { CompanySharingPresetsSelectors } from './selectors/company-sharing-presets.selectors';
import { CompanyWhitelistedIpsSelectors } from './selectors/company-whitelisted-ips.selectors';
import { ExportHistoryLogsSelectors } from './selectors/export-history-logs.selectors';
import { ImageConfigurationsSelectors } from './selectors/image-configurations.selectors';
import { ImagePlatformSelectors } from './selectors/image-platform.selectors';
import { ImportHistoryLogsSelectors } from './selectors/import-history-logs.selectors';
import { IngredientGroupsSelectors } from './selectors/ingredient-groups.selectors';
import { IngredientsSelectors } from './selectors/ingredients.selectors';
import { ListedProductsSelectors } from './selectors/listed-products.selectors';
import { MediaProfilesSelectors } from './selectors/media-profiles.selectors';
import { MetricsSelectors } from './selectors/metrics.selectors';
import { NewsSelectors } from './selectors/news.selectors';
import { NutrientsSelectors } from './selectors/nutrients.selectors';
import { OnboardingSelectors } from './selectors/onboarding.selectors';
import { PaidPlansSelectors } from './selectors/paid-plans.selectors';
import { PartnersSelectors } from './selectors/partners.selectors';
import { PartnershipsSelectors } from './selectors/partnerships.selectors';
import { ProductCategoriesSelectors } from './selectors/product-categories.selectors';
import { ProductChangeRequestsSelectors } from './selectors/product-change-requests.selectors';
import { ProductReviewsSelectors } from './selectors/product-reviews.selectors';
import { ProductTemplatesSelectors } from './selectors/product-templates.selectors';
import { ProductsSelectors } from './selectors/products.selectors';
import { PublicApiSelectors } from './selectors/public-api.selectors';
import { RefreshSelectors } from './selectors/refresh.selectors';
import { RouterSelectors } from './selectors/router.selectors';
import { StateSelectors } from './selectors/state.selectors';
import { UserGroupsSelectors } from './selectors/user-groups.selectors';
import { UserLogsSelectors } from './selectors/user-logs.selectors';
import { UsersSelectors } from './selectors/users.selectors';
import { UtilsSelectors } from './selectors/utils.selectors';
import { WorkflowHistoryLogsSelectors } from './selectors/workflow-history-logs.selectors';
import { WorkflowsSelectors } from './selectors/workflows.selectors';

import { AuthHttpClientService } from '@core/auth_httpclient/auth.service';
import { AuxiliaryService } from '@core/services/auxiliary.service';
import { PublicApiV2CategoryDetailsService } from '@core/services/public-api-v2-category-details.service';
import { PublicApiV2CategorySchemaService } from '@core/services/public-api-v2-category-schema.service';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NotificationComponent } from './components/notifications/notification.component';
import { NotificationsContainerComponent } from './components/notifications/notifications-container.component';

import { environment } from '@env/environment';

const imports = [
    CommonModule,
    NgClass,
    RouterModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {
        runtimeChecks: {
            strictStateImmutability: false,
            strictActionImmutability: false
        },
    }),
    StoreDevtoolsModule.instrument({
        maxAge: 40,
        logOnly: environment.production,
        connectInZone: true,
    }),
    EffectsModule.forRoot([
        AttributeGroupsEffects,
        AttributesEffects,
        AuthEffects,
        BrandsEffects,
        CommonEffects,
        CompaniesEffects,
        CompanyDataRequirementsEffects,
        CompanyDomainsEffects,
        CompanyInternalDataEffects,
        CompanyPaidPlansEffects,
        CompanySharingPresetsEffects,
        CompanyWhitelistedIpsEffects,
        ExportHistoryLogsEffects,
        ImageConfigurationsEffects,
        ImagePlatformEffects,
        ImportHistoryLogsEffects,
        IngredientGroupsEffects,
        IngredientsEffects,
        ListedProductsEffects,
        MediaProfilesEffects,
        MetricsEffects,
        NewsEffects,
        NutrientsEffects,
        OnboardingEffects,
        PaidPlansEffects,
        PartnersEffects,
        PartnershipsEffects,
        ProductCategoriesEffects,
        ProductReviewsEffects,
        ProductsEffects,
        ProductTemplateEffects,
        PublicApiEffects,
        RefreshEffects,
        UserGroupsEffects,
        UserLogsEffects,
        UsersEffects,
        UtilsEffects,
        WorkflowHistoryLogsEffects,
        WorkflowsEffects,
    ]),
    NgxsModule.forRoot([], { developmentMode: !environment.production}),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
];

@NgModule({
    imports,
    declarations: [NotFoundComponent, NotificationComponent, NotificationsContainerComponent],
    exports: [NotificationComponent, NotificationsContainerComponent],
    providers: [
        AttributeGroupsManageGuard,
        AttributeGroupsReadGuard,
        AttributesManageGuard,
        AttributesReadGuard,
        CanDisplayMyListingsGuard,
        CompaniesManageGuard,
        CompaniesManageOwnGuard,
        CompaniesReadOwnGuard,
        CompanyDomainsManageGuard,
        CompanyDomainsReadGuard,
        ImagePlatformAccessGuard,
        IngredientGroupsManageGuard,
        IngredientGroupsReadGuard,
        IsAuthenticatedGuard,
        IsStaffGuard,
        MetricsManageGuard,
        MetricsReadGuard,
        NewsManageGuard,
        NotProductsAutomateAnnotateGuard,
        NutrientsManageGuard,
        NutrientsReadGuard,
        PaidPlansManageGuard,
        PaidPlansReadGuard,
        ProductCategoriesManageGuard,
        ProductCategoriesReadGuard,
        ProductsAnnotateGuard,
        ProductsExportGuard,
        ProductsImportGuard,
        ProductsManageGuard,
        ProductsManageOwnGuard,
        ProductsReadOwnGuard,
        ProductTemplatesManageGuard,
        ProductTemplatesReadGuard,
        PublicApiAccessGuard,
        UnsavedChangesGuard,
        UserGroupsManageOwnGuard,
        UserGroupsReadOwnGuard,
        UserLogsReadGuard,
        UsersManageGuard,
        UsersManageOwnGuard,
        UsersReadOwnGuard,
        WorkflowHistoryLogsGuard,
        WorkflowsManageGuard,
        MaintenanceGuard,

        AppTitleService,
        AttributeGroupsService,
        AttributesService,
        AuthService,
        AuxiliaryService,
        BrandService,
        CompaniesService,
        CompanyDataRequirementsService,
        CompanyDomainsService,
        CompanyInternalDataService,
        CompanyPaidPlansService,
        CompanySharingPresetsService,
        CompanyWhitelistedIpsService,
        CustomValidatorsService,
        ExportHistoryLogsService,
        ImageConfigurationsService,
        ImagePlatformService,
        ImportHistoryLogsService,
        IngredientGroupsService,
        IngredientsService,
        InjectionService,
        ListedProductsService,
        MediaProfilesService,
        MetricsService,
        ModalService,
        NewsService,
        NotificationsService,
        NutrientsService,
        OnboardingService,
        PaidPlansService,
        PartnershipsService,
        PartnersService,
        ProductCategoriesService,
        ProductReviewsService,
        ProductsService,
        ProductTemplatesService,
        PublicApiService,
        RoutingHistoryService,
        SectionGroupService,
        ThemeService,
        UploadService,
        UserGroupsService,
        UserLogsService,
        UsersService,
        UtilsService,
        WorkflowHistoryLogsService,
        WorkflowsService,
        MaintenanceService,
        PublicApiV2CategoryDetailsService,
        PublicApiV2CategorySchemaService,

        AttributeGroupsSelectors,
        AttributesSelectors,
        AuthSelectors,
        BrandsSelectors,
        CompaniesSelectors,
        CompanyDataRequirementsSelectors,
        CompanyDomainsSelectors,
        CompanyInternalDataSelectors,
        CompanyPaidPlansSelectors,
        CompanySharingPresetsSelectors,
        CompanyWhitelistedIpsSelectors,
        ExportHistoryLogsSelectors,
        ImageConfigurationsSelectors,
        ImagePlatformSelectors,
        ImportHistoryLogsSelectors,
        IngredientGroupsSelectors,
        IngredientsSelectors,
        ListedProductsSelectors,
        MediaProfilesSelectors,
        MetricsSelectors,
        NewsSelectors,
        NutrientsSelectors,
        OnboardingSelectors,
        PaidPlansSelectors,
        PartnershipsSelectors,
        PartnersSelectors,
        ProductCategoriesSelectors,
        ProductChangeRequestsSelectors,
        ProductReviewsSelectors,
        ProductsSelectors,
        ProductTemplatesSelectors,
        PublicApiSelectors,
        RouterSelectors,
        RefreshSelectors,
        StateSelectors,
        UserGroupsSelectors,
        UserLogsSelectors,
        UsersSelectors,
        UtilsSelectors,
        WorkflowHistoryLogsSelectors,
        WorkflowsSelectors,

        AuthHttpClientService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
}
