import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { MediaProfileId } from '@core/models';
import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class MediaProfilesService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Create media profile
     * @param data Object with media profile.
     * @returns Backend API response
     */
    public postMediaProfile(data: any = {}): Observable<Response> {
        return this.httpClient.post<any>(PROTECTED_URLS.MEDIA_PROFILES, data);
    }

    /**
     * Get media profile options
     * @param data Object with media profile.
     * @returns Backend API response
     */
    public getMediaProfileOptions(): Observable<any> {
        return this.httpClient.options<any>(PROTECTED_URLS.MEDIA_PROFILES);
    }

    /**
     * Get media profiles list
     * @param params Object with params
     * @returns Backend API response
     */
    public getMediaProfiles(params: any = {}): Observable<Response> {
        return this.httpClient.get<any>(PROTECTED_URLS.MEDIA_PROFILES, { params });
    }

    /**
     * Get media profile data
     * @param mediaProfileId number with media profile id.
     * @returns Backend API response
     */
    public getMediaProfile(mediaProfileId: MediaProfileId): Observable<Response> {
        return this.httpClient.get<any>(`${PROTECTED_URLS.MEDIA_PROFILES}${mediaProfileId}/`);
    }

    /**
     * Update media profile
     * @param data Object with media profile.
     * @returns Backend API response
     */
    public updateMediaProfile(data: any = {}): Observable<Response> {
        return this.httpClient.patch<any>(`${PROTECTED_URLS.MEDIA_PROFILES}${data.id}/`, data);
    }

    /**
     * Delete media profile
     * @param mediaProfileId number with media profile id.
     * @returns Backend API response
     */
    public deleteMediaProfile(mediaProfileId: any = {}): Observable<Response> {
        return this.httpClient.delete<any>(`${PROTECTED_URLS.MEDIA_PROFILES}${mediaProfileId}/`);
    }

    /**
     * Get media profile available images
     * @returns Backend API response
     * @param company
     */
    public getAvailableImages(company: number): Observable<Response> {
        return this.httpClient.post<any>(PROTECTED_URLS.PUBLISHING_CHANNELS_AVAILABLE_IMAGES, { company });
    }

    /**
     * Preview Image
     * @param data Object with media profile.
     * @returns Backend API response
     */
    public previewSampleImage(data: any = {}): Observable<Response> {
        return this.httpClient.post<any>(PROTECTED_URLS.PUBLISHING_CHANNELS_PREVIEWS, data);
    }
}
