import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class AttributesService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get attributes list
     * @param params Object with list params
     * @returns Backend API response
     */
    getAttributes(params: any = {}): Observable<object> {
        return this.httpClient.get<any>(PROTECTED_URLS.ATTRIBUTES, { params });
    }

    /**
     * Get options attributes request
     * @returns Backend API response
     */
    getOptions(): Observable<any> {
        return this.httpClient.options<any>(PROTECTED_URLS.ATTRIBUTES);
    }

    /**
     * Get attribute
     * @param id attribute ID
     * @returns Backend API response
     */
    getAttribute(id: number): Observable<object> {
        return this.httpClient.get<any>(`${PROTECTED_URLS.ATTRIBUTES}${id}/`);
    }

    /**
     * Save attribute
     * @param attribute Object with attribute ID and attribute data needed to save attribute
     * @returns Backend API response
     */
    saveAttribute(attribute: any): Observable<any> {
        return this.httpClient.put<any>(`${PROTECTED_URLS.ATTRIBUTES}${attribute.id}/`, attribute.data);
    }

    /**
     * Create attribute
     * @param attribute Object with attribute data needed to create attribute
     * @returns Backend API response
     */
    createAttribute(attribute: any): Observable<any> {
        return this.httpClient.post<any>(PROTECTED_URLS.ATTRIBUTES, attribute.data);
    }

    /**
     * Delete attribute
     * @param id Attribute ID
     * @returns Backend API response
     */
    deleteAttribute(id: number): Observable<object> {
        return this.httpClient.delete<any>(`${PROTECTED_URLS.ATTRIBUTES}${id}/`);
    }

    /**
     * Delete attributes (cannot be restored)
     * @param ids Array with attributes' IDs
     * @returns Backend API response
     */
    deleteAttributes(ids: number[]): Observable<any> {
        return this.httpClient.post<any>(PROTECTED_URLS.ATTRIBUTES_BULK_DELETE, { ids });
    }

    /**
     * Upload attribute certificates image
     * @param attributeCertificate
     * @returns Backend API response
     */
    uploadAttributeCertificatesImage(attributeCertificate: any): Observable<any> {
        const payload = new FormData();

        Object.entries(attributeCertificate.data).forEach(([name, file]: [string, any]) => {
            payload.append(name, file);
        });

        return this.httpClient.patch<any>(
            `${PROTECTED_URLS.ATTRIBUTE_CERTIFICATES}${attributeCertificate.id}/`,
            payload,
        );
    }

    /**
     * Get import template request
     * @param data Object with config data needed to get a template
     * @returns Backend API response
     */
    getImportTemplate(data: any): Observable<any> {
        return this.httpClient.get<any>(`${PROTECTED_URLS.ATTRIBUTES}${data.mode}_template/`);
    }

    /**
     * Prepare import request
     * @param data Object with import data needed to import attributes
     * @returns Backend API response
     */
    prepareImport(data: any): Observable<any> {
        return this.httpClient.patch<any>(PROTECTED_URLS.ATTRIBUTES_IMPORT, data);
    }

    /**
     * Get import status request
     * @param id Celecry task ID
     * @returns Backend API response
     */
    getImportStatus(id: number): Observable<any> {
        return this.httpClient.get<any>(`${PROTECTED_URLS.ATTRIBUTES_IMPORT_STATUS}${id}/`);
    }
}
