import { Component, OnInit } from '@angular/core';

import { environment } from '@env/environment';

import * as authActions from '@core/actions/auth.actions';
import * as fromRoot from '@core/reducers';

import { AuthHttpClientService } from '@core/auth_httpclient/auth.service';
import { AuthSelectors } from '@core/selectors/auth.selectors';
import { Store } from '@ngrx/store';

import { GITHUB_HASH } from '@app/version';

declare const ga;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    constructor(
        protected store: Store<fromRoot.State>,
        protected authSelectors: AuthSelectors,
        protected authClientService: AuthHttpClientService,
    ) {
        if (environment.production) {
            this.initializeGoogleAnalitics();
        }
    }

    ngOnInit(): void {
        if (this.authClientService.token) {
            this._getUserData();
        }
        console.log(`Application is working in version "${ GITHUB_HASH }"`);
    }

    private _getUserData(): void {
        this.store.dispatch(new authActions.GetLocalUserRequestAction());
    }

    private initializeGoogleAnalitics(): void {
        ga('create', environment.GACode, 'auto');
    }
}
