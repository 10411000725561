import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { Observable } from 'rxjs';
import { PROTECTED_URLS } from '../../../urls.conf';

import {
    BulkDataRequirementsRequest,
    DataRequirementId,
    DataRequirementsHistoryLogsPayload,
    GetDataRequirementsSummaryPayload,
} from '@core/models';

const BASE_URL: string = 'data_requirements';

@Injectable()
export class CompanyDataRequirementsService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get company available attributes list
     * @param params Object with list params
     * @returns Backend API response
     */
    getCompanyAvailableAttributes(params: any): Observable<Object> {
        return this.httpClient.post<any>(`${BASE_URL}/${PROTECTED_URLS.COMPANY_DATA_AVAILABLE_ATTRIBUTES}`, { ...params });
    }

    /**
     * Create company data requirement
     * @param params Object with data
     * @returns Backend API response
     */
    createCompanyDataRequirement(params: any): Observable<Object> {
        return this.httpClient.post<any>(`${BASE_URL}/`, params);
    }

    /**
     * Update company data requirement
     * @param params Object with data
     * @returns Backend API response
     */
    updateCompanyDataRequirement(requirementId: DataRequirementId, params: any): Observable<Object> {
        return this.httpClient.patch<any>(`${BASE_URL}/${requirementId}/`, { ...params });
    }

    /**
     * Get company data requirement
     * @param params Object with params
     * @returns Backend API response
     */
    getCompanyDataRequirements(params: any): Observable<Object> {
        return this.httpClient.get<any>(`${BASE_URL}/`, { params });
    }

    /**
     * Get company data requirement
     * @param requirementId Object with data requirement id
     * @returns Backend API response
     */
    getCompanyDataRequirement(requirementId: DataRequirementId): Observable<Object> {
        return this.httpClient.get<any>(`${BASE_URL}/${requirementId}/`);
    }

    /**
     * Delete company data requirement
     * @param requirementId Object with data requirement id
     * @returns Backend API response
     */
    deleteCompanyDataRequirement(requirementId: DataRequirementId): Observable<Object> {
        return this.httpClient.delete<any>(`${BASE_URL}/${requirementId}/`);
    }

    /**
     * Bulk update company data requirement
     * @param payload Object with data
     * @returns Backend API response
     */
    bulkUpdateCompanyDataRequirement(payload: BulkDataRequirementsRequest): Observable<Object> {
        return this.httpClient.post<any>(`${BASE_URL}/${PROTECTED_URLS.COMPANY_DATA_BULK_TOGGLE}`, { ...payload });
    }

    /**
     * Bulk delete company data requirement
     * @param payload Object with data
     * @returns Backend API response
     */
    bulkDeleteCompanyDataRequirement(payload: BulkDataRequirementsRequest): Observable<Object> {
        return this.httpClient.post<any>(`${BASE_URL}/${PROTECTED_URLS.COMPANY_DATA_BULK_DELETE}`, { ...payload });
    }

    /**
     * Get company data requirements summary
     * @param payload GetDataRequirementsSummaryPayload object contains company ID field
     * @returns Backend API response
     */
    getCompanyDataRequirementsSummary(payload: GetDataRequirementsSummaryPayload): Observable<Object> {
        return this.httpClient.post<any>(`${BASE_URL}/${PROTECTED_URLS.COMPANY_DATA_STATS}`, { ...payload });
    }

    /**
     * Get company data requirements history logs
     * @param payload HistoryLogsPayload object contains company ID, partner ID fields
     * @returns Backend API response
     */
    getHistoryLogs(payload: DataRequirementsHistoryLogsPayload): Observable<Object> {
        return this.httpClient.get<any>(PROTECTED_URLS.COMPANY_DATA_REQUIREMENT_HISTORY_LOGS, {
            params: payload,
        } as Params);
    }
}
