import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import * as paidPlans from '../actions/paid-plans.actions';

import { ModalService } from '../services/modal.service';
import { NotificationsService } from '../services/notifications.service';
import { PaidPlansService } from '../services/paid-plans.service';

import { HTTPClientVer } from '@core/utils/request.utils';
import { catchErrorJson } from './catch-error';

@Injectable()
export class PaidPlansEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private paidPlansService: PaidPlansService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
    ) {}

    
    getPaidPlansRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(paidPlans.ActionTypes.GET_PAID_PLANS_REQUEST),
        map((action: paidPlans.GetPaidPlansRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.paidPlansService.getPaidPlans(payload).pipe(
                map((response) => new paidPlans.GetPaidPlansSuccessAction(response)),
                catchError((error) => of(new paidPlans.GetPaidPlansErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    ));

    
    getOptionsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(paidPlans.ActionTypes.GET_OPTIONS_REQUEST),
        switchMap(() => {
            return this.paidPlansService
                .getOptions()
                .pipe(map((response: any) => new paidPlans.GetOptionsSuccessAction(response.actions.POST)));
        }),
    ));

    
    getPaidPlanRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(paidPlans.ActionTypes.GET_PAID_PLAN_REQUEST),
        map((action: paidPlans.GetPaidPlanRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.paidPlansService.getPaidPlan(payload).pipe(
                map((response) => new paidPlans.GetPaidPlanSuccessAction(response)),
                catchError((error) => of(new paidPlans.GetPaidPlanErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    ));

    
    savePaidPlanRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(paidPlans.ActionTypes.SAVE_PAID_PLAN_REQUEST),
        map((action: paidPlans.SavePaidPlanRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.paidPlansService.savePaidPlan(payload).pipe(
                tap((response) => this.notificationsService.success(`Saved paid plan ${(response as any).name}`)),
                tap(() => this.router.navigate(['/paid-plans'])),
                map((response) => new paidPlans.SavePaidPlanSuccessAction(response)),
                catchError((error) => of(new paidPlans.SavePaidPlanErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    ));

    
    deletePaidPlanRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(paidPlans.ActionTypes.DELETE_PAID_PLAN_REQUEST),
        map((action: paidPlans.DeletePaidPlanRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.paidPlansService.deletePaidPlan(payload.id).pipe(
                tap(
                    () => this.router.navigate([payload.redirectTo]),
                    () => this.modalService.close('delete-paid-plan-modal'),
                ),
                tap(() => this.notificationsService.success(`Deleted paid plan`)),
                map(() => new paidPlans.DeletePaidPlanSuccessAction()),
                catchError((error) =>
                    of(new paidPlans.DeletePaidPlanErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    createPaidPlanRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(paidPlans.ActionTypes.CREATE_PAID_PLAN_REQUEST),
        map((action: paidPlans.CreatePaidPlanRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.paidPlansService.createPaidPlan(payload).pipe(
                tap((response) => this.notificationsService.success(`Created paid plan ${(response as any).name}`)),
                tap(() => this.router.navigate(['/paid-plans'])),
                map((response) => new paidPlans.CreatePaidPlanSuccessAction(response)),
                catchError((error) =>
                    of(new paidPlans.CreatePaidPlanErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));
}
