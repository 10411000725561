import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';

const PATH: string = 'company_partnerships/';

@Injectable()
export class PartnershipsService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get partnerships list
     * @param params Object with list params
     * @returns Backend API response
     */
    getPartnerships(params: any = {}): Observable<Object> {
        return this.httpClient.get<any>(PATH, { params });
    }

    /**
     * Get specific partnership
     * @param id Desired partnership's id
     * @returns Backend API response
     */
    getPartnership(id: number): Observable<Object> {
        return this.httpClient.get<any>(`${PATH}${id}/`);
    }
}
