import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthHttpClientService } from '@core/auth_httpclient/auth.service';
import { ThemeService } from '@core/services/theme.service';

import { Store as NgxsStore } from '@ngxs/store';

import * as ngxsAuthActions from '../../auth/ngxs/auth.actions';
import { SignOutUaePassAction, UaePassLinkingAction, UaePassLoginAction } from '@app/auxiliary-store/auxiliary.actions';
import { getErrorFromHttpErrorResponse, getErrorMessage, HTTPClientVer } from '@core/utils/request.utils';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { PUBLIC_URLS } from '../../../urls.conf';

import * as auth from '../actions/auth.actions';

import { MonitoringService } from '@shared/services/monitorUser.service';
import { NotificationsService } from '../services/notifications.service';

import { catchErrorJson, INVALID_CREDENTIALS_BE, isInvalidCredentialError } from './catch-error';
import { UaePassLinkKeys } from '@app/auth/ngxs/auth.model';
import { environment } from '@env/environment';
import { ModalService } from '@core/services/modal.service';

@Injectable()
export class AuthEffects {
    constructor(
        private actions$: Actions,
        protected authHttpClient: AuthHttpClientService,
        private router: Router,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private monitoringService: MonitoringService,
        private ngxsStore: NgxsStore,
        private themeService: ThemeService,
        private authService: AuthHttpClientService,
        private modalService: ModalService
    ) {}

    
    signInRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(auth.ActionTypes.SIGN_IN_REQUEST),
        map((action: auth.SignInRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.authHttpClient.login(payload).pipe(
                mergeMap(response => {
                    let next = this.route.snapshot.queryParamMap.get('next');
                    const code = this.route.snapshot.queryParamMap.get('code');
                    const state = this.route.snapshot.queryParamMap.get('state');
                    const inManualLinkingProcess = JSON.parse(this.authService.popItem(UaePassLinkKeys.inManualLinkingProcess));
                    const isSamlRequest = window.location.search.includes('SAMLRequest');

                    if (inManualLinkingProcess) {
                        this.ngxsStore.dispatch(new UaePassLinkingAction(state, code));
                    }

                    if (response.profiles?.length) {
                        this.ngxsStore.dispatch(new ngxsAuthActions.InitializeProfileChange(
                            response.profiles as any,
                            next || ( !isSamlRequest ? '/' : null),
                            isSamlRequest ? window.location.search : null
                        ));
                        return from([new auth.SignInSuccessAction()]);
                    } else {
                        if (!next && payload.returnTo) {
                            next = payload.returnTo.replace(window.location.origin, '');
                        }

                        if (isSamlRequest) {
                            this.authHttpClient.authSSOifPossible();
                        } else {
                            next ? this.router.navigateByUrl(next) : this.router.navigate(['/'], {});
                        }
                        return from([new auth.SignInSuccessAction(), new auth.GetLocalUserRequestAction()]);
                    }
                }),
                catchError((error) => {
                    if (isInvalidCredentialError(error)) {
                        this.notificationsService.error(INVALID_CREDENTIALS_BE, '', true);
                    }
                    if (error.error.uaePassError && window.location.search.includes('code')) {
                        this.modalService.open('uae-pass-manual-linking-modal');
                    }
                    return of(new auth.SignInErrorAction(catchErrorJson(error, HTTPClientVer)));
                }),
            );
        }),
    ));

    
    signOutEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<auth.SignOutAction>(auth.ActionTypes.SIGN_OUT),
        map((action: auth.SignOutAction) => action.redirectToLoginPage),
        switchMap((shouldRedirect) => {
            return this.authHttpClient.logout().pipe(
                tap(() => {
                    this.ngxsStore.dispatch(new ngxsAuthActions.ClearAuthState());
                    if (JSON.parse(this.authService.popItem(UaePassLinkKeys.uaePass))) {
                        window.location.href = environment.uaePassLogoutUrl;
                    }
                    else if (shouldRedirect) {
                        this.router.navigate([PUBLIC_URLS.LOGIN]);
                    }
                }),
                catchError(() =>
                    of({
                        /**
                         * Do not remove this!!!
                         * this protect stream in case of wrong error call in some auth functionality
                         */
                    }),
                ),
            );
        }),
    ), { dispatch: false });

    
    getLocalUserRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<auth.GetLocalUserRequestAction>(auth.ActionTypes.GET_LOCAL_USER_REQUEST),
        switchMap(() => this.authHttpClient.getLocalUser().pipe(
            tap(response => this.monitoringService.monitorUser(response)),
            tap(response => this.themeService.setTheme(response.company.subtype)),
            map(response => new auth.GetLocalUserSuccessAction(response)),
            catchError(error => of(new auth.GetLocalUserErrorAction(getErrorMessage(error)))),
        )),
    ));

    
    resetPasswordRequest$: Observable<object> = createEffect(() => this.actions$.pipe(
        ofType<auth.ResetPasswordRequestAction>(auth.ActionTypes.RESET_PASSWORD_REQUEST),
        map((action: auth.ResetPasswordRequestAction) => action.email),
        switchMap((email) => this.authHttpClient.resetPassword(email).pipe(mergeMap(() => of({})))),
    ), { dispatch: false });

    
    newPasswordRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(auth.ActionTypes.NEW_PASSWORD_REQUEST),
        map((action: auth.NewPasswordRequestAction) => action.payload),
        switchMap((payload) => {
            return this.authHttpClient.newPassword(payload).pipe(
                tap(() => this.notificationsService.success('New password set. You can now login.')),
                tap(() => this.router.navigate([PUBLIC_URLS.LOGIN])),
                map(() => new auth.NewPasswordSuccessAction()),
                catchError((error) => {
                    const newPasswordMessage = getErrorFromHttpErrorResponse(error, 'newPassword');
                    if (newPasswordMessage) {
                        this.notificationsService.error(newPasswordMessage);
                    }
                    return of(new auth.NewPasswordErrorAction(catchErrorJson(error, HTTPClientVer)));
                }),
            );
        }),
    ));

    
    activateAccountRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(auth.ActionTypes.ACTIVATE_ACCOUNT_REQUEST),
        map((action: auth.ActivateAccountRequestAction) => action.payload),
        switchMap((payload) => {
            return this.authHttpClient.activateAccount(payload).pipe(
                tap(() => this.notificationsService.success(`Account successfully activated. You can now login.`)),
                tap(() => this.router.navigate([PUBLIC_URLS.LOGIN])),
                map(() => new auth.ActivateAccountSuccessAction()),
                catchError((error) => of(new auth.ActivateAccountErrorAction(catchErrorJson(error, HTTPClientVer)))),
            );
        }),
    ));
}
