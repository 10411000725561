import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PROTECTED_URLS } from '../../../urls.conf';

import { OnboardingLogId, OnboardingLogPayload } from '@core/models';

@Injectable()
export class OnboardingService {
    constructor(private httpClient: HttpClient) {}

    updateOrSave(id: OnboardingLogId, payload: OnboardingLogPayload): Observable<object> {
        return id ? this._updateTour(id, payload) : this._saveTour(payload);
    }

    /**
     * Save seen onboarding for the first time
     * @param payload Contains onboarding ID, isSkipped or isCompleted flag
     * @returns Backend API response
     */
    _saveTour(payload: OnboardingLogPayload): Observable<object> {
        return this.httpClient.post<any>(PROTECTED_URLS.ONBOARDING_USER_ONBOARDING_LOGS, payload);
    }

    /**
     * Update saved onboarding
     * @param id Contains onboarding log ID
     * @param payload Contains onboarding ID, isSkipped or isCompleted flag
     * @returns Backend API response
     */
    _updateTour(id: OnboardingLogId, payload: OnboardingLogPayload): Observable<object> {
        return this.httpClient.put<any>(`${ PROTECTED_URLS.ONBOARDING_USER_ONBOARDING_LOGS }${ id }/`, payload);
    }

}
