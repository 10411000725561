import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PROTECTED_URLS } from '../../../urls.conf';

import {
    BulkSharingPresetRequest,
    DefaultSharingPresetsPayload,
    GetPartnerRequirementsPayload,
    PresetId,
} from '@core/models';

const BASE_URL: string = 'sharing_presets';

@Injectable()
export class CompanySharingPresetsService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get company available attributes list
     * @param params Object with list params
     * @returns Backend API response
     */
    getCompanyAvailableAttributes(params: any): Observable<Object> {
        return this.httpClient.post<any>(`${BASE_URL}/${PROTECTED_URLS.COMPANY_SHARING_PRESETS_AVAILABLE_ATTRIBUTED}`, params);
    }

    /**
     * Create company sharing preset
     * @param params Object with data
     * @returns Backend API response
     */
    createCompanySharingPreset(params: any): Observable<Object> {
        return this.httpClient.post<any>(`${BASE_URL}/`, params);
    }

    /**
     * Update company sharing preset
     * @param params Object with data
     * @returns Backend API response
     */
    updateCompanySharingPreset(presetId: PresetId, params: any): Observable<Object> {
        return this.httpClient.patch<any>(`${BASE_URL}/${presetId}/`, params);
    }

    /**
     * Get company sharing presets
     * @param params Object with params
     * @returns Backend API response
     */
    getCompanySharingPresets(params: any): Observable<Object> {
        return this.httpClient.get<any>(`${BASE_URL}/`, { params });
    }

    /**
     * Get company sharing presets
     * @param presetId Object with sharing preset id
     * @returns Backend API response
     */
    getCompanySharingPreset(presetId: PresetId): Observable<Object> {
        return this.httpClient.get<any>(`${BASE_URL}/${presetId}/`);
    }

    /**
     * Delete company sharing presets
     * @param presetId Object with sharing preset id
     * @returns Backend API response
     */
    deleteCompanySharingPreset(presetId: PresetId): Observable<Object> {
        return this.httpClient.delete<any>(`${BASE_URL}/${presetId}/`);
    }

    /**
     * Bulk update company sharing presets
     * @param payload Object with data
     * @returns Backend API response
     */
    bulkUpdateCompanySharingPresets(payload: BulkSharingPresetRequest): Observable<Object> {
        return this.httpClient.post<any>(`${BASE_URL}/${PROTECTED_URLS.COMPANY_SHARING_PRESETS_BULK_TOGGLE}`, payload);
    }

    /**
     * Bulk delete company sharing presets
     * @param payload Object with data
     * @returns Backend API response
     */
    bulkDeleteCompanySharingPresets(payload: BulkSharingPresetRequest): Observable<Object> {
        return this.httpClient.post<any>(`${BASE_URL}/${PROTECTED_URLS.COMPANY_DATA_BULK_DELETE}`, payload);
    }

    /**
     * Get company sharing presets summary
     * @param payload object contains company ID and partner ID fields
     * @returns Backend API response
     */
    getCompanySharingPresetsSummary(payload: DefaultSharingPresetsPayload): Observable<Object> {
        return this.httpClient.post<any>(`${BASE_URL}/${PROTECTED_URLS.COMPANY_SHARING_PRESETS_STATS}`, payload);
    }

    /**
     * Get company partner requirements
     * @param payload GetPartnerRequirementsPayload object contains company ID, partner ID and category ID fields
     * @returns Backend API response
     */
    getCompanyPartnerRequirements(payload: GetPartnerRequirementsPayload): Observable<Object> {
        return this.httpClient.post<any>(`${BASE_URL}/${PROTECTED_URLS.COMPANY_SHARING_PRESETS_PARTNER_REQUIREMENTS}`, payload);
    }

    /**
     * Get company changes notifications
     * @param payload object contains company ID, partner ID fields
     * @returns Backend API response
     */
    getChangesNotificationsRequirements(payload: DefaultSharingPresetsPayload): Observable<Object> {
        return this.httpClient.post<any>(
            `${BASE_URL}/${PROTECTED_URLS.COMPANY_SHARING_PRESETS_GET_NOTIFICATIONS}`,
            payload,
        );
    }

    /**
     * Apply company changes notifications
     * @param payload object contains company ID, partner ID fields
     * @returns Backend API response
     */
    applyChangesNotificationsRequirements(payload: DefaultSharingPresetsPayload): Observable<Object> {
        return this.httpClient.post<any>(
            `${BASE_URL}/${PROTECTED_URLS.COMPANY_SHARING_PRESETS_CLEAR_NOTIFICATIONS}`,
            payload,
        );
    }

    /**
     * Get company partner history logs
     * @param payload HistoryLogsPayload object contains company ID, partner ID fields
     * @returns Backend API response
     */
    getHistoryLogs(payload: any): Observable<Object> {
        return this.httpClient.get<any>(PROTECTED_URLS.COMPANY_SHARING_PRESETS_HISTORY_LOGS, { params: payload });
    }
}
