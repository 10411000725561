import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class ImagePlatformService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get image platform image preview
     * @returns Backend API response
     * @param imagePlatform
     */
    public getImagePreview(imagePlatform: any): Observable<any> {
        return this.httpClient.post<any>(PROTECTED_URLS.IMAGE_PLATFORM_PREVIEW_IMAGE, imagePlatform.data);
    }

    /**
     * Get image platform filename preview
     * @returns Backend API response
     * @param imagePlatform
     */
    public getFilenamePreview(imagePlatform: any): Observable<object> {
        return this.httpClient.post<any>(PROTECTED_URLS.IMAGE_PLATFORM_PREVIEW_FILENAME, imagePlatform.data);
    }

    /**
     * Generate zip
     * @returns Backend API response
     * @param imagePlatform
     */
    public generateZip(imagePlatform: any): Observable<any> {
        return this.httpClient.post<any>(PROTECTED_URLS.IMAGE_PLATFORM_GENERATE, imagePlatform.data);
    }

    /**
     * Get zip status
     * @param id Celery task ID
     * @returns Backend API response
     */
    public getZipStatus(id: number): Observable<any> {
        return this.httpClient.get<any>(`${PROTECTED_URLS.IMAGE_PLATFORM_STATUS}/${id}/`);
    }

    /**
     * Get available images
     * @param ids Products ids
     * @returns Backend API response
     */
    public getAvailableImages(ids: number[]): Observable<object> {
        return this.httpClient.post<any>(PROTECTED_URLS.IMAGE_PLATFORM_AVAILABLE_IMAGES, {
            products: ids,
        });
    }

    /**
     * Validate that at least 1 image for given products/fields exists
     * @returns Backend API response
     * @param imagePlatform
     */
    public validateImageExistence(imagePlatform: any): Observable<object> {
        return this.httpClient.post<any>(PROTECTED_URLS.IMAGE_PLATFORM_VALIDATE_IMAGE_EXISTENCE, imagePlatform.data);
    }

    /**
     * Get image platform filename options
     * @returns Backend API response
     */
    public getFilenameOptions(): Observable<any> {
        return this.httpClient.options<any>(PROTECTED_URLS.IMAGE_PLATFORM_PREVIEW_FILENAME);
    }
}
