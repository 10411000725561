import { GITHUB_HASH } from '@app/version';
import { MonitoringService } from '@shared/services/monitorUser.service';
import { environment } from '@env/environment';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CommonPageModule } from './common-page/common-page.module';
import { CoreModule } from '@core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/browser';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {
        Sentry.init({
            dsn: environment.sentryDSN,
            environment: environment.sentryENV,
            integrations: [new BrowserTracing()],
            tracesSampleRate: environment.sentryTraceRate,
            release: GITHUB_HASH,
        });
    }
    handleError(error) {
        Sentry.captureException(error.originalError || error);
    }
}

export function getErrorHandler(): ErrorHandler {
    if (environment.production) {
        return new SentryErrorHandler();
    }
    return new ErrorHandler();
}

@NgModule({
    imports: [BrowserModule, BrowserAnimationsModule, CoreModule, CommonPageModule, AppRoutingModule],
    declarations: [AppComponent],
    providers: [Title, { provide: ErrorHandler, useFactory: getErrorHandler }, MonitoringService],
    bootstrap: [AppComponent],
})
export class AppModule { }
