import { NgModule, Injectable } from '@angular/core';
import { PreloadingStrategy, Route, RouterModule, Routes } from '@angular/router';

import { Observable, of } from 'rxjs';

import { IsAuthenticatedGuard } from '@core/guards';
import { PublicApiAccessGuard } from '@core/guards';

import { NotFoundComponent } from '@core/components/not-found/not-found.component';

@Injectable()
export class PreloadSelectedModulesList implements PreloadingStrategy {
    // eslint-disable-next-line @typescript-eslint/ban-types
    preload(route: Route, load: Function): Observable<any> {
        return route.data && route.data.preload ? load() : of(null);
    }
}

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('app/wrapper/wrapper.module').then(m => m.WrapperModule),
        canActivate: [IsAuthenticatedGuard],
    },
    {
        path: 'login',
        loadChildren: () => import('app/auth/auth.module').then(m => m.AuthModule),
        data: {
            preload: true,
        },
    },
    {
        path: 'public-api',
        loadChildren: () => import('app/public-api/public-api.module').then(m => m.PublicApiModule),
        canActivate: [IsAuthenticatedGuard, PublicApiAccessGuard],
    },
    {
        path: 'not-found',
        component: NotFoundComponent,
        canActivate: [IsAuthenticatedGuard],
    },
    {
        path: '**',
        redirectTo: '/not-found',
    },
];

@NgModule({
    providers: [PreloadSelectedModulesList],
    imports: [
        RouterModule.forRoot(routes, {
    useHash: false,
    preloadingStrategy: PreloadSelectedModulesList
}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
