import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import * as nutrients from '../actions/nutrients.actions';

import { ModalService } from '../services/modal.service';
import { NotificationsService } from '../services/notifications.service';
import { NutrientsService } from '../services/nutrients.service';

import { catchErrorJson } from './catch-error';

@Injectable()
export class NutrientsEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private nutrientsService: NutrientsService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
    ) {}

    
    getNutrientsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(nutrients.ActionTypes.GET_NUTRIENTS_REQUEST),
        map((action: nutrients.GetNutrientsRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.nutrientsService.getNutrients(payload).pipe(
                map((response) => new nutrients.GetNutrientsSuccessAction(response)),
                catchError((error) => of(new nutrients.GetNutrientsErrorAction(catchErrorJson(error)))),
            );
        }),
    ));

    
    getOptionsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(nutrients.ActionTypes.GET_OPTIONS_REQUEST),
        switchMap(() => {
            return this.nutrientsService
                .getOptions()
                .pipe(map((response) => new nutrients.GetOptionsSuccessAction(response.actions.POST)));
        }),
    ));

    
    getNutrientRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(nutrients.ActionTypes.GET_NUTRIENT_REQUEST),
        map((action: nutrients.GetNutrientRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.nutrientsService.getNutrient(payload).pipe(
                map((response) => new nutrients.GetNutrientSuccessAction(response)),
                catchError((error) => of(new nutrients.GetNutrientErrorAction(catchErrorJson(error)))),
            );
        }),
    ));

    
    saveNutrientRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(nutrients.ActionTypes.SAVE_NUTRIENT_REQUEST),
        map((action: nutrients.SaveNutrientRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.nutrientsService.saveNutrient(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Saved nutrient ${response.translations.en.name}`);
                }),
                tap(() => {
                    this.router.navigate(['/nutrients']);
                }),
                map((response) => new nutrients.SaveNutrientSuccessAction(response)),
                catchError((error) => of(new nutrients.SaveNutrientErrorAction(catchErrorJson(error)))),
            );
        }),
    ));

    
    deleteNutrientRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(nutrients.ActionTypes.DELETE_NUTRIENT_REQUEST),
        map((action: nutrients.DeleteNutrientRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.nutrientsService.deleteNutrient(payload.id).pipe(
                tap(() => {
                    this.notificationsService.success(`Deleted nutrient`);
                }),
                tap(
                    () => {
                        this.router.navigate([payload.redirectTo]);
                    },
                    () => {
                        this.modalService.close('delete-nutrient-modal');
                    },
                ),
                map(() => new nutrients.DeleteNutrientSuccessAction()),
                catchError((error) => of(new nutrients.DeleteNutrientErrorAction(catchErrorJson(error)))),
            );
        }),
    ));

    
    createNutrientRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(nutrients.ActionTypes.CREATE_NUTRIENT_REQUEST),
        map((action: nutrients.CreateNutrientRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.nutrientsService.createNutrient(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Created nutrient ${response.translations.en.name}`);
                }),
                tap(() => {
                    this.router.navigate(['/nutrients']);
                }),
                map((response) => new nutrients.CreateNutrientSuccessAction(response)),
                catchError((error) => of(new nutrients.CreateNutrientErrorAction(catchErrorJson(error)))),
            );
        }),
    ));
}
