import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { from, Observable, of } from 'rxjs';

import * as companyDataRequirementsActions from '@core/actions/company-data-requirements.actions';

import { CompanyDataRequirementsService } from '@core/services/company-data-requirements.service';
import { ModalService } from '@core/services/modal.service';
import { NotificationsService } from '@core/services/notifications.service';

import { catchErrorJson } from './catch-error';

import { BulkDataRequirementsRequest } from '@core/models';
import { HTTPClientVer } from '@core/utils/request.utils';

const REDIRECT_SECTION = 'requirements';

@Injectable()
export class CompanyDataRequirementsEffects {
    constructor(
        private actions$: Actions,
        private activatedRoute: ActivatedRoute,
        private companyDataRequirementsService: CompanyDataRequirementsService,
        private modalService: ModalService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    
    getAvailableAttributesRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companyDataRequirementsActions.ActionTypes.GET_AVAILABLE_ATTRIBUTES_REQUEST),
        map((action: companyDataRequirementsActions.GetAvailableAttributesRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companyDataRequirementsService.getCompanyAvailableAttributes(payload).pipe(
                map((response) => new companyDataRequirementsActions.GetAvailableAttributesSuccessAction(response)),
                catchError((error) =>
                    of(
                        new companyDataRequirementsActions.GetAvailableAttributesErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    ));

    
    createCompanyDataRequirementsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companyDataRequirementsActions.ActionTypes.CREATE_REQUEST),
        map((action: companyDataRequirementsActions.CreateRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companyDataRequirementsService.createCompanyDataRequirement(payload.data).pipe(
                tap(() => this.notificationsService.success('Data Requirement has been created')),
                tap(() => {
                    this.router.navigate([payload.redirectTo], {
                        queryParams: {
                            section: REDIRECT_SECTION,
                        },
                    });
                }),
                map((response) => new companyDataRequirementsActions.CreateSuccessAction(response)),
                catchError((error) =>
                    of(new companyDataRequirementsActions.CreateErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    editCompanyDataRequirementRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companyDataRequirementsActions.ActionTypes.UPDATE_REQUEST),
        map((action: companyDataRequirementsActions.UpdateRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companyDataRequirementsService.updateCompanyDataRequirement(payload.id, payload.data).pipe(
                tap(() => this.notificationsService.success('Data Requirement Set has been edited')),
                tap(() => {
                    if (payload.redirectTo) {
                        this.router.navigate([payload.redirectTo], {
                            queryParams: {
                                section: REDIRECT_SECTION,
                            },
                        });
                    }
                }),
                mergeMap((response: any) => {
                    const { company } = response;
                    const actions = [];

                    if (payload.id) {
                        actions.push(
                            new companyDataRequirementsActions.GetListRequestAction({
                                ...payload.params,
                                company,
                            }),
                        );

                        actions.push(
                            new companyDataRequirementsActions.GetSummaryRequest({
                                company,
                            }),
                        );
                        actions.push(
                            new companyDataRequirementsActions.GetHistoryLogsRequestAction({
                                company,
                                offset: 0,
                            }),
                        );
                    }

                    actions.push(new companyDataRequirementsActions.UpdateSuccessAction(response));

                    return from(actions);
                }),
                catchError((error) =>
                    of(new companyDataRequirementsActions.UpdateErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getCompanyDataRequirementsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companyDataRequirementsActions.ActionTypes.GET_LIST_REQUEST),
        map((action: companyDataRequirementsActions.GetListRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companyDataRequirementsService.getCompanyDataRequirements(payload).pipe(
                map((response) => new companyDataRequirementsActions.GetListSuccessAction(response)),
                catchError((error) =>
                    of(new companyDataRequirementsActions.GetListErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getCompanyDataRequirementRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companyDataRequirementsActions.ActionTypes.GET_REQUIREMENT_REQUEST),
        map((action: companyDataRequirementsActions.GetRequirementRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companyDataRequirementsService.getCompanyDataRequirement(payload).pipe(
                mergeMap((response) => {
                    this.modalService.open('data-requirement-preview-modal');

                    const { company, category } = response as any;
                    return from([
                        new companyDataRequirementsActions.GetRequirementSuccessAction(response),
                        new companyDataRequirementsActions.GetAvailableAttributesRequestAction({
                            company,
                            category,
                        }),
                    ]);
                }),
                catchError((error) =>
                    of(
                        new companyDataRequirementsActions.GetRequirementErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    ));

    
    deleteCompanyDataRequirementRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companyDataRequirementsActions.ActionTypes.DELETE_REQUEST),
        map((action: companyDataRequirementsActions.DeleteRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companyDataRequirementsService.deleteCompanyDataRequirement(payload.id).pipe(
                tap(() => {
                    this.notificationsService.success('Data Requirement has been deleted');
                }),
                mergeMap((response: any) => {
                    const { company } = payload;

                    this.modalService.close('delete-data-requirement-modal');

                    return from([
                        new companyDataRequirementsActions.DeleteSuccessAction(response),
                        new companyDataRequirementsActions.GetListRequestAction({
                            ...payload.params,
                            company,
                            offset: 0,
                        }),
                        new companyDataRequirementsActions.GetSummaryRequest({
                            company,
                        }),
                        new companyDataRequirementsActions.GetHistoryLogsRequestAction({
                            company,
                            offset: 0,
                        }),
                    ]);
                }),
                catchError((error) =>
                    of(new companyDataRequirementsActions.DeleteErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    bulkUpdateCompanyDataRequirementsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companyDataRequirementsActions.ActionTypes.BULK_UPDATE_STATUS_REQUEST),
        map((action: companyDataRequirementsActions.BulkUpdateStatusRequestAction) => action.payload),
        switchMap((payload: BulkDataRequirementsRequest) => {
            return this.companyDataRequirementsService.bulkUpdateCompanyDataRequirement(payload).pipe(
                tap(() => {
                    this.notificationsService.success('Data Requirements has been updated');
                }),
                mergeMap((response: any) => {
                    const params = this.activatedRoute.snapshot.queryParams;
                    const { company } = payload.params;

                    return from([
                        new companyDataRequirementsActions.BulkUpdateStatusSuccessAction(response),
                        new companyDataRequirementsActions.GetListRequestAction({
                            ...params,
                            ...payload.params,
                            offset: 0,
                        }),
                        new companyDataRequirementsActions.GetSummaryRequest({
                            company,
                        }),
                        new companyDataRequirementsActions.GetHistoryLogsRequestAction({
                            company,
                            offset: 0,
                        }),
                    ]);
                }),
                catchError((error) =>
                    of(
                        new companyDataRequirementsActions.BulkUpdateStatusErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    ));

    
    bulkDeleteCompanyDataRequirementsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companyDataRequirementsActions.ActionTypes.BULK_DELETE_REQUEST),
        map((action: companyDataRequirementsActions.BulkDeleteRequestAction) => action.payload),
        switchMap((payload: BulkDataRequirementsRequest) => {
            return this.companyDataRequirementsService.bulkDeleteCompanyDataRequirement(payload).pipe(
                tap(() => {
                    this.notificationsService.success('Data Requirements has been deleted');
                }),
                mergeMap((response: any) => {
                    const params = this.activatedRoute.snapshot.queryParams;
                    const { company } = payload.params;

                    return from([
                        new companyDataRequirementsActions.ClearRequirementAction(),
                        new companyDataRequirementsActions.BulkDeleteSuccessAction(response),
                        new companyDataRequirementsActions.GetListRequestAction({
                            ...params,
                            ...payload.params,
                        }),
                        new companyDataRequirementsActions.GetSummaryRequest({
                            company,
                        }),
                        new companyDataRequirementsActions.GetHistoryLogsRequestAction({
                            company,
                            offset: 0,
                        }),
                    ]);
                }),
                catchError((error) =>
                    of(new companyDataRequirementsActions.BulkDeleteErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getCompanySharingPresetsSummaryRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companyDataRequirementsActions.ActionTypes.GET_SUMMARY_REQUEST),
        map((action: companyDataRequirementsActions.GetSummaryRequest) => action.payload),
        switchMap((payload) => {
            return this.companyDataRequirementsService.getCompanyDataRequirementsSummary(payload).pipe(
                map((response: any) => new companyDataRequirementsActions.GetSummarySuccess(response)),
                catchError((error) =>
                    of(new companyDataRequirementsActions.GetSummaryError(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getHistoryLogsRequestt$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companyDataRequirementsActions.ActionTypes.GET_HISTORY_LOGS_REQUEST),
        map((action: companyDataRequirementsActions.GetHistoryLogsRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companyDataRequirementsService.getHistoryLogs(payload).pipe(
                map((response) => new companyDataRequirementsActions.GetHistoryLogsSuccessAction(response)),
                catchError((error) =>
                    of(
                        new companyDataRequirementsActions.GetHistoryLogsErrorAction(
                            catchErrorJson(error, HTTPClientVer),
                        ),
                    ),
                ),
            );
        }),
    ));
}
