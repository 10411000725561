import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import * as workflowHistoryLogs from '@core/actions/workflow-history-logs.actions';
import { WorkflowHistoryLogsFilteringParams } from '@core/models';
import { ProductReviewsService } from '@core/services/product-reviews.service';
import { WorkflowHistoryLogsService } from '@core/services/workflow-history-logs.service';

import { HTTPClientVer } from '@core/utils/request.utils';
import { catchErrorJson } from './catch-error';

@Injectable()
export class WorkflowHistoryLogsEffects {
    constructor(
        private actions$: Actions,
        private workflowHistoryLogsService: WorkflowHistoryLogsService,
        private productReviewsService: ProductReviewsService,
    ) {}

    
    getWorkflowHistoryLogsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(workflowHistoryLogs.ActionTypes.GET_LIST_REQUEST),
        map((action: workflowHistoryLogs.GetListRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.workflowHistoryLogsService.getWorkflowHistoryLogs(payload).pipe(
                map((response) => new workflowHistoryLogs.GetListSuccessAction(response)),
                catchError((error) =>
                    of(new workflowHistoryLogs.GetListErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getProductWokrflowIdsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(workflowHistoryLogs.ActionTypes.GET_PRODUCT_WORKFLOW_IDS_REQUEST),
        map((action: workflowHistoryLogs.GetProductWorkflowIdsRequestAction) => action.payload),
        switchMap((payload: WorkflowHistoryLogsFilteringParams) => {
            return this.workflowHistoryLogsService.getProductWorkflowIds(payload).pipe(
                map((response) => new workflowHistoryLogs.GetProductWorkflowIdsSuccessAction(response)),
                catchError((error) =>
                    of(new workflowHistoryLogs.GetProductWorkflowIdsErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));
}
