import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HTTPClientVer } from '@core/utils/request.utils';
import { catchError, delay, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { EMPTY as empty, from, Observable, of } from 'rxjs';

import * as imagePlatform from '../actions/image-platform.actions';

import { ImagePlatformService } from '../services/image-platform.service';
import { ModalService } from '../services/modal.service';
import { NotificationsService } from '../services/notifications.service';
import { SectionGroupService } from '../services/section-group.service';

import { catchErrorJson } from './catch-error';

@Injectable()
export class ImagePlatformEffects {
    private zipCeleryId: number;

    
    getImagePreviewRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(imagePlatform.ActionTypes.GET_IMAGE_PREVIEW_REQUEST),
        map((action: imagePlatform.GetImagePreviewRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.imagePlatformService.getImagePreview(payload).pipe(
                map((response) => new imagePlatform.GetImagePreviewSuccessAction(response)),
                catchError((error) =>
                    of(new imagePlatform.GetImagePreviewErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getFilenamePreviewRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(imagePlatform.ActionTypes.GET_FILENAME_PREVIEW_REQUEST),
        map((action: imagePlatform.GetFilenamePreviewRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.imagePlatformService.getFilenamePreview(payload).pipe(
                tap((response) => {
                    if (payload.changeSectionGroup) {
                        this.sectionGroupService.selectSection(
                            payload.changeSectionGroup.id,
                            payload.changeSectionGroup.index,
                        );
                    }
                }),
                map((response) => new imagePlatform.GetFilenamePreviewSuccessAction(response)),
                catchError((error) =>
                    of(new imagePlatform.GetFilenamePreviewErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    validateImageExistenceRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(imagePlatform.ActionTypes.VALIDATE_IMAGE_EXISTENCE_REQUEST),
        map((action: imagePlatform.ValidateImageExistenceRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.imagePlatformService.validateImageExistence(payload).pipe(
                tap((response) => {
                    if (payload.changeSectionGroup) {
                        this.sectionGroupService.selectSection(
                            payload.changeSectionGroup.id,
                            payload.changeSectionGroup.index,
                        );
                    }
                }),
                mergeMap((response) =>
                    from([
                        new imagePlatform.ValidateImageExistenceSuccessAction(response),
                        new imagePlatform.GetFilenamePreviewRequestAction(payload),
                    ]),
                ),
                catchError((error) =>
                    of(new imagePlatform.ValidateImageExistenceErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    generateZipRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(imagePlatform.ActionTypes.GENERATE_ZIP_REQUEST),
        map((action: imagePlatform.GenerateZipRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.imagePlatformService.generateZip(payload).pipe(
                tap((response) => {
                    // `sendMail` equal false means that we want to download zip directly.
                    // `sendMail` equal true means that we want to send an email with a link to zip.
                    if (!payload.data.sendMail) {
                        this.zipCeleryId = response.id;
                        this.modalService.open('image-platform-modal');
                    } else {
                        this.modalService.close('target-image-platform-modal');
                        this.notificationsService.success('E-mail with download link was sent to you.');
                    }
                }),
                mergeMap(response => {
                    const actions = [new imagePlatform.GenerateZipSuccessAction(response)] as Action[];
                    if (!payload.data.sendMail) {
                        // Automatically dispatch `GetZipStatusRequestAction` only when user download zip directly
                        actions.push(new imagePlatform.GetZipStatusRequestAction(response.id));
                    }
                    return from(actions);
                }),
                catchError((error) =>
                    of(new imagePlatform.GenerateZipErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getZipStatus$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(imagePlatform.ActionTypes.GET_ZIP_STATUS_REQUEST),
        map((action: imagePlatform.GetZipStatusRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.imagePlatformService.getZipStatus(payload).pipe(
                tap(
                    (response) => {
                        if (response.status === 'SUCCESS') {
                            window.location.href = response.info.url;
                            this.modalService.close('image-platform-modal');
                            this.notificationsService.success('Download success.');
                            this.router.navigate(['products']);
                        } else if (response.status === 'FAILURE') {
                            this.modalService.close('image-platform-modal');
                            this.notificationsService.error('Download fail. Please try again later.');
                        }
                    },
                    (error) => {
                        this.modalService.close('image-platform-modal');
                    },
                ),
                map((response) => new imagePlatform.GetZipStatusSuccessAction(response)),
                catchError((error) =>
                    of(new imagePlatform.GetZipStatusErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getZipStatusSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(imagePlatform.ActionTypes.GET_ZIP_STATUS_SUCCESS),
        map((action: imagePlatform.GetZipStatusSuccessAction) => action.payload),
        delay(1000),
        filter(() => this.modalService.isOpened('image-platform-modal')),
        filter((payload) => payload.status === 'PENDING' || payload.status === 'IN_PROGRESS'),
        map((payload) => {
            return new imagePlatform.GetZipStatusRequestAction(this.zipCeleryId);
        }),
    ));

    
    getAvailableImages$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(imagePlatform.ActionTypes.GET_AVAILABLE_IMAGES_REQUEST),
        map((action: imagePlatform.GetAvailableImagesRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.imagePlatformService.getAvailableImages(payload).pipe(
                map((response) => new imagePlatform.GetAvailableImagesSuccessAction(response)),
                catchError((error) =>
                    of(new imagePlatform.GetAvailableImagesErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getFilenameOptionsRequest$: Observable<Action | {}> = createEffect(() => this.actions$.pipe(
        ofType(imagePlatform.ActionTypes.GET_FILENAME_OPTIONS_REQUEST),
        switchMap((payload) => {
            return this.imagePlatformService.getFilenameOptions().pipe(
                map((response) => new imagePlatform.GetFilenameOptionsSuccessAction(response.actions.POST)),
                catchError(() => empty),
            );
        }),
    ));

    constructor(
        private router: Router,
        private actions$: Actions,
        private imagePlatformService: ImagePlatformService,
        private notificationsService: NotificationsService,
        private sectionGroupService: SectionGroupService,
        private modalService: ModalService,
    ) {}
}
