import { NgModule } from '@angular/core';
import { RichTextEditorComponent } from '@shared/components/rich-text-editor/rich-text-editor.component';

import { CommonPageRoutingModule } from './common-page-routing.module';

import { SharedModule } from '../shared/shared.module';
import { CommonComponent } from './components/common/common.component';

@NgModule({
    imports: [CommonPageRoutingModule, SharedModule, RichTextEditorComponent],
    declarations: [CommonComponent],
    exports: [],
    providers: [],
})
export class CommonPageModule {}
