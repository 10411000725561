import { prepareActions } from '@app/utils';
import { Action } from '@ngrx/store';

export const ActionTypes = prepareActions('[Product Change Requests]', [
    'GET_LIST_REQUEST',
    'GET_LIST_SUCCESS',
    'GET_LIST_ERROR',
    'CLEAR_LIST',

    'GET_MANAGE_REQUEST',
    'GET_MANAGE_SUCCESS',
    'GET_MANAGE_ERROR',
    'CLEAR_MANAGE',

    'CREATE_REQUEST',
    'CREATE_SUCCESS',
    'CREATE_ERROR',
]);

export namespace ChangeRequestsActions {
    /**
     * Create 'Create Request' Action
     * @param payload Payload with request data
     */
    export class CreateRequest implements Action {
        type = ActionTypes.CREATE_REQUEST;
        constructor(public payload?: any) {}
    }

    /**
     * Create 'Create Success' Action
     * @param payload Payload with response
     */
    export class CreateSuccess implements Action {
        type = ActionTypes.CREATE_SUCCESS;
        constructor(public payload?: any) {}
    }

    /**
     * Create 'Create Error' Action
     * @param payload Payload with error object
     */
    export class CreateError implements Action {
        type = ActionTypes.CREATE_ERROR;
        constructor(public payload?: any) {}
    }

    /**
     * Create 'Get List Request' Action
     * @param payload Payload with query params
     */
    export class GetListRequest implements Action {
        type = ActionTypes.GET_LIST_REQUEST;
        constructor(public payload?: any) {}
    }

    /**
     * Create 'Get List Success' Action
     * @param payload Payload with backend response
     */
    export class GetListSuccess implements Action {
        type = ActionTypes.GET_LIST_SUCCESS;
        constructor(public payload?: any) {}
    }

    /**
     * Create 'Get List Error' Action
     * @param payload Payload with error object
     */
    export class GetListError implements Action {
        type = ActionTypes.GET_LIST_ERROR;
        constructor(public payload?: any) {}
    }

    /**
     * Create 'Clear List' Action
     * @param payload redundant
     */
    export class ClearList implements Action {
        type = ActionTypes.CLEAR_LIST;
        constructor(public payload?: any) {}
    }

    /**
     * Create 'Get Manage Request' Action
     * @param payload Payload with change request id
     */
    export class GetManageRequest implements Action {
        type = ActionTypes.GET_MANAGE_REQUEST;
        constructor(public payload?: any) {}
    }

    /**
     * Create 'Get Manage Success' Action
     * @param payload Payload with backend response
     */
    export class GetManageSuccess implements Action {
        type = ActionTypes.GET_MANAGE_SUCCESS;
        constructor(public payload?: any) {}
    }

    /**
     * Create 'Get Manage Error' Action
     * @param payload Payload with error object
     */
    export class GetManageError implements Action {
        type = ActionTypes.GET_MANAGE_ERROR;
        constructor(public payload?: any) {}
    }

    /**
     * Create 'Clear Manage' Action
     * @param payload redundant
     */
    export class ClearManage implements Action {
        type = ActionTypes.CLEAR_MANAGE;
        constructor(public payload?: any) {}
    }

    export type Actions =
        | CreateRequest
        | CreateSuccess
        | CreateError
        | GetListRequest
        | GetListSuccess
        | GetListError
        | ClearList
        | GetManageRequest
        | GetManageSuccess
        | GetManageError
        | ClearManage;
}
