import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import * as importHistoryLogs from '@core/actions/import-history-logs.actions';
import { ImportHistoryLogsService } from '@core/services/import-history-logs.service';

import { HTTPClientVer } from '@core/utils/request.utils';
import { catchErrorJson } from './catch-error';

@Injectable()
export class ImportHistoryLogsEffects {
    constructor(private actions$: Actions, private importHistoryLogsService: ImportHistoryLogsService) {}

    
    getImportHistoryLogsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(importHistoryLogs.ActionTypes.GET_LIST_REQUEST),
        map((action: importHistoryLogs.GetListRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.importHistoryLogsService.getImportHistoryLogs(payload).pipe(
                map((response) => new importHistoryLogs.GetListSuccessAction(response)),
                catchError((error) =>
                    of(new importHistoryLogs.GetListErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getImportHistoryLogRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(importHistoryLogs.ActionTypes.GET_LOG_REQUEST),
        map((action: importHistoryLogs.GetLogRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.importHistoryLogsService.getImportHistoryLog(payload).pipe(
                map((response) => new importHistoryLogs.GetLogSuccessAction(response)),
                catchError((error) =>
                    of(new importHistoryLogs.GetLogErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));
}
