import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Action } from '@ngrx/store';

import * as ingredients from '../actions/ingredients.actions';

import { IngredientsService } from '../services/ingredients.service';

import { HTTPClientVer } from '@core/utils/request.utils';
import { catchErrorJson } from './catch-error';

@Injectable()
export class IngredientsEffects {
    constructor(private actions$: Actions, private ingredientsService: IngredientsService) {}

    
    getIngredientsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ingredients.ActionTypes.GET_INGREDIENTS_REQUEST),
        map((action: ingredients.GetIngredientsRequestAction) => action.payload),
        switchMap((payload) => {
            return this.ingredientsService.getIngredients(payload).pipe(
                map((response) => new ingredients.GetIngredientsSuccessAction(response)),
                catchError((error) =>
                    of(new ingredients.GetIngredientsErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));
}
