import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

@Injectable()
export class MonitoringService {
    monitorUser(user: any): void {
        Sentry.configureScope(scope => {
            scope.setUser({
                id: user.id,
                username: `${user.firstName} ${user.lastName}`,
                email: user.email,
            });
        });
    }
}
