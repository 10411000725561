<div class="not-found" [@routerTransition]="animateReady">
  <div class="not-found__container">
    <figure class="not-found__logo">
      <svg class="not-found__logo-image" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" height="104.6" width="605.74725" xml:space="preserve"
        viewBox="0 0 605.74725 104.6" y="0px" x="0px" id="Layer_1" version="1.1">
        <defs id="defs3800" />
        <g transform="translate(-51.9,-58.7)" id="g3795">
          <path id="path3761" d="M 54.6,58.7 H 55 c 1.8,0.4 2.7,1.2 2.7,2.4 v 32.8 c 7.9,-7.7 15.8,-11.5 23.6,-11.5 7.5,0 14.3,2.9 20.5,8.6 5.9,6.2 8.8,13.1 8.8,20.8 0,11 -5.2,19.7 -15.7,26 -4.5,2.3 -8.8,3.4 -13,3.4 h -1.8 c -7.9,0 -15.2,-3.7 -22,-11.1 C 54,124.6 51.9,118 51.9,110.3 V 61.1 c 0,-1.2 0.9,-2 2.7,-2.4 z m 3.1,52.3 v 1.1 c 0,9.8 5.1,17.1 15.4,21.9 2.8,0.8 5.3,1.3 7.5,1.3 H 82 c 9.6,0 16.8,-5.1 21.7,-15.4 0.8,-3.1 1.2,-5.6 1.2,-7.3 v -1.4 c 0,-9.8 -5.2,-17.1 -15.7,-21.9 -3.2,-0.8 -5.7,-1.2 -7.5,-1.2 h -0.9 c -9.6,0 -16.8,5 -21.6,15.1 -1,2.9 -1.5,5.5 -1.5,7.8 z" />
          <path id="path3763" d="m 122.7,82.6 c 2,0.5 3,1.3 3,2.3 v 8.3 c 7.9,-7.1 15.4,-10.6 22.5,-10.6 3.8,0 7.8,0.9 12,2.7 0.8,0.8 1.1,1.6 1.1,2.3 v 0.1 c -0.3,1.9 -1.2,2.9 -2.7,2.9 -4.1,-1.3 -7.2,-2 -9.5,-2 h -1.3 c -9.4,0 -16.3,4.9 -20.7,14.6 -0.9,2.6 -1.4,5.6 -1.4,8.8 v 26.5 c 0,1.4 -1,2.3 -2.9,2.6 h -0.1 c -1.4,0 -2.4,-1 -2.7,-3.1 V 86.3 c 0.1,-2.5 1.1,-3.7 2.7,-3.7 z" />
          <path id="path3765" d="m 192.7,83.1 c 10.9,0 19.6,5.4 26,16.1 1.9,4.3 2.9,8.8 2.9,13.5 v 25.8 c 0,1.3 -0.9,2.2 -2.9,2.5 h -0.1 c -1.2,0 -2.1,-0.7 -2.7,-2.1 v 0.1 l 0.2,-5.5 v -3.8 c -7.9,7.5 -15.5,11.3 -22.6,11.3 h -1.7 c -9.8,0 -18,-5 -24.5,-14.8 -2.4,-4.7 -3.6,-9.5 -3.6,-14.4 0,-10.8 5.3,-19.3 15.9,-25.6 4.4,-2.1 8.8,-3.1 13.1,-3.1 z m -23.3,28.5 v 0.7 c 0,9.4 4.7,16.6 14.2,21.4 3,1.1 5.8,1.7 8.2,1.7 h 1.7 c 7.9,0 14.5,-3.9 19.6,-11.9 1.9,-3.7 2.8,-7.3 2.8,-10.7 v -0.9 c 0,-9.1 -4.5,-16.1 -13.5,-20.9 -3.2,-1.4 -6.4,-2.1 -9.8,-2.1 h -0.2 c -9.6,0 -16.8,5 -21.6,15 -1,2.9 -1.4,5.5 -1.4,7.7 z" />
          <path id="path3767" d="m 233.1,83.1 c 1.9,0.5 2.9,1.3 2.9,2.3 v 8.2 c 7.8,-7 15.3,-10.5 22.6,-10.5 7.9,0 15,3.3 21.1,9.9 4.5,5.6 6.7,11.4 6.7,17.5 v 28.3 c 0,1.1 -1,1.9 -2.8,2.3 -1.9,-0.4 -2.9,-1.3 -2.9,-2.7 v -24.9 c 0,-11.8 -5.2,-19.7 -15.5,-23.5 -2.5,-0.6 -4.7,-0.9 -6.7,-0.9 H 258 c -8.7,0 -15.3,4.4 -20,13.3 -1.3,2.8 -1.9,7.1 -1.9,12.9 v 23.4 c 0,1.3 -1,2.2 -2.8,2.5 h -0.1 c -1.4,0 -2.3,-1 -2.7,-3.1 V 86.8 c 0.1,-2.5 1,-3.7 2.6,-3.7 z" />
          <path id="path3769" d="m 347.1,58.7 h 0.7 c 1.8,0.3 2.7,1.1 2.7,2.1 v 44.6 l 0.2,7.6 c 0,5.6 -2.3,11.4 -6.8,17.5 -6.5,7 -13.7,10.5 -21.8,10.5 h -1.8 c -7.9,0 -15.2,-3.7 -22,-11.1 -4.2,-5.9 -6.3,-12 -6.3,-18.2 0,-9.5 4.2,-17.5 12.7,-24 5.4,-3.5 10.9,-5.3 16.7,-5.3 7.4,0 15.3,3.7 23.5,11.1 V 64.6 l -0.2,-3.3 c 0.7,-1.8 1.5,-2.6 2.4,-2.6 z M 297.9,111 v 1.1 c 0,9.8 5.1,17.1 15.4,21.9 2.8,0.8 5.3,1.3 7.5,1.3 h 1.4 c 9.6,0 16.8,-5.1 21.7,-15.4 0.8,-3.1 1.2,-5.6 1.2,-7.3 v -1.4 c 0,-9.8 -5.2,-17.1 -15.7,-21.9 -3.2,-0.8 -5.7,-1.2 -7.5,-1.2 H 321 c -9.6,0 -16.8,5 -21.6,15.1 -1,2.9 -1.5,5.5 -1.5,7.8 z" />
          <path id="path3771" d="m 389.4,143.7 c -4.8,0 -9.7,-0.8 -14.8,-2.5 -5,-1.6 -9.8,-4.2 -14.3,-7.7 l 8,-12.3 c 3.7,2.7 7.4,4.7 11.1,6.1 3.7,1.4 7.2,2.1 10.4,2.1 2.9,0 5.1,-0.5 6.5,-1.5 1.4,-1 2.1,-2.3 2.1,-4 v -0.2 c 0,-1 -0.4,-2 -1.2,-2.7 -0.8,-0.8 -1.8,-1.5 -3.1,-2.1 -1.3,-0.6 -2.8,-1.2 -4.6,-1.7 -1.8,-0.6 -3.6,-1.1 -5.4,-1.7 -2.4,-0.7 -4.9,-1.6 -7.3,-2.5 -2.4,-1 -4.7,-2.2 -6.6,-3.7 -1.9,-1.5 -3.5,-3.4 -4.8,-5.7 -1.2,-2.3 -1.8,-5 -1.8,-8.3 V 95 c 0,-3.4 0.7,-6.4 1.9,-9.1 1.3,-2.7 3.1,-4.9 5.3,-6.7 2.2,-1.8 4.9,-3.2 7.9,-4.2 3,-0.9 6.2,-1.4 9.6,-1.4 4.3,0 8.7,0.7 13.1,2.1 4.4,1.4 8.4,3.3 12.1,5.7 l -7.1,12.8 c -3.4,-1.9 -6.7,-3.5 -9.9,-4.6 -3.2,-1.1 -6.1,-1.7 -8.7,-1.7 -2.4,0 -4.3,0.5 -5.6,1.5 -1.3,1 -2,2.2 -2,3.6 v 0.2 c 0,1 0.4,1.9 1.2,2.6 0.8,0.8 1.8,1.5 3.1,2.1 1.3,0.6 2.8,1.3 4.5,1.8 1.7,0.6 3.5,1.2 5.4,1.8 2.4,0.7 4.8,1.6 7.2,2.6 2.4,1 4.7,2.3 6.6,3.8 2,1.5 3.6,3.4 4.9,5.6 1.3,2.2 1.9,4.8 1.9,7.9 v 0.2 c 0,3.8 -0.7,7 -2,9.7 -1.3,2.7 -3.2,5 -5.5,6.8 -2.3,1.8 -5,3.1 -8.2,4 -2.9,1.2 -6.3,1.6 -9.9,1.6 z" />
          <path id="path3773" d="m 467.6,74.7 h 19.3 l -26.2,69.6 c -1.3,3.4 -2.7,6.4 -4.1,8.8 -1.4,2.4 -3,4.4 -4.8,5.9 -1.7,1.5 -3.7,2.6 -5.9,3.3 -2.2,0.7 -4.7,1 -7.6,1 -3.4,0 -6.5,-0.4 -9.2,-1.3 -2.7,-0.9 -5.3,-2 -7.7,-3.4 l 6.2,-13.4 c 1.3,0.7 2.7,1.3 4.1,1.8 1.5,0.5 2.9,0.7 4.3,0.7 1.8,0 3.2,-0.3 4.3,-1 1,-0.7 2.1,-2 3,-4 l -27,-68.1 H 436 l 16.1,47 z" />
          <path id="path3775" d="m 493.8,74.7 h 18.6 v 9.8 c 1.1,-1.5 2.3,-2.9 3.6,-4.1 1.3,-1.3 2.8,-2.5 4.4,-3.5 1.6,-1 3.4,-1.8 5.4,-2.4 2,-0.6 4.2,-0.9 6.6,-0.9 7.4,0 13.1,2.2 17.2,6.7 4.1,4.5 6.1,10.6 6.1,18.6 v 43.8 h -18.5 v -38 c 0,-4.7 -1.1,-8.3 -3.2,-10.7 -2.1,-2.4 -5.1,-3.6 -9.1,-3.6 -3.9,0 -7,1.2 -9.3,3.6 -2.3,2.5 -3.4,6 -3.4,10.7 v 38 h -18.6 v -68 z" />
          <path id="path3777" d="m 610.5,125.5 c -0.4,0.2 -0.7,0.4 -1.1,0.6 -2.5,1.3 -5.4,2 -8.7,2 -2.7,0 -5.1,-0.5 -7.3,-1.5 -2.2,-1 -4.1,-2.4 -5.6,-4.2 -1.6,-1.8 -2.7,-3.8 -3.6,-6.2 -0.8,-2.4 -1.3,-4.9 -1.3,-7.6 v -0.2 c 0,-2.6 0.4,-5.1 1.3,-7.4 0.9,-2.3 2,-4.3 3.6,-6 1.5,-1.7 3.3,-3.1 5.4,-4.1 2.1,-1 4.4,-1.5 6.9,-1.5 3.5,0 6.4,0.7 8.9,2.1 2.4,1.4 4.7,3.3 6.9,5.6 l 11.5,-12.2 c -3.2,-3.6 -6.9,-6.4 -11.2,-8.4 -4.3,-2 -9.6,-3 -15.9,-3 -5.2,0 -10,0.9 -14.3,2.8 -4.4,1.9 -8.1,4.4 -11.2,7.6 -3.1,3.2 -5.6,7 -7.3,11.2 -1.8,4.3 -2.6,8.8 -2.6,13.6 v 0.2 c 0,4.9 0.9,9.5 2.6,13.7 1.7,4.2 4.2,7.9 7.3,11.1 3.1,3.2 6.8,5.7 11.2,7.5 4.3,1.8 9,2.8 14.1,2.8 3.3,0 6.4,-0.3 9,-0.9 2.7,-0.6 5.1,-1.5 7.4,-2.6 2.1,-1 3.9,-2.2 5.7,-3.6 z" />
          <g id="g3793">
            <path id="path3779" d="m 629.4,95.5 c -0.7,-2.2 0.4,-4.5 2.6,-5.3 2.2,-0.7 4.5,0.4 5.3,2.6 0.8,2.2 -0.4,4.5 -2.6,5.3 -2.2,0.7 -4.6,-0.4 -5.3,-2.6 z" />
            <path id="path3781" d="m 638.2,82.5 c -0.7,-2.2 0.4,-4.5 2.6,-5.3 2.2,-0.7 4.5,0.4 5.3,2.6 0.8,2.2 -0.4,4.5 -2.6,5.3 -2.2,0.7 -4.6,-0.4 -5.3,-2.6 z" />
            <path id="path3783" d="m 632.4,109 c -0.7,-2.2 0.4,-4.5 2.6,-5.3 2.2,-0.7 4.5,0.4 5.3,2.6 0.7,2.2 -0.4,4.5 -2.6,5.3 -2.2,0.7 -4.5,-0.5 -5.3,-2.6 z" />
            <path id="path3785" d="m 640.5,118.5 c -1.1,-3.3 0.6,-6.9 4,-8 3.3,-1.1 6.9,0.6 8,3.9 1.1,3.3 -0.6,6.9 -4,8 -3.2,1.2 -6.8,-0.6 -8,-3.9 z" />
            <path id="path3787" d="m 618.4,106 c -1,-3.1 0.7,-6.4 3.8,-7.5 3.1,-1 6.4,0.7 7.5,3.8 1,3.1 -0.7,6.4 -3.8,7.5 -3.2,1 -6.5,-0.7 -7.5,-3.8 z" />
            <path id="path3789" d="m 641,98.9 c -1.5,-4.5 0.9,-9.3 5.3,-10.9 4.5,-1.5 9.4,0.9 10.9,5.4 1.5,4.5 -0.9,9.3 -5.3,10.9 -4.5,1.5 -9.4,-0.9 -10.9,-5.4 z" />
            <path id="path3791" d="m 618.5,124.7 c -1.8,-5.1 1,-10.7 6.1,-12.5 5.1,-1.8 10.7,1 12.5,6.1 1.8,5.1 -1,10.7 -6.1,12.5 -5.2,1.8 -10.8,-1 -12.5,-6.1 z" />
          </g>
        </g>
      </svg>
    </figure>

    <h1 class="not-found__error">404</h1>

    <div class="not-found__content">
      <div class="not-found__info">
        page not found
      </div>

      <div class="not-found__actions">
        <a routerLink="/" class="not-found__primary-action btn btn--secondary btn--fluid">Go to products</a>
      </div>
    </div>
  </div>
</div>
