import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { from, Observable, of } from 'rxjs';

import * as companiesActions from '../actions/companies.actions';
import * as companyPaidPlans from '../actions/company-paid-plans.actions';

import { CompanyPaidPlansService } from '../services/company-paid-plans.service';
import { ModalService } from '../services/modal.service';
import { NotificationsService } from '../services/notifications.service';
import { SectionGroupService } from '../services/section-group.service';

import { HTTPClientVer } from '@core/utils/request.utils';
import { catchErrorJson } from './catch-error';

@Injectable()
export class CompanyPaidPlansEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private companyPaidPlansService: CompanyPaidPlansService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
        private sectionGroupService: SectionGroupService,
    ) {}

    
    getCompanyPaidPlansRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companyPaidPlans.ActionTypes.GET_COMPANY_PAID_PLANS_REQUEST),
        map((action: companyPaidPlans.GetCompanyPaidPlansRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companyPaidPlansService.getCompanyPaidPlans(payload).pipe(
                map(response => new companyPaidPlans.GetCompanyPaidPlansSuccessAction(response)),
                catchError(error =>
                    of(new companyPaidPlans.GetCompanyPaidPlansErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    getOptionsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companyPaidPlans.ActionTypes.GET_OPTIONS_REQUEST),
        switchMap(() => {
            return this.companyPaidPlansService
                .getOptions()
                .pipe(map((response) => new companyPaidPlans.GetOptionsSuccessAction((response as any).actions.POST)));
        }),
    ));

    
    getCompanyPaidPlanRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companyPaidPlans.ActionTypes.GET_COMPANY_PAID_PLAN_REQUEST),
        map((action: companyPaidPlans.GetCompanyPaidPlanRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companyPaidPlansService.getCompanyPaidPlan(payload).pipe(
                map((response) => new companyPaidPlans.GetCompanyPaidPlanSuccessAction(response)),
                catchError((error) =>
                    of(new companyPaidPlans.GetCompanyPaidPlanErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    saveCompanyPaidPlanRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companyPaidPlans.ActionTypes.SAVE_COMPANY_PAID_PLAN_REQUEST),
        map((action: companyPaidPlans.SaveCompanyPaidPlanRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companyPaidPlansService.saveCompanyPaidPlan(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(`Saved paid plan ${(response as any).basePlanData.name}`);
                    if (typeof payload.redirectTo !== 'undefined') {
                        this.router.navigate([payload.redirectTo]);
                    }
                    if (payload.changeSectionGroup) {
                        this.sectionGroupService.selectSection(
                            payload.changeSectionGroup.id,
                            payload.changeSectionGroup.index,
                        );
                    }
                }),
                mergeMap(response => {
                    const actions = [new companyPaidPlans.SaveCompanyPaidPlanSuccessAction(response)] as Action[];
                    if (payload.options.refreshList) {
                        actions.push(
                            new companyPaidPlans.GetCompanyPaidPlansRequestAction({
                                company: (response as any).company,
                            }),
                        );
                        actions.push(new companiesActions.GetCompanyRequestAction((response as any).company));
                    }
                    return from(actions);
                }),
                catchError((error) =>
                    of(new companyPaidPlans.SaveCompanyPaidPlanErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    deleteCompanyPaidPlanRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companyPaidPlans.ActionTypes.DELETE_COMPANY_PAID_PLAN_REQUEST),
        map((action: companyPaidPlans.DeleteCompanyPaidPlanRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companyPaidPlansService.deleteCompanyPaidPlan(payload.id).pipe(
                tap(
                    (response) => {
                        this.notificationsService.success(`Deleted company paid plan`);
                        if (payload.options.redirect) {
                            this.router.navigate([payload.options.redirect]);
                        }
                    },
                    (error) => {
                        this.modalService.close('delete-company-paid-plan-modal');
                    },
                ),
                map(() => new companyPaidPlans.DeleteCompanyPaidPlanSuccessAction()),
                catchError((error) =>
                    of(new companyPaidPlans.DeleteCompanyPaidPlanErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));

    
    createCompanyPaidPlanRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(companyPaidPlans.ActionTypes.CREATE_COMPANY_PAID_PLAN_REQUEST),
        map((action: companyPaidPlans.CreateCompanyPaidPlanRequestAction) => action.payload),
        switchMap((payload: any) => {
            return this.companyPaidPlansService.createCompanyPaidPlan(payload).pipe(
                tap((response) => {
                    this.notificationsService.success(
                        `Created company paid plan ${(response as any).basePlanData.name}`,
                    );
                    if (payload.options.redirect) {
                        this.router.navigate([payload.options.redirect]);
                    }
                    if (payload.changeSectionGroup) {
                        this.sectionGroupService.selectSection(
                            payload.changeSectionGroup.id,
                            payload.changeSectionGroup.index,
                        );
                    }
                }),
                mergeMap(response => {
                    const actions = [new companyPaidPlans.CreateCompanyPaidPlanSuccessAction(response)] as Action[];
                    if (payload.options.refreshList) {
                        actions.push(
                            new companyPaidPlans.GetCompanyPaidPlansRequestAction({
                                company: (response as any).company,
                            }),
                        );
                    }
                    return from(actions);
                }),
                catchError((error) =>
                    of(new companyPaidPlans.CreateCompanyPaidPlanErrorAction(catchErrorJson(error, HTTPClientVer))),
                ),
            );
        }),
    ));
}
