import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PROTECTED_URLS } from '../../../urls.conf';

@Injectable()
export class UserLogsService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Get user logs list
     * @param params Object with list params
     * @returns Backend API response
     */
    getLogsActions(params: any = {}): Observable<any> {
        return this.httpClient.get<any>(PROTECTED_URLS.USER_LOGS, { params });
    }
}
