import * as changeRequestsActions from '@core/actions/product-change-requests.actions';

import { DefaultList, DefaultManage } from '@core/models';

export interface State {
    manage: DefaultManage<any>;
    list: DefaultList<any>;
}

const manageInitialState: DefaultManage<any> = {
    data: {},
    errors: {},
    isFetching: true,
    isSaving: false,
};

const listInitialState: DefaultList<any> = {
    data: {
        count: null,
        limit: null,
        next: null,
        offset: null,
        previous: null,
        results: [],
    },
    errors: {},
    isFetching: true,
};

const initialState: State = {
    manage: manageInitialState,
    list: listInitialState,
};

export function reducer(state = initialState, action: changeRequestsActions.ChangeRequestsActions.Actions) {
    switch (action.type) {
        case changeRequestsActions.ActionTypes.CREATE_REQUEST:
        case changeRequestsActions.ActionTypes.GET_MANAGE_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    isSaving: true,
                    errors: {},
                },
            };
        }

        case changeRequestsActions.ActionTypes.CREATE_SUCCESS:
        case changeRequestsActions.ActionTypes.GET_MANAGE_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isSaving: false,
                },
            };
        }

        case changeRequestsActions.ActionTypes.CREATE_ERROR:
        case changeRequestsActions.ActionTypes.GET_MANAGE_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case changeRequestsActions.ActionTypes.CLEAR_MANAGE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        case changeRequestsActions.ActionTypes.GET_LIST_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    isFetching: true,
                    errors: {},
                },
            };
        }

        case changeRequestsActions.ActionTypes.GET_LIST_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case changeRequestsActions.ActionTypes.GET_LIST_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    isFetching: false,
                    errors: action.payload,
                },
            };
        }

        case changeRequestsActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        default:
            return state;
    }
}

export const getManage = (state: State) => state.manage;
export const getList = (state: State) => state.list;
