import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CommonComponent } from './components/common/common.component';

export const routes: Routes = [
    {
        path: 'common',
        component: CommonComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CommonPageRoutingModule {}
